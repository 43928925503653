export const NSL = {
  type: "FeatureCollection",
  name: "NSL Cable.kmz",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Name: "NSL Cable",
        tessellate: 1,
        extrude: 0,
        visibility: -1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-1.528929694358067, 55.153956611802712, 0.0],
          [-1.430610162239999, 55.148099051628883, 0.0],
          [-1.237053451100096, 55.202432150215529, 0.0],
          [-1.095103185287414, 55.244435053819593, 0.0],
          [-1.0586457130322, 55.253718953748759, 0.0],
          [-0.981404587337734, 55.293263839050233, 0.0],
          [-0.929928606417262, 55.308819210313843, 0.0],
          [-0.860806792300786, 55.336312734822933, 0.0],
          [-0.802193044622276, 55.371703208700772, 0.0],
          [-0.759421207766352, 55.400477417270231, 0.0],
          [-0.717563725786412, 55.429747750527312, 0.0],
          [-0.659995951809694, 55.478921659847018, 0.0],
          [-0.553365197357351, 55.54170962446252, 0.0],
          [-0.399424372915265, 55.631878293201382, 0.0],
          [-0.287275096923133, 55.698687090737742, 0.0],
          [-0.210666128017163, 55.742830291049387, 0.0],
          [-0.158559960420558, 55.778314385160598, 0.0],
          [-0.139584533122806, 55.791751603310729, 0.0],
          [-0.091405656965265, 55.84524464346427, 0.0],
          [-0.018779584299753, 55.931836123342748, 0.0],
          [0.003358471897006, 55.956713626777884, 0.0],
          [0.081509340993633, 56.020638292413828, 0.0],
          [0.166190412310987, 56.08917668776558, 0.0],
          [0.301048773159705, 56.187061467914468, 0.0],
          [0.385250500585639, 56.249472323098708, 0.0],
          [0.471777816194661, 56.304150448722481, 0.0],
          [0.544371712902025, 56.327541835891971, 0.0],
          [0.639486189680505, 56.354353211403968, 0.0],
          [0.708051192135852, 56.377684569925968, 0.0],
          [0.7757104834676, 56.403947807257161, 0.0],
          [0.811850826547054, 56.416584818588561, 0.0],
          [0.845809452859301, 56.433858340771891, 0.0],
          [0.875389888730138, 56.442423054921314, 0.0],
          [0.961275969858986, 56.523777260857749, 0.0],
          [1.049393993107037, 56.600879898463688, 0.0],
          [1.12138948925268, 56.656538345691978, 0.0],
          [1.195776018623256, 56.716156634473059, 0.0],
          [1.267338870506483, 56.780365447323497, 0.0],
          [1.362354574480007, 56.842563456254247, 0.0],
          [1.38563759329746, 56.874172756237193, 0.0],
          [1.540962387521492, 56.928811333410088, 0.0],
          [1.678359817254713, 56.976577157071432, 0.0],
          [1.806996674021855, 57.025221609131897, 0.0],
          [1.900562454594237, 57.061200171888423, 0.0],
          [1.963884379997944, 57.082349285476397, 0.0],
          [2.090341895158907, 57.15161587041775, 0.0],
          [2.201378339152418, 57.215687733610437, 0.0],
          [2.291372684371484, 57.271798281370067, 0.0],
          [2.370616032105466, 57.319230901442957, 0.0],
          [2.496311464556911, 57.388253431086959, 0.0],
          [2.678467671296207, 57.49472342931486, 0.0],
          [2.831804875117117, 57.583453242243422, 0.0],
          [3.02639007828893, 57.698592182918937, 0.0],
          [3.21250229467811, 57.806961921947313, 0.0],
          [3.392376710353351, 57.915849381545407, 0.0],
          [3.613607325268804, 58.040310245557279, 0.0],
          [3.816455771311651, 58.158281333625247, 0.0],
          [4.06583902327281, 58.300444919063722, 0.0],
          [4.242447981136039, 58.406556477386523, 0.0],
          [4.359958060503891, 58.470274495355802, 0.0],
          [4.537371534827709, 58.567911584986248, 0.0],
          [4.701087125898518, 58.657781603475321, 0.0],
          [4.834130887223774, 58.731538411513327, 0.0],
          [4.971775596562367, 58.807818252771654, 0.0],
          [5.098521086959207, 58.875466842766187, 0.0],
          [5.174849152996382, 58.916492903438083, 0.0],
          [5.22323295803117, 58.956245394424528, 0.0],
          [5.272959781715889, 58.980021274472982, 0.0],
          [5.335231589892102, 59.062090449202437, 0.0],
          [5.431633700079099, 59.082345550281559, 0.0],
          [5.467629747117138, 59.082007542790038, 0.0],
          [5.569624332622693, 59.139662631734232, 0.0],
          [5.603630446002958, 59.159245812671422, 0.0],
          [5.639940948676148, 59.173819865462058, 0.0],
          [5.705375710236426, 59.179351559672618, 0.0],
          [5.78087352748788, 59.189803867136739, 0.0],
          [5.849970586279176, 59.189878553810757, 0.0],
          [5.935195686480432, 59.264588488744877, 0.0],
          [6.028782710165244, 59.308624614370977, 0.0],
          [6.032527251294272, 59.339584271688302, 0.0],
          [6.0493533947543, 59.353780807058712, 0.0],
          [6.059529214593913, 59.380047966133937, 0.0],
          [6.082586915489145, 59.396816794862431, 0.0],
          [6.14308086106478, 59.403545502932673, 0.0],
          [6.160574462897319, 59.416700759999799, 0.0],
          [6.183991207748878, 59.423298445432941, 0.0],
          [6.206851161107585, 59.450705849803732, 0.0],
          [6.243957354820351, 59.470250694842377, 0.0],
          [6.247002758175906, 59.503875441769537, 0.0],
          [6.332731606931392, 59.523055460949777, 0.0],
          [6.448792171031728, 59.550688260381307, 0.0],
          [6.494855252417684, 59.560037933998053, 0.0],
          [6.595895848256497, 59.562731130426243, 0.0],
        ],
      },
    },
  ],
};
