import React, { ReactNode } from "react";
import { Box, Button } from "@mui/material";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import styles from "./Drawer.module.scss";

interface Props {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  secondDrawer?: boolean;
  children?: ReactNode;
  width?: string;
  left?: string;
  hideBackdrop?: boolean;
}

const Drawer = ({
  collapsed,
  setCollapsed,
  children,
  left,
  width,
  hideBackdrop = false,
}: Props) => {
  return (
    <Box
      className={styles.collapsedDetail}
      sx={{
        width: width,
        left: left,
        border: collapsed ? "1px solid #393c56" : "none",
        borderTop: "0",
      }}
    >
      <Box className={styles.collapseIconWrap}>
        {hideBackdrop ? (
          <Box display={collapsed ? "block" : "none"}> {children} </Box>
        ) : (
          collapsed && children
        )}
        <Button
          className={styles.collapseIcon}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {collapsed ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
        </Button>
      </Box>
    </Box>
  );
};
export default Drawer;
