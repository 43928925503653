import { useState } from "react";

const usePasswordInput = ({
  setCurrentPassword,
}: {
  setCurrentPassword: (password: string) => void;
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCurrentPassword(event.target.value);
  };

  return {
    handleClickShowPassword,
    showPassword,
    handleChange,
  };
};

export default usePasswordInput;
