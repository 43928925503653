import { FormEvent } from "react";
import { WORKSPACE_MODE } from "../../../utils/Constants";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import styles from "../../../layout/Dialog/Dialog.module.scss";

export type WorkStationData = {
  workStationName: string;
  workStationMode: string;
};

interface CreateFolderDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  name: string;
  label: string;
  title: string;
  buttonName: string;
  addEditFolder: (value: string | WorkStationData) => void;
  oldWorkStationName?: string;
  isEditDialog?: boolean;
}

const CreateEditWorkStationDialog = ({
  open,
  setOpen,
  title,
  name,
  label,
  buttonName,
  addEditFolder,
  oldWorkStationName,
  isEditDialog = true,
}: CreateFolderDialogProps) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());

          if (name === "user_email" && formJson.user_email) {
            const userEmail = formJson.user_email;
            addEditFolder(userEmail);
          } else {
            const workStationName = formJson.work_station_name;
            const workStationMode = formJson.work_station_mode;
            addEditFolder({ workStationName, workStationMode });
          }
          handleClose();
        },
      }}
      className={styles.dialogMain}
      classes={{ paper: styles.dialogWrap }}
    >
      <DialogTitle className={styles.dialogHead}>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id={name}
          name={name}
          label={label}
          type="text"
          fullWidth
          variant="standard"
          defaultValue={oldWorkStationName}
          InputLabelProps={{
            classes: {
              root: styles.dialogLabel,
              focused: styles.dialogLabelFocused,
            },
          }}
          InputProps={{
            classes: {
              root: styles.dialogInput,
              input: styles.test,
              focused: styles.dialogInputFocused,
            },
          }}
          className={styles.dialogTextFild}
        />
        {name !== "user_email" && !isEditDialog && (
          <Box
            display="flex"
            flexDirection="column"
            className={styles.modeWrap}
          >
            <h6 className={styles.modesHead}>Modes :</h6>
            <FormControl>
              <RadioGroup
                className={styles.radioGroup}
                defaultValue="cloud"
                name="work_station_mode"
              >
                {WORKSPACE_MODE.map((workStationMode) => (
                  <FormControlLabel
                    className={styles.dialogtext}
                    key={workStationMode}
                    value={workStationMode}
                    control={<Radio className={styles.radioButton} />}
                    label={workStationMode}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button className={styles.button} onClick={handleClose}>
          Cancel
        </Button>
        <Button className={styles.buttonCreate} type="submit">
          {buttonName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditWorkStationDialog;
