import { Dialog } from "@mui/material";
import styled from "styled-components";
import useFireBaseAuth from "../../context/useFireBaseAuth";
import ActionButton from "../ActionButon";
import { formatSeconds } from "../../utils/utils";

const WarningHeader = styled.div`
  font-size: 24px;
`;

const WarningText = styled.div`
  font-size: 14px;
  padding: 16px 0;
`;

const UserInactiveModal: React.FC = () => {
  const { userInactiveModalIsOpen, secondsInactiveCounter } = useFireBaseAuth();

  return (
    <Dialog
      maxWidth="lg"
      open={userInactiveModalIsOpen}
      PaperProps={{
        sx: {
          backgroundColor: "#111326",
          color: "#fff",
          padding: "27px",
          width: "360px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          backdropFilter: "blur(25px)",
          borderRadius: "4px",
        },
      }}
    >
      <WarningHeader>Are You Still There?</WarningHeader>
      <WarningText>
        Time until logout: {formatSeconds(secondsInactiveCounter)}
      </WarningText>
      <ActionButton
        type="button"
        label="Yes"
        variantType="contained"
        style={{ marginLeft: "auto", marginRight: "auto" }}
      />
    </Dialog>
  );
};

export default UserInactiveModal;
