import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnalyzeShape,
  setIsOpenPolygonShipAnalysisModal,
  setPolygonDataForSlider,
  setLastFetchedIndex,
  fetchPolygonAnalysisData,
  setResetPolygonData,
} from "./PolygonShipAnalysis.store";
import { setMinimizePolygonModal } from "../MainMap.store";
import { RootState } from "../../../redux/store";
import Modal from "../../../components/Modal/Modal";
import PolygonShipAnalysisSelectDate from "./PolygonShipAnalysisSelectDate/PolygonShipAnalysisSelectDate";
import PolygonShipAnalysisSlider from "./PolygonShipAnalysisSlider/PolygonShipAnalysisSlider";
import { ShipSvgType } from "../../../utils/ShipSvgs";
import { DateRange, MapContext } from "../MainMap";
import styles from "./PolygonShipAnalysis.module.scss";
import { useSnackbar } from "../../../context/SnackbarContext";
import moment from "moment";
import { ThunkDispatch } from "@reduxjs/toolkit";

interface PolygonShipAnalysisProps {
  setSelectedShape: (val: ShipSvgType | null) => void;
  dateRange: DateRange;
  setDateRange: (val: DateRange) => void;
  handleSimilarShips: () => Promise<void>;
  showSimilarShipsButton: boolean;
  lastSelectedOption: string;
  setLastSelectedOption: (lastSelectedOption: string) => void;
}

const PolygonShipAnalysis = ({
  setSelectedShape,
  dateRange,
  setDateRange,
  handleSimilarShips,
  showSimilarShipsButton,
  lastSelectedOption,
  setLastSelectedOption,
}: PolygonShipAnalysisProps) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const { minimizePolygonModal } = useSelector(
    (state: RootState) => state.mainMap,
  );
  const { polygonData, error, totalPages } = useSelector(
    (state: RootState) => state.polygonShipAnalysis,
  );
  const { setShowShips, setShowSimilarShips, features } =
    useContext(MapContext);

  const [showPolygonShipAnalysisSlider, setShowPolygonShipAnalysisSlider] =
    useState<boolean>(false);
  const limit = 30000; // we can change it if needed
  const [skip, setSkip] = useState<number>(0);
  const isLastPage = totalPages !== 0 ? skip + limit > totalPages : false;
  const [loading, setLoading] = useState<boolean>(false);
  const [polygonSliderValue, setPolygonSliderValue] = useState<number>(0);
  const handleClose = () => {
    dispatch(setIsOpenPolygonShipAnalysisModal(false));
    dispatch(setAnalyzeShape([]));
    setDateRange({
      startDate: new Date(new Date().getTime() - 60 * 60 * 1000),
      endDate: new Date(),
    });
    setSelectedShape(null);
    setShowShips(true);
    setShowSimilarShips(true);
  };
  const { setNewSnackBar } = useSnackbar();

  const settingPolygonDataForSlider = () => {
    let index = 1,
      startDate = moment
        .utc(
          `${moment((dateRange.startDate as any).$d).format(
            "YYYY-MM-DD",
          )} 00:00:00`,
          "YYYY-MM-DD HH:mm:ss",
        )
        .unix(),
      endDate = moment
        .utc(
          `${moment((dateRange.endDate as any).$d).format(
            "YYYY-MM-DD",
          )} 23:59:59`,
          "YYYY-MM-DD HH:mm:ss",
        )
        .unix();
    let size = Math.ceil((endDate - startDate) / 900);
    let dataForSlider: any = Array.from({ length: size }, () => []);
    let sortedPolygonData = polygonData
      .slice()
      .sort((a: any, b: any) => a.timestamp / 1000 - b.timestamp / 1000);
    let set = new Set();
    let lastPopulatedIndex = -1;
    sortedPolygonData.forEach((data: any) => {
      if (data.timestamp / 1000 > startDate + 15 * 60 * index) {
        index++;
        set.clear();
      }

      if (!set.has(data.synmax_ship_id)) {
        dataForSlider[index - 1].push(data);
        set.add(data.synmax_ship_id);
        lastPopulatedIndex = index - 1;
      }
    });
    dispatch(setPolygonDataForSlider(dataForSlider));
    dispatch(setLastFetchedIndex(lastPopulatedIndex));
  };

  useEffect(() => {
    settingPolygonDataForSlider();
  }, [polygonData]);
  useEffect(() => {
    if (error) {
      setNewSnackBar({ message: error, severity: "error" });
    }
  }, [error]);

  const loadMoreData = async () => {
    if (skip + limit > totalPages || loading) return;

    const area = features.data?.[lastSelectedOption]?.geometry;
    const shipData = {
      area,
      end:
        moment(new Date(dateRange.endDate)).format("YYYY-MM-DD") + " 23:59:59",
      start:
        moment(new Date(dateRange.startDate)).format("YYYY-MM-DD") +
        " 00:00:00",
      limit,
      skip: skip + limit,
      isExist: true,
    };
    setLoading(true);
    try {
      setSkip((prev) => prev + limit);
      await dispatch(fetchPolygonAnalysisData(shipData));
    } catch (error) {
      console.error("Error loading more data:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetValuesForSlider = () => {
    setPolygonSliderValue(0);
    setSkip(0);
    dispatch(setResetPolygonData());
    setShowPolygonShipAnalysisSlider(false);
  };

  return (
    <Modal
      headerTitle="Polygon Ship Analysis"
      handleClose={handleClose}
      onClickMinimize={() => {
        dispatch(setMinimizePolygonModal(true));
      }}
      onMinimizeClick={minimizePolygonModal}
      className={styles.polygonShip}
    >
      <PolygonShipAnalysisSelectDate
        setShowPolygonShipAnalysisSlider={setShowPolygonShipAnalysisSlider}
        dateRange={dateRange}
        setDateRange={setDateRange}
        showPolygonShipAnalysisSlider={showPolygonShipAnalysisSlider}
        lastSelectedOption={lastSelectedOption}
        setLastSelectedOption={setLastSelectedOption}
        limit={limit}
        skip={skip}
        resetValuesForSlider={resetValuesForSlider}
      />
      <PolygonShipAnalysisSlider
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        handleSimilarShips={handleSimilarShips}
        showSimilarShipsButton={showSimilarShipsButton}
        showPolygonShipAnalysisSlider={showPolygonShipAnalysisSlider}
        loadMoreData={loadMoreData}
        timelineLoading={loading}
        isLastPage={isLastPage}
        polygonSliderValue={polygonSliderValue}
        setPolygonSliderValue={setPolygonSliderValue}
      />
    </Modal>
  );
};

export default PolygonShipAnalysis;
