import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import {
  addTabData,
  setIsDashboard,
  setTabValue,
} from "../../layout/Header/Header.store";
import { setIsRecent } from "../../layout/HomePanel/HomePanel.store";
import { addNotificationData } from "../../layout/Header/Notification/Notification.store";
import {
  deleteSharedData,
  fetchSharedData,
  setIsShared,
  updateSharedData,
} from "./Shared.store";
import WorkCard from "../../layout/WorkCard/WorkCard";
import CreateEditWorkStationDialog, {
  WorkStationData,
} from "../Theia/Dialog/CreateEditWorkStationDialog";
import DeleteDialog from "../../layout/Dialog/DeleteDialog";
import {
  findWorkStationInTabs,
  getUserByEmail,
  onDragStart,
} from "../../common/Common";
import { Box, Typography } from "@mui/material";
import styles from "./Shared.module.scss";
import useFireBaseAuth from "../../context/useFireBaseAuth";
import { useSnackbar } from "../../context/SnackbarContext";

const Shared = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();

  const { currentUser } = useFireBaseAuth();
  const { users } = useSelector((state: RootState) => state.common);
  const { tabs } = useSelector((state: RootState) => state.header);
  const { isSharedFetch, shared } = useSelector(
    (state: RootState) => state.shared,
  );
  const { setNewSnackBar } = useSnackbar();
  const userId = currentUser.uid;

  const [workStationName, setWorkStationName] = useState<string>("");
  const [workStationId, setWorkStationId] = useState<string>("");
  const [isEditWorkStation, setIsEditWorkStation] = useState<boolean>(false);
  const [isDeleteWorkStation, setIsDeleteWorkStation] =
    useState<boolean>(false);
  const [isShareWorkStation, setIsShareWorkStation] = useState<boolean>(false);

  useEffect(() => {
    if (!isSharedFetch) dispatch(fetchSharedData(userId));
  }, [isSharedFetch, dispatch, userId]);

  const editShared = async (workStationData: WorkStationData | string) => {
    const { workStationName, workStationMode } =
      workStationData as WorkStationData;
    for (const key in tabs) {
      if (tabs[key].id === shared[workStationId].tabId) {
        const data = {
          userId,
          workStationId,
          newWorkStationName: workStationName,
          tabId: key,
        };
        dispatch(updateSharedData(data));
        return;
      }
    }
    const data = {
      userId,
      workStationId,
      newWorkStationName: workStationName,
      newWorkStationMode: workStationMode,
    };
    dispatch(updateSharedData(data));
    setNewSnackBar({ message: "Work Station Updated", severity: "success" });
  };

  const deleteShared = async () => {
    for (const key in tabs) {
      if (tabs[key].id === shared[workStationId].tabId) {
        const data = {
          userId,
          workStationId,
          tabId: key,
        };
        dispatch(deleteSharedData(data));
        return;
      }
    }
    const data = {
      userId,
      workStationId,
    };
    dispatch(deleteSharedData(data));
    setNewSnackBar({ message: "Work Station Deleted", severity: "error" });
  };

  const shareWorkStation = async (userEmail: WorkStationData | string) => {
    const emailUserId = await getUserByEmail(users, userEmail.toString());
    const shareWorkData = {
      userId: emailUserId as string,
      workStationName,
      sharedUserId: userId,
      type: "share",
    };
    dispatch(addNotificationData(shareWorkData));
    setNewSnackBar({ message: "Work Station Shared", severity: "success" });
  };

  const addTabList = (name: string, workId: string) => {
    const workStationData = findWorkStationInTabs(workId, tabs);
    const updateDatedata = {
      userId,
      workStationId: workId,
      isWorkStationDate: true,
    };

    if (workStationData) {
      dispatch(setTabValue(workStationData.value));
    } else {
      const maxTabValue =
        Object.keys(tabs).length > 0
          ? Math.max(...Object.values(tabs).map((item) => item.value)) + 1
          : 0;
      const newTab = {
        userId,
        tabName: name,
        tabValue: maxTabValue,
        workStationId: workId,
      };
      dispatch(addTabData(newTab));
      dispatch(setTabValue(maxTabValue));
    }

    dispatch(setIsDashboard(false));
    dispatch(setIsRecent(false));
    dispatch(setIsShared(false));
    dispatch(updateSharedData(updateDatedata));
  };

  return (
    <Box display="flex" flexDirection="column" className={styles.sharedMain}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        top="0"
        className={styles.sharedWrap}
      >
        <Box className={styles.sharedWorkMain}>
          {Object.keys(shared).length > 0 ? (
            <Box
              sx={{
                gridTemplateColumns: {
                  xl: "repeat(4,1fr)",
                  lg: "repeat(3,1fr)",
                  md: "repeat(2,1fr)",
                },
              }}
              display="grid"
              gridTemplateColumns="repeat(3,1fr)"
              gap={3}
            >
              {Object.entries(shared)
                .sort(([, a], [, b]) => {
                  const createdAtA = a.createdAt?._seconds || 0;
                  const createdAtB = b.createdAt?._seconds || 0;
                  return createdAtB - createdAtA;
                })
                .map(([data, sharedData]) => (
                  <Box
                    key={data}
                    onDragStart={(e) => onDragStart(e, sharedData.name)}
                    draggable
                  >
                    <WorkCard
                      key={data}
                      work={data}
                      workStationName={sharedData.name}
                      setWorkStationName={setWorkStationName}
                      setWorkStationId={setWorkStationId}
                      setIsEditWorkStation={setIsEditWorkStation}
                      setIsDeleteWorkStation={setIsDeleteWorkStation}
                      setIsShareWorkStation={setIsShareWorkStation}
                      addTabList={addTabList}
                    />
                  </Box>
                ))}
            </Box>
          ) : (
            <Typography variant="h6" className={styles.CardAddText}>
              There are no work station available
            </Typography>
          )}
        </Box>
      </Box>
      {isEditWorkStation && (
        <CreateEditWorkStationDialog
          open={isEditWorkStation}
          setOpen={setIsEditWorkStation}
          name="work_station_name"
          label="Work Station Name"
          title="Edit Work Station Name"
          buttonName="Change"
          addEditFolder={editShared}
          oldWorkStationName={workStationName}
        />
      )}
      {isDeleteWorkStation && (
        <DeleteDialog
          open={isDeleteWorkStation}
          setOpen={setIsDeleteWorkStation}
          deleteFolderWorkStation={deleteShared}
        />
      )}
      {isShareWorkStation && (
        <CreateEditWorkStationDialog
          open={isShareWorkStation}
          setOpen={setIsShareWorkStation}
          name="user_email"
          label="Enter User Email"
          title="User Email"
          buttonName="Send"
          addEditFolder={shareWorkStation}
        />
      )}
    </Box>
  );
};

export default Shared;
