import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDataInsidePolygon } from "./PolygonShipAnalysis.services";

export const fetchPolygonAnalysisData = createAsyncThunk(
  "polygonShipAnalysis/fetchPolygonAnalysisData",
  async (shipData: any, { rejectWithValue }) => {
    try {
      const data = await fetchDataInsidePolygon(shipData);
      return { data };
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data?.error || "Internal Server Error",
      );
    }
  },
);
export const polygonShipAnalysisSlice = createSlice({
  name: "polygonShipAnalysis",
  initialState: {
    loading: false,
    error: "",
    isOpenPolygonShipAnalysisModal: false,
    analyzeShape: [] as any,
    polygonData: [] as any,
    layers: [] as any,
    slider: 0,
    totalPages: 0,
    lastFetchedIndex: -1,
  },
  reducers: {
    setIsOpenPolygonShipAnalysisModal: (state, action) => {
      state.isOpenPolygonShipAnalysisModal = action.payload;
    },
    setAnalyzeShape: (state, action) => {
      state.analyzeShape = action.payload;
    },
    setPolygonDataForSlider: (state, action) => {
      state.layers = action.payload;
    },
    setPolygonSlider: (state, action) => {
      state.slider = action.payload;
    },
    setPolygonData: (state, action) => {
      state.polygonData = action.payload;
    },
    setLastFetchedIndex: (state, action) => {
      state.lastFetchedIndex = action.payload;
    },
    setResetPolygonData: (state) => {
      state.polygonData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPolygonAnalysisData.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        fetchPolygonAnalysisData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          const { data } = action.payload;
          state.polygonData = [...state.polygonData, ...data.results];
          state.totalPages = data.paging.total;
        },
      )
      .addCase(fetchPolygonAnalysisData.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setIsOpenPolygonShipAnalysisModal,
  setAnalyzeShape,
  setPolygonDataForSlider,
  setLastFetchedIndex,
  setPolygonSlider,
  setPolygonData,
  setResetPolygonData,
} = polygonShipAnalysisSlice.actions;

export const polygonShipAnalysisReducer = polygonShipAnalysisSlice.reducer;
