import {
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import moment from "moment";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import ToolTipComponent from "../../../../components/ToolTipComponent/ToolTipComponent";
import CheckboxComponent from "../../../../components/CheckboxComponent/CheckboxComponent";
import { MapContext } from "../../MainMap";
import { Box, Button, Slider } from "@mui/material";
import { Add, HelpOutlineOutlined, Remove } from "@mui/icons-material";
import styles from "./PolygonShipAnalysisSlider.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/store";
import { setPolygonSlider } from "../PolygonShipAnalysis.store";

interface PolygonShipAnalysisSliderProps {
  startDate: Date;
  endDate: Date;
  handleSimilarShips: () => Promise<void>;
  showSimilarShipsButton: boolean;
  showPolygonShipAnalysisSlider: boolean;
  loadMoreData: () => Promise<void>;
  timelineLoading: boolean;
  isLastPage: boolean;
  polygonSliderValue: number;
  setPolygonSliderValue: Dispatch<SetStateAction<number>>;
}

const PolygonShipAnalysisSlider = ({
  startDate,
  endDate,
  handleSimilarShips,
  showSimilarShipsButton,
  showPolygonShipAnalysisSlider,
  loadMoreData,
  timelineLoading,
  isLastPage,
  polygonSliderValue,
  setPolygonSliderValue,
}: PolygonShipAnalysisSliderProps) => {
  const [date, setDate] = useState<number>(startDate.valueOf() / 1000);

  const [sliderMaxValue, setSliderMaxValue] = useState(0);
  const [startEpochDate, setStartEpochDate] = useState<any>();

  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();

  const [openTooltip, setOpenTooltip] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log(startDate.valueOf(), ":startDate.valueOf()");
  const { lastFetchedIndex } = useSelector(
    (state: RootState) => state.polygonShipAnalysis,
  );
  const { showShips, setShowShips, showSimilarShips, setShowSimilarShips } =
    useContext(MapContext);
  useEffect(() => {
    setDate(startDate.valueOf() / 1000);
  }, [startDate]);
  useEffect(() => {
    dispatch(setPolygonSlider(Math.ceil(polygonSliderValue)));
    if (polygonSliderValue >= lastFetchedIndex && lastFetchedIndex !== -1) {
      loadMoreData();
    }
  }, [polygonSliderValue]);

  useEffect(() => {
    const startEpoch = moment
      .utc(
        `${moment((startDate as any).$d).format("YYYY-MM-DD")} 00:00:00`,
        "YYYY-MM-DD HH:mm:ss",
      )
      .unix();
    const sliderMax =
      (moment
        .utc(
          `${moment((endDate as any).$d).format("YYYY-MM-DD")} 23:59:59`,
          "YYYY-MM-DD HH:mm:ss",
        )
        .unix() -
        startEpoch) /
      60 /
      15;

    setStartEpochDate(startEpoch);
    setSliderMaxValue(sliderMax);
  }, [startDate, endDate]);
  const handleClick = async () => {};

  const handleSimilarClick = async () => {
    setLoading(true);
    try {
      await handleSimilarShips();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleChange = async (newValue: any) => {
    const availableDataPoints = Math.max(2, lastFetchedIndex);
    if (newValue <= availableDataPoints || isLastPage) {
      setPolygonSliderValue(newValue);
    }
  };

  const getLabel = (value: any) => {
    if (startEpochDate) {
      return moment
        .utc((startEpochDate + value * 15 * 60) * 1000)
        .format("YYYY-MM-DD HH:mm:ss");
    }
  };

  return (
    <>
      {showPolygonShipAnalysisSlider && (
        <Box
          display="flex"
          flexDirection="column"
          className={styles.polygonShipSlider}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb="12px"
          >
            <p className={styles.polygonShipSliderText}>
              Drag slider to view ships in motion
            </p>
            <Box display="flex" alignItems="center" gap={1}>
              <p className={styles.polygonShipSliderText}>
                How to lock a ship?
              </p>
              <ToolTipComponent
                title={
                  "To lock a ship from moving when dragging the slider, simply click on the ship and have it selected." as any
                }
                open={openTooltip}
                handleClose={(e: any) => {
                  setOpenTooltip(false);
                }}
                handleOpen={(e: any) => {
                  setOpenTooltip(true);
                }}
              >
                <HelpOutlineOutlined
                  data-animal-type="bird"
                  className={styles.polygonShipSliderIcon}
                  fontSize="small"
                />
              </ToolTipComponent>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gap={1} marginBottom={1}>
            <Button
              disabled={timelineLoading}
              className={styles.polygonShipSliderIcon}
              classes={{
                disabled: styles.polygonShipSliderIconDisabled,
              }}
              onClick={(e) => {
                if (polygonSliderValue <= 0) return;
                handleChange(polygonSliderValue - 1);
              }}
            >
              <Remove fontSize="small" />
            </Button>
            {/* <DateSlider
              value={date}
              minDate={startDate.valueOf() / 1000}
              maxDate={endDate?.valueOf() / 1000}
              step={900}
              getAriaValueText={valueLabelFormat}
              valueLabelFormat={valueLabelFormat}
              onChange={handleChange}
            /> */}

            <Slider
              className={styles.polygonShipSliderInput}
              size="medium"
              max={sliderMaxValue}
              value={polygonSliderValue}
              disabled={timelineLoading}
              valueLabelFormat={getLabel}
              aria-label="Small"
              valueLabelDisplay="on"
              onChange={(e, a) => handleChange(a)}
              classes={{
                disabled: styles.polygonShipSliderInputDisabled,
              }}
              min={0}
            />
            <Button
              disabled={timelineLoading}
              className={styles.polygonShipSliderIcon}
              classes={{
                disabled: styles.polygonShipSliderIconDisabled,
              }}
              onClick={(e) => {
                handleChange(polygonSliderValue + 1);
              }}
            >
              <Add fontSize="small" />
            </Button>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className={styles.polygonShipSliderDate}>
              {moment(startDate.valueOf()).utc().format("MM/DD/YYYY")}
            </span>
            <span className={styles.polygonShipSliderDate}>
              {moment(endDate.valueOf()).utc().format("MM/DD/YYYY")}
            </span>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={3}
          >
            <CheckboxComponent
              label="Hide ships"
              checked={!showShips}
              onChange={(event) => {
                setShowShips(!event.target.checked);
              }}
            />
            <CustomButton
              buttonName="View Ships In Lists"
              onClick={handleClick}
              variant="contained"
              className={styles.polygonShipButton}
            />
          </Box>
        </Box>
      )}
      {showSimilarShipsButton && (
        <Box
          display="flex"
          flexDirection="column"
          className={styles.polygonShipSlider}
          style={{ padding: 0, border: "none" }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <CheckboxComponent
              label="Hide other ships in polygon when similar ships are shown"
              checked={!showSimilarShips}
              onChange={(event) => {
                setShowSimilarShips(!event.target.checked);
              }}
            />
            <CustomButton
              buttonName="Search Similar Ships"
              loading={loading}
              onClick={handleSimilarClick}
              variant="contained"
              className={styles.polygonShipButton}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default PolygonShipAnalysisSlider;
