import React, { FormEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import styles from "../../../Dialog/Dialog.module.scss";
import { useSnackbar } from "../../../../context/SnackbarContext";

export type LatestNotificationType = {
  id: string;
  userId: string;
  workStationName: string;
  sharedUserId: string;
  notificationId: string;
  notificationType: string;
  status: string;
  isMarkAsRead: boolean;
  createdAt: Date;
  data: any;
};

interface NotificationDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  latestNotification: LatestNotificationType | undefined;
  users: any;
  addShared: (workStationName: string, notificationId: string) => void;
  rejectShared: (id: string) => void;
}

interface User {
  firstName: string;
  lastName: string;
  email?: string;
}

const NotificationDialog = ({
  open,
  setOpen,
  latestNotification,
  users,
  addShared,
  rejectShared,
}: NotificationDialogProps) => {
  const [show, setShow] = useState(true);
  const { setNewSnackBar } = useSnackbar();

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false);
      setOpen(false);
    }, 15000);

    return () => {
      clearTimeout(timeId);
    };
  }, [setOpen]);

  let user: User;

  if (latestNotification?.sharedUserId) {
    user = latestNotification.data;
  } else {
    user = {
      firstName: "Loading",
      lastName: "User",
      email: "Loading Email",
    };
  }

  const handleReject = () => {
    rejectShared(latestNotification?.notificationId as string);
    setOpen(false);
  };

  const handleAccept = () => {
    addShared(
      latestNotification?.workStationName as string,
      latestNotification?.notificationId as string,
    );
    setNewSnackBar({
      message: `${latestNotification?.workStationName} added to Shared folder`,
      severity: "success",
    });
    setOpen(false);
  };

  const handleClose = () => setOpen(false);

  if (!show) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          addShared(
            latestNotification?.workStationName as string,
            latestNotification?.notificationId as string,
          );
          setOpen(false);
        },
      }}
      className={styles.dialogMain}
      classes={{ paper: styles.dialogWrap }}
    >
      <DialogTitle className={styles.dialogHead}>
        Share Work Station
      </DialogTitle>
      <DialogContent>
        <Box className={styles.dialogtext}>
          {user &&
            `${
              user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName}`
                : user.email || "Loading User"
            } shared `}
          <strong>{latestNotification?.workStationName}</strong> with you.
        </Box>
      </DialogContent>
      <DialogActions>
        <Button className={styles.button} onClick={handleReject}>
          Reject
        </Button>
        <Button className={styles.buttonCreate} onClick={handleAccept}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationDialog;
