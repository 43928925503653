import React from "react";
import {
  NotificationType,
  updateNotificationMarkAsReadData,
} from "./Notification.store";
import { UserType } from "../../../common/Common.store";
import { Box, Typography, Button } from "@mui/material";
import { Close, Grading } from "@mui/icons-material";
import styles from "./Notification.module.scss";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";

interface NotificationModalType {
  setOpen: (value: boolean) => void;
  notificationData: NotificationType;
  addShared: (workStationName: string, notificationId: string) => void;
  rejectShared: (id: string) => void;
  users: UserType;
  userId: string;
}

const Notification = ({
  setOpen,
  notificationData,
  addShared,
  rejectShared,
  users,
  userId,
}: NotificationModalType) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  return (
    <Box className={styles.notificationMain}>
      <Box className={styles.notificationHead}>
        <Typography variant="h6">Notification</Typography>
        <Box className={styles.notificationHeadIcon}>
          <Close onClick={() => setOpen(false)} />
        </Box>
      </Box>
      <Box className={styles.notificationWrap}>
        <Box className={styles.readAllWrapper}>
          <Button
            className={styles.readAllButton}
            onClick={() => dispatch(updateNotificationMarkAsReadData(userId))}
            startIcon={<Grading />}
          >
            Mark As Read
          </Button>
        </Box>
        {Object.keys(notificationData).length > 0 ? (
          Object.entries(notificationData)
            .filter(([, notification]) => notification.status === "pending")
            .sort(([, a], [, b]) => b.createdAt.seconds - a.createdAt.seconds)
            .map(([data, notification]) => {
              const userName = notification.data;

              return (
                <Box className={styles.notificationCard} key={data}>
                  <Typography
                    className={styles.cardHead}
                    variant="h6"
                    component="h6"
                  >
                    {`${userName.firstName} ${userName.lastName} share ${notification.workStationName}`}
                  </Typography>
                  <div className={styles.notificationButtonWrap}>
                    {notification.status === "pending" && (
                      <>
                        <Button
                          onClick={() =>
                            addShared(notification.workStationName, data)
                          }
                          className={styles.acceptButton}
                        >
                          Accept
                        </Button>
                        <Button
                          onClick={() => rejectShared(data)}
                          className={styles.rejectButton}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  </div>
                </Box>
              );
            })
        ) : (
          <Typography variant="h6" className={styles.CardAddText}>
            No Notifications
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Notification;
