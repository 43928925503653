import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styles from "./Dialog.module.scss";

interface DeleteDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  deleteFolderWorkStation?: () => void;
  handleCancel?: (value: string, isChangeSave?: boolean) => void;
  handleSave?: () => void;
  deletedTabId?: string;
  deleteButtonName?: string;
  cancelButtonName?: string;
  displayModalTitle?: string;
  displayModalContent?: string;
}

const DeleteDialog = ({
  open,
  setOpen,
  deleteFolderWorkStation,
  handleCancel,
  handleSave,
  deletedTabId,
  deleteButtonName = "Delete",
  cancelButtonName = "Cancel",
  displayModalTitle,
  displayModalContent,
}: DeleteDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown",
  ) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={styles.dialogMain}
      classes={{ paper: styles.dialogWrap }}
    >
      <DialogTitle id="responsive-dialog-title" className={styles.dialogHead}>
        {displayModalTitle || "Are you sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={styles.dialogtext}>
          {displayModalContent ||
            "Do you really want to delete? This process cannot be undone."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={styles.button}
          onClick={() => {
            handleSave && handleSave();
            handleCancel && deletedTabId && handleCancel(deletedTabId, false);
            setOpen(false);
          }}
        >
          {cancelButtonName}
        </Button>
        <Button
          className={styles.buttonCreate}
          onClick={() => {
            deleteFolderWorkStation && deleteFolderWorkStation();
            setOpen(false);
          }}
        >
          {deleteButtonName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
