import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { defaultImplementation } from "../utils/utils";
import {
  EmailAuthProvider,
  User,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signOut,
  updatePassword,
} from "firebase/auth";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { firebaseConfig } from "../App";
import { isEmpty } from "lodash";
import { useSnackbar } from "./SnackbarContext";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { getRemoteConfig } from "firebase/remote-config";

interface FirebaseAuthContextProps {
  handleSetCurrentUser: (user: DocumentData) => void;
  currentUser: DocumentData;
  isInternalUser: () => boolean;
  setUserData: Dispatch<SetStateAction<DocumentData>>;
  handleUserLogOut: () => void;
  handleAuthStateChange: () => void;
  getCurrentUserLoggedIn: () => User | null;
  openChangePasswordModal: () => void;
  changePasswordModalIsOpen: boolean;
  handleClosePasswordModal: () => void;
  changeUserPassword: (
    currentPassword: string,
    newPassword: string,
  ) => Promise<void>;
  userInactiveModalIsOpen: boolean;
  secondsInactiveCounter: number;
  checkFirstLogin: () => boolean;
}

const FireBaseAuthContext = createContext<FirebaseAuthContextProps>({
  handleSetCurrentUser: defaultImplementation,
  currentUser: {},
  isInternalUser: defaultImplementation,
  setUserData: defaultImplementation,
  handleUserLogOut: defaultImplementation,
  handleAuthStateChange: defaultImplementation,
  getCurrentUserLoggedIn: defaultImplementation,
  openChangePasswordModal: defaultImplementation,
  changePasswordModalIsOpen: false,
  handleClosePasswordModal: defaultImplementation,
  changeUserPassword: defaultImplementation,
  userInactiveModalIsOpen: false,
  secondsInactiveCounter: 0,
  checkFirstLogin: defaultImplementation,
});

const useFireBaseAuth = () => {
  const context = useContext<FirebaseAuthContextProps>(FireBaseAuthContext);
  if (!context) {
    throw new Error("useFireBaseAuth must be used with a FireBaseAuthProvider");
  }
  return context;
};

export const app = initializeApp(firebaseConfig);
export const remoteConfig = getRemoteConfig(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const FirebaseFunctions = getFunctions(app);

const FIVE_MIUNTES_REMIAN = 3300; // Five minutes subtracted from one hour
const FIVE_MIUNTES = 300; // Five minutes in seconds

export const FireBaseAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const getCurrentUserLoggedIn = (): User | null => {
    const auth = getAuth();
    const isloggedIn = auth.currentUser;
    return isloggedIn;
  };

  const currentUserIsLoggedIn = getCurrentUserLoggedIn();

  const [userData, setUserData] = useState<DocumentData>({});

  const [changePasswordModalIsOpen, setChangePasswordModalIsOpen] =
    useState<boolean>(false);

  const openChangePasswordModal = () => {
    setChangePasswordModalIsOpen(true);
  };

  const handleClosePasswordModal = () => {
    setChangePasswordModalIsOpen(false);
  };

  const isMFAVerified = localStorage.getItem("isMFAVerified");

  const { setNewSnackBar } = useSnackbar();

  const [secondsInactiveCounter, setSecondsInactiveCounter] =
    useState(FIVE_MIUNTES);
  const [isInactive, setIsInactive] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const resetTimer = () => {
      setIsInactive(false);
      clearTimeout(timeout);
      startTimer();
      setSecondsInactiveCounter(FIVE_MIUNTES);
    };

    const startTimer = () => {
      timeout = setTimeout(() => {
        setIsInactive(true);
      }, FIVE_MIUNTES_REMIAN * 1000);
    };

    startTimer();

    window.addEventListener("click", resetTimer);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("click", resetTimer);
    };
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (secondsInactiveCounter > 0 && isInactive) {
      interval = setInterval(() => {
        setSecondsInactiveCounter((prev) => {
          if (prev <= 1) {
            clearInterval(interval!);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [secondsInactiveCounter, isInactive]);

  useEffect(() => {
    if (secondsInactiveCounter === 0) {
      handleUserLogOut();
    }
  }, [secondsInactiveCounter]);

  useEffect(() => {
    const tokenInterval = setInterval(() => {
      if (currentUserIsLoggedIn) {
        refreshToken();
      }
    }, 5000);

    const refreshToken = async () => {
      if (currentUserIsLoggedIn !== null) {
        const token = await currentUserIsLoggedIn.getIdToken();
        localStorage.setItem("token", token);
      }
    };
    return () => clearInterval(tokenInterval);
  }, [currentUserIsLoggedIn]);

  const setUserAsNotNew = async () => {
    const user = getAuth().currentUser;
    if (user !== null) {
      const docRef = doc(db, "users", user.uid);
      await updateDoc(docRef, {
        isNewUser: false,
      });
    }
  };

  const authenticateUserPassword = async (
    currentPassword: string,
  ): Promise<void> => {
    const user = getAuth().currentUser;
    try {
      if (user !== null) {
        const credential = EmailAuthProvider.credential(
          user.email!,
          currentPassword,
        );

        // Reauthenticate with the provided password
        await reauthenticateWithCredential(user, credential);
      }
    } catch (error) {
      setNewSnackBar({
        message:
          "We are sorry, we were unable to validate your current password",
        severity: "error",
      });
      throw error;
    }
  };

  const updateUserPassword = async (newPassword: string): Promise<void> => {
    const user = getAuth().currentUser;
    try {
      if (user !== null) {
        await updatePassword(user, newPassword);
        setNewSnackBar({
          message: "Password has been updated",
          severity: "success",
        });
      }
    } catch (error) {
      setNewSnackBar({
        message: "We are sorry there was an error updating the password",
        severity: "error",
      });
      throw error;
    }
  };

  const changeUserPassword = async (
    currentPassword: string,
    newPassword: string,
  ): Promise<void> => {
    try {
      await authenticateUserPassword(currentPassword);
      await setUserAsNotNew();
      await updateUserPassword(newPassword);
    } catch (error) {
      console.log("Error in changeUserPassword", error);
    }
  };

  const checkFirstLogin = (): boolean => {
    if (userData?.isNewUser) {
      return true;
    } else {
      return false;
    }
  };

  const handleSetCurrentUser = async (user: any) => {
    const auth = getAuth();
    if (user) {
      const { uid, email } = user;
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data() || {};
      setUserData({ ...userData, uid });
      const token = await user.getIdToken();
      if (uid && (!userData || isEmpty(userData))) {
        // It means this is a new user, so it doesn't have any profile data yet
        // So we create a profile for this user on firebase
        let firstName = "";
        let lastName = "";
        await setDoc(doc(db, "users", uid), {
          email,
          role: "new_user",
          firstName,
          lastName,
          isNewUser: true,
        });
      }
      // We are checking the user's role
      // As you need to be a customer, co-worker, admin, or super-user to view the app
      const role = userData ? userData.role : "";
      if (
        role === "super_user" ||
        role === "admin" ||
        role === "co-worker" ||
        role === "theia_customer" ||
        (role === "temporary_theia_user" &&
          userData.expiry_date.seconds > Date.now() / 1000)
      ) {
        localStorage.setItem("token", token);
      } else if (role === undefined || role === "new_user") {
        setNewSnackBar({
          message: "Email info@synmax.com For Access",
          severity: "error",
        });
      } else if (token) {
        signOut(auth).then(() => {
          setNewSnackBar({
            message: "Email info@synmax.com For Access",
            severity: "error",
          });
        });
      }
    }
  };

  const isInternalUser = () => {
    const superRoles = ["super_user", "admin", "co-worker"];
    return superRoles.includes(userData?.role || "");
  };

  const handleUserLogOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        window.location.href = "/login";
        localStorage.setItem("isMFAVerified", "false");
      })
      .catch((error) => {
        console.log(error, "err");
      });
  };

  useEffect(() => {
    const auth = getAuth();
    if (
      !userData ||
      !userData.uid ||
      !userData?.role ||
      userData?.role === "new_user" ||
      isMFAVerified === "false" ||
      userData?.isNewUser
    )
      return;
    const userCollectionRef = collection(db, "users");
    const userDocRef = doc(userCollectionRef, userData.uid);
    const userUnsubscribe = onSnapshot(userDocRef, (snapshot) => {
      const userDocData = snapshot.data();
      if (!userDocData) return;
      if (!userDocData?.secret) {
        signOut(auth)
          .then(() => {
            setUserData({});
            window.location.href = "/login";
          })
          .catch((error) => {
            console.log(error, "err");
          });
      }
    });
    return () => {
      userUnsubscribe();
    };
  }, [userData]);

  const handleAuthStateChange = () => {
    const auth = getAuth();
    //extra code to check build
    // This function from  firebase is called when our authentication state changes(eg: logged in/ logged out)
    onAuthStateChanged(auth, handleSetCurrentUser);
  };

  return (
    <FireBaseAuthContext.Provider
      value={{
        handleSetCurrentUser,
        currentUser: userData,
        isInternalUser,
        setUserData,
        handleUserLogOut,
        handleAuthStateChange,
        getCurrentUserLoggedIn,
        openChangePasswordModal,
        changePasswordModalIsOpen,
        handleClosePasswordModal,
        changeUserPassword,
        userInactiveModalIsOpen: isInactive,
        secondsInactiveCounter,
        checkFirstLogin,
      }}
    >
      {children}
    </FireBaseAuthContext.Provider>
  );
};

export default useFireBaseAuth;
