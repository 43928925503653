import Box from "@mui/material/Box";
import styled from "styled-components";
import ErrorIcon from "../../assets/icons/ErrorIcon";

const ErroMessageContainer = styled(Box)<{ style?: React.CSSProperties }>(
  (props) => ({
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    border: "1px #FA5849",
    background: "rgba(250, 88, 73, 0.50)",
    padding: "12px",
    ...(props.style && { ...props.style }),
  }),
);

const ErrorMessageText = styled(Box)(() => ({
  color: "#FFF",
  marginLeft: "6px",
}));

const ErrorMessage: React.FC<{
  message: string;
  style?: React.CSSProperties;
}> = ({ message, style }) => {
  return (
    <ErroMessageContainer style={style}>
      <ErrorIcon size={40} />
      <ErrorMessageText>{message}</ErrorMessageText>
    </ErroMessageContainer>
  );
};

export default ErrorMessage;
