import React from "react";

const TrendingShipIcon = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2219_8078)">
      <path
        d="M15.8338 17.5006L17.2463 13.6152C17.3162 13.4231 17.313 13.212 17.2374 13.0221C17.1618 12.8322 17.0191 12.6767 16.8363 12.5852L10.373 9.35351C10.2573 9.29571 10.1298 9.26562 10.0005 9.26562C9.87119 9.26562 9.74366 9.29571 9.62799 9.35351L3.16466 12.5852C2.98181 12.6766 2.83897 12.8321 2.76329 13.022C2.68762 13.2119 2.6844 13.423 2.75424 13.6152L4.16716 17.5006"
        stroke="white"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M12.084 5.83366V2.50033C12.084 2.27931 11.9962 2.06735 11.8399 1.91107C11.6836 1.75479 11.4717 1.66699 11.2507 1.66699H8.75065C8.52964 1.66699 8.31768 1.75479 8.1614 1.91107C8.00512 2.06735 7.91732 2.27931 7.91732 2.50033V5.83366M14.584 5.83366H5.41732C5.1963 5.83366 4.98434 5.92146 4.82806 6.07774C4.67178 6.23402 4.58398 6.44598 4.58398 6.66699V11.667L9.65148 9.32824C9.76096 9.27772 9.88009 9.25156 10.0007 9.25156C10.1212 9.25156 10.2403 9.27772 10.3498 9.32824L15.4173 11.667V6.66699C15.4173 6.44598 15.3295 6.23402 15.1732 6.07774C15.017 5.92146 14.805 5.83366 14.584 5.83366Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 13.334V16.6673"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M1.66699 18.3333C3.33366 18.3333 3.33366 17.5 4.58366 17.5C5.83366 17.5 5.83366 18.3333 7.08366 18.3333C8.33366 18.3333 8.54199 17.5 10.0003 17.5C11.4587 17.5 11.667 18.3333 12.917 18.3333C14.167 18.3333 14.167 17.5 15.417 17.5C16.667 17.5 16.667 18.3333 18.3337 18.3333"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2219_8078">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TrendingShipIcon;
