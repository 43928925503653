import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { AllDetailsTabs } from "../../../../../context/useShipDetails";

// default coordinates for initial render
const defaultShipCoordinates = {
  bunkering_id: null,
  ship1: { x: 0, y: 0 },
  ship2: { x: 0, y: 0 },
};

// Custom hook to identify ship coordinates on satellite image for bunkering events
const useShipClipCoordinates = ({
  tabDetails,
}: {
  tabDetails: AllDetailsTabs;
}) => {
  // Default state to store clip coordinates
  const [shipCoordinates, setShipCoordinates] = useState(
    defaultShipCoordinates,
  );

  // Get features data from redux to identify ship coordinates using useSelector hook
  const { features } = useSelector(
    (state: RootState) => state.theia.opticalSTS || {},
  );

  // useEffect hook to model the ships data and get the coordinates
  useEffect(() => {
    if (features) {
      const clipCoordinates = features
        .map(
          (feature: {
            properties: {
              bunkering_id: any;
              ship1_clip_pxl_x: Number;
              ship1_clip_pxl_y: Number;
              ship2_clip_pxl_x: Number;
              ship2_clip_pxl_y: Number;
            };
          }) => {
            return {
              bunkering_id: feature.properties.bunkering_id,
              ship1: {
                x: feature.properties.ship1_clip_pxl_x,
                y: feature.properties.ship1_clip_pxl_y,
              },
              ship2: {
                x: feature.properties.ship2_clip_pxl_x,
                y: feature.properties.ship2_clip_pxl_y,
              },
            };
          },
        )
        .filter(
          (scenerio: { bunkering_id: any }) =>
            scenerio.bunkering_id === tabDetails.bunkering_id,
        )[0];
      setShipCoordinates(clipCoordinates);
    }
  }, [tabDetails.bunkering_id]);

  // shipCoordinates object to be returned by the hook
  return shipCoordinates;
};

export default useShipClipCoordinates;
