import { FC } from "react";
import { ICurrentEventTypeFilterOptions } from "../FilterArea/FilterArea";
import { FILTER_ATTRIBUTES } from "../../../../utils/Constants";
import { ShipSvgs } from "../../../../utils/ShipSvgs";
import { Box, Checkbox } from "@mui/material";
import { HelpOutlineOutlined, Tune } from "@mui/icons-material";
import styles from "./FilterEventType.module.scss";

interface FilterEventTypeProps {
  updateCheckedFilterValue: (eventType: any, oldValue: any) => void;
  currentEventTypeFilterOptions: ICurrentEventTypeFilterOptions;
  setCurrentEventTypeFilter: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  isSimilarShips: boolean;
}

const FilterEventType: FC<FilterEventTypeProps> = ({
  updateCheckedFilterValue,
  currentEventTypeFilterOptions,
  setCurrentEventTypeFilter,
  isSimilarShips,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      className={styles.filterOptions}
    >
      {FILTER_ATTRIBUTES.map((data: any, key) => {
        if (data.text === "similar" && !isSimilarShips) {
          return null;
        }
        return (
          <Box
            key={key}
            display="flex"
            alignItems="center"
            gap={1}
            className={styles.filterTypeWrapper}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Checkbox
                size="medium"
                className={styles.checkboxMain}
                classes={{ checked: styles.checkboxChecked }}
                checked={
                  currentEventTypeFilterOptions?.[data.text]?.checked
                    ? true
                    : false
                }
                onChange={(e) => {
                  updateCheckedFilterValue(
                    data.text,
                    currentEventTypeFilterOptions?.[data.text]?.checked,
                  );
                }}
              />
              {ShipSvgs({
                color: data.color,
                stroke: data.stroke,
                type: data.type,
              })}
              <span className={styles.singleOption}>{data.text}</span>
              <HelpOutlineOutlined fontSize="small" />
            </Box>
            <Tune
              onClick={(e) => {
                e.stopPropagation();
                data.text !== "bunkering" &&
                  data.text !== "sanctioned" &&
                  setCurrentEventTypeFilter(data.text);
              }}
              className={styles.tuneWrap}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default FilterEventType;
