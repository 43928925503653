import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  containsNumber,
  containsSpecailCharacters,
  containsUppercase,
} from "../../utils/utils";

const usePasswordTextField = ({
  currentPassword,
  setPasswordIsValid,
}: {
  currentPassword: string;
  setPasswordIsValid?: Dispatch<SetStateAction<boolean>>;
}) => {
  const [passwordLengthIsvalid, setPassworLengthIsValid] =
    useState<boolean>(false);
  const [passwordContainsNumber, setPasswordContainsNumber] =
    useState<boolean>(false);
  const [passwordContainsUpperCase, setPasswordContainsUpperCase] =
    useState<boolean>(false);
  const [
    passwordContainsSpecialCharacters,
    setPasswordContainsSpecialCharacters,
  ] = useState(false);

  useEffect(() => {
    validateInput();
  }, [currentPassword]);

  const validateInput = (): void => {
    const lengthIsValid = currentPassword.length >= 8;
    setPassworLengthIsValid(lengthIsValid);
    const passwordContainsNumber = containsNumber(currentPassword);
    setPasswordContainsNumber(passwordContainsNumber);
    const passwordContainsUppercase = containsUppercase(currentPassword);
    setPasswordContainsUpperCase(passwordContainsUppercase);
    const passwordContainsSpecialCharacters =
      containsSpecailCharacters(currentPassword);
    setPasswordContainsSpecialCharacters(passwordContainsSpecialCharacters);
    if (setPasswordIsValid) {
      setPasswordIsValid(
        lengthIsValid &&
          passwordContainsNumber &&
          passwordContainsUppercase &&
          passwordContainsSpecialCharacters,
      );
    }
  };

  return {
    passwordLengthIsvalid,
    passwordContainsUpperCase,
    passwordContainsNumber,
    passwordContainsSpecialCharacters,
  };
};

export default usePasswordTextField;
