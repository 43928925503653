import { AlertColor, Box } from "@mui/material";
import Loader from "../../../../../components/ShipTools/Loader/Loader";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { DateRange, EstimatedLocationOpenType } from "../EstimatedLocation";
import { MapContext } from "../../../MainMap";
import axios from "axios";
import dayjs from "dayjs";
import useShipDetails, {
  BunkeringInterface,
  ShipDetailsInterFace,
} from "../../../../../context/useShipDetails";
import { REACT_APP_BASE_URL } from "../../../../../utils/Constants";

const EstimatedLocationLoader: React.FC<{
  setOpen: Dispatch<SetStateAction<EstimatedLocationOpenType>>;
  open: EstimatedLocationOpenType;
  dateRange: DateRange;
  setDatesWithNoLoactionData: Dispatch<SetStateAction<string[]>>;
  setAllDatesAreMissingLoactions: Dispatch<SetStateAction<boolean>>;
  setNewSnackBar: (snackBarInfo: {
    message: string;
    severity?: AlertColor;
  }) => void;
}> = ({
  setOpen,
  open,
  dateRange,
  setDatesWithNoLoactionData,
  setAllDatesAreMissingLoactions,
  setNewSnackBar,
}) => {
  const {
    setSelectedShipEstimatedLocationGEOJSON,
    setSelectedShipEstimatedLocationLayer,
  } = useContext(MapContext);

  const { bunkeringValue, shipDetailTabValue, selectedEvent, selectedShip } =
    useShipDetails();
  const [loadingPercentage, setLoadingPercantege] = useState(33);
  const [estimatedLoactionController, setEstimatedShipController] =
    useState<AbortController | null>(null);

  const getDateRanges = (): { startDate: string; endDate: string }[] => {
    const dateRanges: any[] = [];
    let currentDate = dayjs(dateRange.startDate).startOf("day");

    while (
      currentDate.isBefore(dayjs(dateRange.endDate).endOf("day")) ||
      currentDate.isSame(dayjs(dateRange.endDate).endOf("day"))
    ) {
      const currentDateRange = {
        startDate: currentDate.format("YYYY-MM-DD HH:mm:ss"),
        endDate: currentDate.endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      };

      dateRanges.push(currentDateRange);

      // Move to the next day
      currentDate = currentDate.add(1, "day");
    }

    return dateRanges;
  };

  useEffect(() => {
    const abortController = new AbortController();
    setEstimatedShipController(abortController);
    setLoadingPercantege(0);

    // Mock loading percentage increase
    const loadinPregressInterval = setInterval(() => {
      if (loadingPercentage < 95) {
        setLoadingPercantege(
          (previousPercantage) =>
            previousPercantage + Math.floor(Math.random() * 5),
        );
      }
    }, 2500);

    const validateLoactionRespones = (locationData: any): void => {
      const missingLoactions = locationData.filter(
        (locationData: any) => locationData.data === null,
      );
      setDatesWithNoLoactionData(missingLoactions);
      if (locationData.length === missingLoactions.length) {
        setAllDatesAreMissingLoactions(true);
      }
    };

    const getEstimatedLoaction = async () => {
      try {
        const token = localStorage.getItem("token");

        let shipToLoadLocations: any = selectedShip;

        if (
          shipDetailTabValue &&
          selectedShip[shipDetailTabValue] === undefined
        ) {
          shipToLoadLocations = selectedEvent[shipDetailTabValue];
        } else {
          if (
            shipDetailTabValue &&
            selectedShip[shipDetailTabValue].mmsi === undefined
          ) {
            const currentShip: BunkeringInterface | ShipDetailsInterFace =
              selectedShip[shipDetailTabValue];

            if (bunkeringValue === "1") {
              const shipIdKey =
                selectedShip[shipDetailTabValue].synmax_ship_id_1;
              const attributionKey =
                selectedShip[shipDetailTabValue].ship1_attribution;
              if (
                shipDetailTabValue !== undefined &&
                shipIdKey !== undefined &&
                selectedShip &&
                selectedShip[shipDetailTabValue].type === "AISSTS"
              ) {
                shipToLoadLocations =
                  currentShip[shipIdKey as keyof typeof currentShip];
              } else if (attributionKey) {
                shipToLoadLocations =
                  currentShip[attributionKey as keyof typeof currentShip];
              }
            }
            const shipBIdKey =
              selectedShip[shipDetailTabValue].synmax_ship_id_2;
            const attributionBKey =
              selectedShip[shipDetailTabValue].ship2_attribution;

            if (bunkeringValue === "2") {
              if (
                shipDetailTabValue !== undefined &&
                shipBIdKey &&
                selectedShip[shipDetailTabValue].type === "AISSTS"
              ) {
                shipToLoadLocations =
                  currentShip[shipBIdKey as keyof typeof currentShip];
              } else if (shipBIdKey) {
                shipToLoadLocations =
                  currentShip[attributionBKey as keyof typeof currentShip];
              }
            }
          } else if (shipDetailTabValue) {
            shipToLoadLocations = selectedShip[shipDetailTabValue];
          }
        }
        const loactionRequests = getDateRanges().map((range) => {
          return axios.post(
            `${REACT_APP_BASE_URL}/ship_location`,
            {
              mmsi: shipToLoadLocations.mmsi,
              start: range.startDate,
              end: range.endDate,
            },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
              signal: abortController.signal,
            },
          );
        });
        const res = await Promise.all(loactionRequests);

        const formatedFeatures = res.map((feature: any) => {
          return {
            startDate: feature.data.start_datetime,
            data: feature.data.response.geojson,
          };
        });

        validateLoactionRespones(formatedFeatures);
        setSelectedShipEstimatedLocationGEOJSON(formatedFeatures);
        setSelectedShipEstimatedLocationLayer(formatedFeatures[0].data);
        clearInterval(loadinPregressInterval);
        setLoadingPercantege(100);
        setOpen("openSlider");
      } catch (error) {
        console.log("getEstimatedLoaction error:", error);
        setNewSnackBar({
          message: "Estimating location failed",
          severity: "error",
        });
        setOpen("selectDate");
        clearInterval(loadinPregressInterval);
      }
    };
    if (open === "openLoder" && selectedShip) {
      getEstimatedLoaction();
    }
    return () => {
      clearInterval(loadinPregressInterval);
      if (estimatedLoactionController !== null) {
        estimatedLoactionController.abort();
      }
    };
  }, [open, selectedShip, selectedEvent]);

  return (
    <Box>
      <Loader
        handleCancelButtonClick={() => {
          setOpen("selectDate");
          if (estimatedLoactionController !== null) {
            estimatedLoactionController.abort();
          }
        }}
        loadingMessage="Loading estimated location..."
        loaderProgress={loadingPercentage}
      />
    </Box>
  );
};

export default EstimatedLocationLoader;
