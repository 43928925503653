import SYNMAX_LOGO from "../../assets/synmax-logo.png";
import styles from "./Auth.module.scss";
import { Outlet } from "react-router";

const AuthWrapper = () => {
  return (
    <div className={styles.loginMain}>
      <div className={styles.loginOverlay}></div>
      <div className={styles.loginWrap}>
        <img src={SYNMAX_LOGO} alt="synmax-logo" />
        <Outlet />
      </div>
    </div>
  );
};

export default AuthWrapper;
