import {
  Button,
  CircularProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
} from "@mui/material";
import SynMaxIcon, { IconType } from "../../Shared/SynMaxIcon";
import {
  CurrentSearchResults,
  FilterOption,
  FilterType,
  ObjectSearchOptionResult,
  VesselSearcOptionResult,
} from "./useSearch";
import { useEffect, useRef, useState } from "react";
import styles from "./AllSearchResultsDialog.module.scss";
import axios from "axios";
import CountryFlagIcon from "../../../components/CountryFlagIcon/CountryFlagIcon";

const JumpToPageButton = styled(Button)`
  height: 35px;
  width: 52px;
  background-color: #00a3e3;
  color: white;
  margin-left: 12px;
`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "40px",
  backgroundColor: "#24263C",
  color: "#ffffff",
  border: 0,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#1a4c6e",
  },
}));

const StyledTableHeader = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#1b1d2d",
  color: "#ffffff",
  border: 0,
  borderRadius: 4,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  maxWidth: "100px",
  color: "#ffffff",
  border: 0,
  fontSize: 14,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const StyledNameCell = styled(TableCell)(({ theme }) => ({
  color: "#ffffff",
  border: 0,
  fontSize: 14,
  display: "flex",
  alignItems: "center",
}));

const AllSearchReusltHeader: React.FC<{ columns: string[] }> = ({
  columns,
}) => (
  <StyledTableHeader>
    {columns.map((column, index) => (
      <StyledTableCell align={index === 0 ? "left" : "right"} key={column}>
        {column}
      </StyledTableCell>
    ))}
  </StyledTableHeader>
);

const SEARCH_RESULTS_LIMIT = 20;

const initialSearchResultsState = {
  vessels: [],
  objects: [],
};

const AllResultsTable: React.FC<{
  columns: { title: string; field: any }[];
  icon: IconType;
  selectedFilter: FilterOption;
  currentSearchValue: string;
  searchTermRefValue: string | null;
  loadShipsOnMap: (
    isAISSearch: boolean,
    searchValue: ObjectSearchOptionResult | VesselSearcOptionResult,
    date: string | undefined,
  ) => void;
  date: string | undefined;
}> = ({
  columns,
  icon,
  selectedFilter,
  searchTermRefValue,
  loadShipsOnMap,
  date,
}) => {
  const [currentSearchResults, setCurrentSearchResults] = useState<any>(
    initialSearchResultsState,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [jumoToPageValue, setJumpToPageValue] = useState("");
  const [vessselTotalPageCount, setVesselsTotalPageCount] = useState(0);
  const [objectTotalPageCount, setObjectTotalPageCount] = useState(0);
  const [loadingSpinnerIsShown, setLoadingSpinnerIsShown] = useState(false);
  const searchResultsTableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setJumpToPageValue(`${currentPage}`);
  }, [currentPage]);

  useEffect(() => {
    setLoadingSpinnerIsShown(true);
    const abortController = new AbortController();
    const getNewOptions = async () => {
      const token = localStorage.getItem("token");

      const numberOfNewRows = searchResultsTableRef?.current?.clientHeight
        ? Math.floor(searchResultsTableRef?.current?.clientHeight / 40) - 1
        : SEARCH_RESULTS_LIMIT;

      const offset =
        currentPage === 1 ? 0 : numberOfNewRows * (currentPage - 1);

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}searchShips?q=${searchTermRefValue}&offset=${offset}&limit=${numberOfNewRows}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
            signal: abortController.signal,
          },
        );

        const searchResultsFormatted: CurrentSearchResults = {
          vessels: res.data.vessels.results.map((item: any) => item.attributes),
          objects: res.data.objects.results.map((item: any) => ({
            object_id: item.object_id,
            ...item.attributes,
          })),
          vesselsTotal: res.data.vessels.paging.total,
          objectsTotal: res.data.objects.paging.total,
        };

        setCurrentSearchResults(searchResultsFormatted);

        setVesselsTotalPageCount(
          Math.ceil(res.data.vessels.paging.total / numberOfNewRows),
        );
        setObjectTotalPageCount(
          Math.ceil(res.data.objects.paging.total / numberOfNewRows),
        );
      } catch (err) {
        console.log(err, "err");
      } finally {
        setLoadingSpinnerIsShown(false);
      }
    };
    if (searchTermRefValue !== null) {
      getNewOptions();
    }
    return () => {
      abortController.abort();
    };
  }, [currentPage, searchResultsTableRef, searchTermRefValue]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilter]);

  const currentDisplayedResults =
    selectedFilter.filterType === FilterType.Vessel
      ? currentSearchResults?.vessels
      : currentSearchResults?.objects;

  const currentResultsCount =
    selectedFilter.filterType === FilterType.Vessel
      ? currentSearchResults?.vesselsTotal
      : currentSearchResults?.vesselsTotal;

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {loadingSpinnerIsShown ? (
        <CircularProgress
          sx={{ color: "#ffffff", margin: "auto" }}
          size={100}
        />
      ) : (
        <TableContainer
          component={Paper}
          sx={{ flex: 1, backgroundColor: "#24263C" }}
          ref={searchResultsTableRef}
        >
          <Table
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="search-results-table"
          >
            <TableHead>
              <AllSearchReusltHeader
                columns={columns.map((column) => column.title)}
              />
            </TableHead>
            <TableBody>
              {currentDisplayedResults.map((row: any, rowIndex: number) => (
                <StyledTableRow
                  key={JSON.stringify(row)}
                  onClick={() => {
                    if (selectedFilter.filterType === FilterType.Vessel) {
                      loadShipsOnMap(true, row, date);
                    } else {
                      loadShipsOnMap(false, row, date);
                    }
                  }}
                >
                  {columns.map((column: any, columnIndex) => {
                    if (column.field === "flag" && row[column.field]) {
                      return (
                        <StyledTableCell align="right">
                          <CountryFlagIcon
                            size={30}
                            countryCode={row[column?.field].toLowerCase()}
                          />
                        </StyledTableCell>
                      );
                    }
                    if (
                      column.field === "name" ||
                      column.field === "acquired"
                    ) {
                      return (
                        <StyledNameCell component="th" scope="row">
                          <SynMaxIcon icon={icon} size={20} />
                          <div className={styles.allResultsRowName}>
                            {row[column.field]}
                          </div>
                        </StyledNameCell>
                      );
                    }
                    return (
                      <StyledTableCell
                        align={columnIndex === 0 ? "left" : "right"}
                      >
                        {row[column.field] === null
                          ? "No info"
                          : row[column.field]}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className={styles.allResultsPagination}>
        <Pagination
          count={
            selectedFilter.filterType === FilterType.Vessel
              ? vessselTotalPageCount
              : objectTotalPageCount
          }
          page={currentPage}
          classes={{
            root: styles.pagination,
            ul: styles.paginationNavigationOption,
          }}
          variant="outlined"
          shape="rounded"
          size="large"
          showFirstButton
          showLastButton
          onChange={(event, page) => {
            setCurrentPage(page);
          }}
        />
        <div className={styles.pageLabel}>Page</div>
        <TextField
          type="number"
          style={{
            backgroundColor: "white",
            color: "white",
          }}
          inputProps={{
            style: {
              height: "10px",
              width: "60px",
              color: "#ffffff",
              backgroundColor: "#000000",
            },
          }}
          value={jumoToPageValue}
          onChange={(event) => {
            setJumpToPageValue(event.target.value);
          }}
        />
        <JumpToPageButton
          variant="contained"
          color="primary"
          onClick={() => {
            const newPageValue = parseInt(jumoToPageValue);
            if (newPageValue <= currentResultsCount) {
              setCurrentPage(parseInt(jumoToPageValue));
            } else {
              setCurrentPage(currentResultsCount);
            }
          }}
        >
          Go
        </JumpToPageButton>
      </div>
    </div>
  );
};

export default AllResultsTable;
