import usePasswordModal from "../ChangePasswordModal/usePasswordModal";
import { useNavigate } from "react-router-dom";
import PasswordForm from "../ChangePasswordForm/ChangePasswordForm";
import { FormEvent } from "react";

const ChangePasswordSignIn: React.FC = () => {
  const {
    handleChangePassword,
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
    passwordError,
    confirmPassword,
    passwordIsValid,
    hanldeSetConfirmPassword,
    setPasswordIsValid,
  } = usePasswordModal();

  const navigate = useNavigate();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    handleChangePassword(event);
    navigate("/theia");
  };

  return (
    <PasswordForm
      onSubmit={handleSubmit}
      currentPassword={currentPassword}
      setCurrentPassword={setCurrentPassword}
      newPassword={newPassword}
      setNewPassword={setNewPassword}
      confirmPassword={confirmPassword}
      hanldeSetConfirmPassword={hanldeSetConfirmPassword}
      passwordError={passwordError}
      passwordIsValid={passwordIsValid}
      setPasswordIsValid={setPasswordIsValid}
    />
  );
};

export default ChangePasswordSignIn;
