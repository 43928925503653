import moment from "moment";
import axios from "axios";
import { api } from "../../../services/api";
import { REACT_APP_BASE_URL } from "../../../utils/Constants";

export const getShipSearch = async (
  shipId: string,
  date: string | undefined,
  token: any,
) => {
  let res;
  const today = moment().startOf("day");
  const momentDate = moment(date);
  if (momentDate.isSame(today, "day")) {
    res = await axios.post(
      `${REACT_APP_BASE_URL}/live_ships`,
      { id: "frontend", shipids: [shipId] },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    );
  } else {
    const start = momentDate.format("YYYY-MM-DD 00:00:00");
    const end = momentDate.endOf("day").format("YYYY-MM-DD HH:mm:ss");
    res = await api.get(
      `/ship_location/${shipId}?start=${start}&end=${end}&limit=1&skip=0`,
    );
  }

  return res;
};
