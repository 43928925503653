import React, { useState } from "react";
import TextInput from "../../../components/TextInput/TextInput";
import Label from "../../../components/Label/Label";
import { Box, Checkbox, FormControlLabel, Link } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "../Auth.module.scss";
import { InfoOutlined } from "@mui/icons-material";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../context/useFireBaseAuth";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState<string | null>(null);
  const auth = getAuth();
  const navigate = useNavigate();

  // Clear input fields and error messages
  const clearState = () => {
    setEmail("");
    setPassword("");
    setErrMessage(null);
  };

  const signInUser = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setErrMessage(null);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      setIsLoading(false);
      const user = userCredential.user;

      const userDoc = await getDoc(doc(db, "users", user.uid));
      const userData = userDoc.data();
      if (
        userData?.isNewUser &&
        userData?.role &&
        userData?.role !== "new_user"
      ) {
        navigate("/change-password");
      } else if (userData?.role && userData?.role !== "new_user") {
        clearState();
        localStorage.setItem("isMFAVerified", "false");
        navigate("/mfa");
      }
      // if ()
    } catch (error: any) {
      setIsLoading(false);
      const errorMessage = error.message;
      setErrMessage(errorMessage);
    }
  };

  return (
    <Box className={styles.loginDetail}>
      <h5 className={styles.loginDetailHead}>Log In To Your Account</h5>
      <form className={styles.detailInputWrapper} onSubmit={signInUser}>
        <Box className={styles.inputWrapper}>
          <Label label="Email" />
          <TextInput
            id="email"
            type="email"
            name="email"
            value={email}
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
            required={true}
          />
        </Box>
        <Box className={styles.inputWrapper}>
          <Label label="Password" />
          <TextInput
            id="password"
            type="password"
            name="password"
            value={password}
            placeholder="Enter password"
            onChange={(e) => setPassword(e.target.value)}
            passwordField={true}
            required={true}
          />
        </Box>
        <Box className={styles.inputLinkWrapper}>
          <FormControlLabel
            className={styles.inputLabel}
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                className={styles.inputCheckbox}
              />
            }
            label="Remember me "
          />
          <Link
            className={styles.inputLink}
            underline="none"
            onClick={() => {
              clearState();
              navigate("/reset-password");
            }}
          >
            Forgot password?
          </Link>
        </Box>
        <LoadingButton
          variant="outlined"
          className={styles.buttonWrap}
          type="submit"
          loading={isLoading}
        >
          Sign In
        </LoadingButton>
        {errMessage && (
          <p className={styles.errorMessage}>
            <InfoOutlined />
            {errMessage}
          </p>
        )}
        <p className={styles.accountWrapper}>
          Don't have an account?
          <Link
            className={styles.inputLink}
            underline="none"
            onClick={(e) => {
              clearState();
              setErrMessage(null);
              navigate("/register");
            }}
          >
            Sign Up
          </Link>
        </p>
      </form>
    </Box>
  );
};

export default SignIn;
