export const indianOcean = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.56734077643094, 17.99498561407787, 0],
            [38.56760635435988, 17.98904269347874, 0],
            [38.56949359575215, 17.9866565508431, 0],
            [38.57101994375493, 17.98669564105374, 0],
            [38.57205352216408, 17.98757662554011, 0],
            [38.57273251592699, 17.98755891269324, 0],
            [38.57499572224472, 17.98638967967334, 0],
            [38.57507333775435, 17.98509640855572, 0],
            [38.5732469355568, 17.98311719236771, 0],
            [38.57273667027188, 17.98124104361271, 0],
            [38.60769069982538, 17.91945491747907, 0],
            [38.62700485578303, 17.91833119778369, 0],
            [38.63249326379016, 17.91609074651477, 0],
            [38.64148472476565, 17.90028914983577, 0],
            [38.64642973718408, 17.88119921137638, 0],
            [38.64836365643322, 17.86360442696272, 0],
            [38.65815920804387, 17.84493136741935, 0],
            [38.66841788126066, 17.83069604354863, 0],
            [38.67184162764602, 17.82006113619364, 0],
            [38.68654127059217, 17.79771008639817, 0],
            [38.69587385552516, 17.78138872169481, 0],
            [38.71962639960655, 17.76134117498295, 0],
            [38.72595662227911, 17.74877487944777, 0],
            [38.72354326828556, 17.74199457702125, 0],
            [38.72636355792969, 17.7396297317939, 0],
            [38.73066027843772, 17.73969666016738, 0],
            [38.75552225929407, 17.70717543564331, 0],
            [38.79135629139258, 17.65845784306322, 0],
            [38.833359146082, 17.58679872144037, 0],
            [38.85925693890925, 17.52253435413811, 0],
            [38.88615749244102, 17.48339595129586, 0],
            [38.92575420656467, 17.39097057657235, 0],
            [38.93643516092809, 17.34865903215881, 0],
            [38.93951561288249, 17.31210572518525, 0],
            [38.96158526985305, 17.26115854020137, 0],
            [38.96765089326717, 17.22817845262285, 0],
            [38.97170917631886, 17.20620502904809, 0],
            [38.99370326631314, 17.19546080564814, 0],
            [38.99650123055665, 17.17794033211434, 0],
            [39.00002517004351, 17.14334485705354, 0],
            [39.02403220041207, 17.03038503753775, 0],
            [39.04016119438438, 16.9825889259563, 0],
            [39.05942900754864, 16.90142721922942, 0],
            [39.09152753543861, 16.83567771844976, 0],
            [39.09920474186372, 16.78970560667115, 0],
            [39.11989746134319, 16.7326817499739, 0],
            [39.14666594361627, 16.69347081335691, 0],
            [39.19762464669638, 16.3349919106565, 0],
            [39.25538143351955, 15.97926183360338, 0],
            [39.3570880443371, 15.84058990373584, 0],
            [39.42319723428446, 15.78459378123588, 0],
            [39.44334455877797, 15.65789316466526, 0],
            [39.42366171518544, 15.57236899429516, 0],
            [39.47817304586837, 15.50934163332772, 0],
            [39.55283627621018, 15.5128526496912, 0],
            [39.59509095959955, 15.50413104514966, 0],
            [39.64239381946711, 15.34802936439154, 0],
            [39.699571590966, 15.25590981491376, 0],
            [39.69120991357797, 15.13486684408, 0],
            [39.71720103484555, 15.07755884160792, 0],
            [39.78748839765979, 15.06311992029112, 0],
            [39.84242950064043, 15.174335425647, 0],
            [39.83480402133506, 15.24676294842855, 0],
            [39.81894129585375, 15.27908769272901, 0],
            [39.79480557304086, 15.26285367778796, 0],
            [39.77744459707575, 15.30582733019975, 0],
            [39.78318268135108, 15.33083566752234, 0],
            [39.79180509118053, 15.35359546080893, 0],
            [39.77928783186197, 15.39552755510345, 0],
            [39.79783455364044, 15.40963672948826, 0],
            [39.80461612144358, 15.4393079127368, 0],
            [39.81764763802045, 15.50366999912857, 0],
            [39.84811911707213, 15.4955951566825, 0],
            [39.89493269634594, 15.50697131003175, 0],
            [39.9049126410945, 15.46886237284757, 0],
            [39.9539203270165, 15.43066517844039, 0],
            [39.97282197109141, 15.41683119382441, 0],
            [40.00165015655905, 15.39437184916943, 0],
            [40.03438562198852, 15.37431034963102, 0],
            [40.07629142166879, 15.34248358942679, 0],
            [40.08619176246947, 15.31908431134747, 0],
            [40.07668080060797, 15.29982754943019, 0],
            [40.04794545298967, 15.26336272834774, 0],
            [40.02765178243516, 15.24146969268542, 0],
            [40.0433179912433, 15.19882719637992, 0],
            [40.16083356054863, 15.03104388626139, 0],
            [40.29765800366974, 14.91787302747753, 0],
            [40.35098272422251, 14.95076451939726, 0],
            [40.38132889978928, 14.95736853779424, 0],
            [40.43071335920698, 14.97299829087906, 0],
            [40.45926850769236, 14.9865198429118, 0],
            [40.52015897711638, 14.96345336981772, 0],
            [40.52968246905323, 14.98275087502533, 0],
            [40.54387435350114, 15.00759639301501, 0],
            [40.56632724486423, 14.99449256627558, 0],
            [40.57261867449107, 14.95532545218365, 0],
            [40.58521919668522, 14.94598641505648, 0],
            [40.61582293352556, 14.95183944696052, 0],
            [40.63559551061842, 14.95919877784567, 0],
            [40.64394454578424, 14.94326539782832, 0],
            [40.63965074765315, 14.92840925784897, 0],
            [40.62524695190002, 14.91075361838102, 0],
            [40.62712770308086, 14.9003643445788, 0],
            [40.65150891331793, 14.8942561396703, 0],
            [40.66928428869759, 14.90786353261907, 0],
            [40.67586202299127, 14.92140036131883, 0],
            [40.69515200309313, 14.9155627884974, 0],
            [40.71288307747088, 14.90673289725052, 0],
            [40.72187765029063, 14.89730426935834, 0],
            [40.71912336555661, 14.88543528023515, 0],
            [40.70809762309527, 14.87868427012351, 0],
            [40.7136273595821, 14.86917126234351, 0],
            [40.72201503103139, 14.87566450516848, 0],
            [40.73583621192776, 14.8685100448424, 0],
            [40.74784925286856, 14.86839786404727, 0],
            [40.76414010057175, 14.86818825938849, 0],
            [40.77057766925492, 14.85515332294112, 0],
            [40.77328263113572, 14.8445885826884, 0],
            [40.78310789746521, 14.84245097736409, 0],
            [40.78472857688318, 14.83461809100937, 0],
            [40.76578233531916, 14.82709411722798, 0],
            [40.76556563374783, 14.81134267834714, 0],
            [40.74580378513068, 14.80399924826306, 0],
            [40.7404942609886, 14.7885189415615, 0],
            [40.73734232592705, 14.76008533609323, 0],
            [40.74824450526789, 14.74775755041509, 0],
            [40.76661166228588, 14.74540833209052, 0],
            [40.78569068733453, 14.73127315275452, 0],
            [40.78632563760445, 14.73030291762985, 0],
            [40.80841146788512, 14.71800874957833, 0],
            [40.81213663102749, 14.71553477850934, 0],
            [40.82725676449075, 14.72139247542889, 0],
            [40.85447850693589, 14.72378155814433, 0],
            [40.85675131754547, 14.72245364082971, 0],
            [40.87651446434872, 14.72979515991957, 0],
            [40.8906987777701, 14.72421176510633, 0],
            [40.90380774047434, 14.72134345161644, 0],
            [40.91880284778449, 14.70807993147939, 0],
            [40.93271144461011, 14.6975485045831, 0],
            [40.95610793241013, 14.69075635931472, 0],
            [40.96456124053439, 14.67891538485758, 0],
            [41.00196062297081, 14.65821407680848, 0],
            [41.01948313615429, 14.66686300625768, 0],
            [41.05139053333578, 14.6631883055277, 0],
            [41.07172293442009, 14.65788481617067, 0],
            [41.09544025975585, 14.65599973003552, 0],
            [41.11258461930345, 14.64805784946501, 0],
            [41.13136895467271, 14.63975119811786, 0],
            [41.18744477322313, 14.62069300223621, 0],
            [41.19355885209897, 14.59922567480954, 0],
            [41.20589159549807, 14.58570908692564, 0],
            [41.21755688944508, 14.56567886963727, 0],
            [41.30725194504308, 14.49604647535017, 0],
            [41.33389013233074, 14.41105410616641, 0],
            [41.41428000684088, 14.30198995354697, 0],
            [41.56775215977704, 14.11379856881278, 0],
            [41.68113464675341, 13.93288429351678, 0],
            [41.72795993719126, 13.91123868707178, 0],
            [41.79827977096728, 13.91569106590317, 0],
            [41.86233645881681, 13.8598915714035, 0],
            [41.91551098289084, 13.86028060761538, 0],
            [41.93152298489683, 13.84996828508174, 0],
            [41.9667454073593, 13.84765209181852, 0],
            [42.08371031620143, 13.66982142879214, 0],
            [42.12284659358866, 13.63072035976494, 0],
            [42.14571329002283, 13.63448172356804, 0],
            [42.22317467105506, 13.55156224374164, 0],
            [42.24520807032373, 13.55665900158254, 0],
            [42.28480071334782, 13.55774044255733, 0],
            [42.29917911845482, 13.50603524459555, 0],
            [42.34548291414303, 13.42668816714842, 0],
            [42.38315665925483, 13.20844399394469, 0],
            [42.49294852225088, 13.17556314385646, 0],
            [42.52548302469615, 13.2051595749508, 0],
            [42.54513250508259, 13.22422683392722, 0],
            [42.55280264332845, 13.22247569277973, 0],
            [42.66792690299634, 13.05557297594374, 0],
            [42.68793939853388, 13.0472092383621, 0],
            [42.71256969136798, 13.04992703231263, 0],
            [42.7272914060809, 13.03988080145696, 0],
            [42.74033048263342, 13.03356072107852, 0],
            [42.74721199789727, 13.01947355209975, 0],
            [42.73454045764267, 12.9881646865032, 0],
            [42.73207107050077, 12.96370288183303, 0],
            [42.76278663377826, 12.89577422458769, 0],
            [42.75664386285067, 12.87381988863902, 0],
            [42.7771239709564, 12.84160014758293, 0],
            [42.77960602944794, 12.8376948840887, 0],
            [42.81409061729605, 12.84231025037488, 0],
            [42.83908194616605, 12.86077712595934, 0],
            [42.91708248308239, 12.77610793726441, 0],
            [42.98661396987421, 12.81937915529794, 0],
            [42.9945890061543, 12.85594618360297, 0],
            [42.99102103883524, 12.89432797740569, 0],
            [42.98749948776163, 12.92935834241955, 0],
            [42.98344655950231, 12.94447540770277, 0],
            [42.99271024522758, 12.94735494480141, 0],
            [43.01066083251838, 12.93655515476808, 0],
            [43.00812069060279, 12.91543776591724, 0],
            [43.06361801402755, 12.83672088875714, 0],
            [43.10043924114657, 12.73717531603157, 0],
            [43.12481584277218, 12.71732962750522, 0],
            [43.28396788093995, 12.47690609636326, 0],
            [43.33600649270601, 12.37514500867093, 0],
            [43.38965560438699, 12.16988770375553, 0],
            [43.37737911787303, 12.04730270913507, 0],
            [43.34981375889582, 11.97310561727229, 0],
            [43.25221894376851, 11.96516836498806, 0],
            [43.18269617923058, 11.92344139971672, 0],
            [43.09928985948032, 11.84445630738263, 0],
            [43.01566750341642, 11.78890023763926, 0],
            [42.94501345416115, 11.77891877204112, 0],
            [42.86363862726234, 11.78047687399821, 0],
            [42.77911897000031, 11.73343651337851, 0],
            [42.72368082282083, 11.65716327269141, 0],
            [42.68329126865792, 11.5502884045866, 0],
            [42.80878875368371, 11.57902965367227, 0],
            [43.02845956558032, 11.58514441545094, 0],
            [43.12268900632724, 11.58054330246395, 0],
            [43.15565362148604, 11.62177169386866, 0],
            [43.21729929673526, 11.48645149728674, 0],
            [43.28374564957401, 11.46157494342772, 0],
            [43.32239349232572, 11.43442304141058, 0],
            [43.47044777318124, 11.3361145264415, 0],
            [43.49878308478969, 11.21272439626775, 0],
            [43.54387091995692, 11.17487515224521, 0],
            [43.73206504139539, 10.87794875171855, 0],
            [44.11333160321505, 10.55815311010126, 0],
            [44.30427676893233, 10.41763934162149, 0],
            [44.42878856533946, 10.39155289608334, 0],
            [44.57823328180087, 10.37251673802999, 0],
            [44.71356290650421, 10.4060324182446, 0],
            [44.92698002597653, 10.40661085759397, 0],
            [44.99954359303781, 10.44199882005507, 0],
            [45.08708076281884, 10.50081317875758, 0],
            [45.26236501008136, 10.59997948296939, 0],
            [45.31041620001287, 10.66377276980055, 0],
            [45.48730040566488, 10.65984844762467, 0],
            [45.60430469651386, 10.74223304035767, 0],
            [45.8001698382857, 10.84510363025312, 0],
            [46.00065274499965, 10.76783201109868, 0],
            [46.22792508335614, 10.77321301790634, 0],
            [46.32744805928052, 10.69425060142876, 0],
            [46.55518040624732, 10.71277816956723, 0],
            [47.0864725583756, 10.95612404794599, 0],
            [47.21916632321367, 11.08122289207832, 0],
            [47.43546772167448, 11.17777769486851, 0],
            [47.5729831094661, 11.16505283262191, 0],
            [47.67791048619029, 11.09811207779807, 0],
            [47.84891096340878, 11.10803202571019, 0],
            [48.14340093166827, 11.13549298100529, 0],
            [48.27261517232402, 11.19177599857522, 0],
            [48.35725753648511, 11.25074334914622, 0],
            [48.58162761819442, 11.30088899602258, 0],
            [48.68517886491446, 11.31028816450704, 0],
            [48.92922668733149, 11.24346929106319, 0],
            [49.30687525012801, 11.30437704869574, 0],
            [49.46420676370217, 11.33619378980071, 0],
            [49.56620803961749, 11.45014881525294, 0],
            [49.65735829761903, 11.46114791754765, 0],
            [49.68747495798735, 11.4743614465619, 0],
            [49.75611831446555, 11.44521139090609, 0],
            [49.86373569881623, 11.46236875996158, 0],
            [49.94323541660847, 11.50987815914763, 0],
            [50.08757525369975, 11.50283635114839, 0],
            [50.14942189264833, 11.54029653358397, 0],
            [50.45233020929216, 11.6888238846933, 0],
            [50.54749176949278, 11.83214643812349, 0],
            [50.62304280404066, 11.92852252821198, 0],
            [50.69917462874457, 11.92488967106023, 0],
            [50.78928043875683, 11.98174125021798, 0],
            [50.85913968353038, 11.94870305054488, 0],
            [50.95035192675431, 11.91980975303398, 0],
            [51.03813357927369, 11.8772111446401, 0],
            [51.14396195231942, 11.86405280097247, 0],
            [51.24290780087404, 11.83218920459321, 0],
            [51.28740562717441, 11.8352069775576, 0],
            [51.27314639097085, 11.8028277640322, 0],
            [51.24583248048949, 11.7426392098395, 0],
            [51.23785231297357, 11.68695370545518, 0],
            [51.12595912755314, 11.51135795562025, 0],
            [51.11428082389698, 11.45230950743604, 0],
            [51.09537664247265, 11.36595389777393, 0],
            [51.06734030674822, 11.22320169534873, 0],
            [51.1201795857159, 11.14374499027906, 0],
            [51.17850679833826, 11.14279984731514, 0],
            [51.14634091815796, 11.07459142110835, 0],
            [51.11060237986283, 10.95177227950096, 0],
            [51.11935764454504, 10.72820229497155, 0],
            [51.16141937876221, 10.58352201822691, 0],
            [51.12432441200681, 10.56403376387125, 0],
            [51.06687758096579, 10.50753453544716, 0],
            [51.02106261987301, 10.39572964036241, 0],
            [50.9092142070984, 10.30979979086057, 0],
            [50.88581862764064, 10.06682253920071, 0],
            [50.80305393680916, 9.558653184564221, 0],
            [50.82322585677213, 9.482760210844122, 0],
            [50.78186320005885, 9.34891476439368, 0],
            [50.65852751879179, 9.216797041658186, 0],
            [50.63322881954323, 9.061066646286822, 0],
            [50.43329220392298, 8.903731609188245, 0],
            [50.36428179548923, 8.732819384946662, 0],
            [50.32915286169724, 8.615014254155215, 0],
            [50.28855491528348, 8.497402522751553, 0],
            [50.13065010013574, 8.230940776545328, 0],
            [49.89755167789043, 8.00872162807978, 0],
            [49.80321485712344, 7.918686986292465, 0],
            [49.80516405802051, 7.745382170222131, 0],
            [49.60701566726817, 7.3744905388402, 0],
            [49.25179730620746, 6.822838251446206, 0],
            [49.06642886258044, 6.396403280580001, 0],
            [49.05887713445072, 6.260929383314367, 0],
            [48.57219705469976, 5.41630675321036, 0],
            [48.20968610966258, 4.926106968477399, 0],
            [47.97791318843802, 4.513518238826125, 0],
            [47.25919604828972, 3.725383994528473, 0],
            [46.44591984322009, 2.88567200264169, 0],
            [46.02965469216979, 2.455825729127798, 0],
            [44.76213942739243, 1.731893859360953, 0],
            [43.61067839455244, 0.7875405521958069, 0],
            [42.69789867258957, -0.169657111336353, 0],
            [41.92535576397256, -1.100983263708284, 0],
            [41.56663777637116, -1.666326167947641, 0],
            [48.75010272606562, -6.749764706741345, 0],
            [60.25001323238411, 10.80001605012192, 0],
            [53.83334525031074, 14.91667493802758, 0],
            [53.10857067684334, 16.6500025855162, 0],
            [52.58750820592427, 16.50672999427668, 0],
            [52.26912169950473, 16.31606176441473, 0],
            [52.11873850219484, 15.87882369541353, 0],
            [52.20609925073914, 15.63415473594649, 0],
            [51.63957969116956, 15.40316344683945, 0],
            [51.3343583451483, 15.24676367622105, 0],
            [50.72691715417157, 15.07622272782262, 0],
            [50.44940192201587, 15.02616311525244, 0],
            [50.15548735993966, 14.85818403603982, 0],
            [50.0349241607381, 14.83248793256766, 0],
            [50.01200432958228, 14.85361412400996, 0],
            [49.92843654154594, 14.85461937660651, 0],
            [49.51654524674139, 14.73959719670479, 0],
            [49.1422177961331, 14.55272045877173, 0],
            [49.02483666173014, 14.48459828743806, 0],
            [48.96215465122312, 14.32081734734431, 0],
            [48.86281563342109, 14.24269084670941, 0],
            [48.68992701715528, 14.06489307066634, 0],
            [48.48138419509294, 14.03577333166975, 0],
            [48.18130227748636, 14.01210209072503, 0],
            [48.03198236069938, 14.06452817808955, 0],
            [47.92913710977424, 14.04967945025524, 0],
            [47.75282248367559, 13.93482254119667, 0],
            [47.66234531265782, 13.90013136190984, 0],
            [47.37781583959882, 13.64564749608193, 0],
            [46.87610088922698, 13.5484340947822, 0],
            [46.66826898783822, 13.43105909177788, 0],
            [46.57605515572422, 13.45041993421604, 0],
            [46.23354493065303, 13.44496430433238, 0],
            [45.6814517510599, 13.3682887604511, 0],
            [45.3691303826611, 13.06653784449004, 0],
            [45.17121431007467, 13.01741623308574, 0],
            [45.07479639701802, 12.93529613559749, 0],
            [45.0390937613838, 12.74337389476956, 0],
            [45.01221369880891, 12.82002291302774, 0],
            [45.01425157517338, 12.84029018199919, 0],
            [44.99353459991743, 12.84219291466462, 0],
            [44.87225571018099, 12.78446337982772, 0],
            [44.72646788554711, 12.74263302249025, 0],
            [44.70389512460605, 12.7848080662388, 0],
            [44.6429076097892, 12.8279931137213, 0],
            [44.5372206353529, 12.80817429704018, 0],
            [44.42427752488587, 12.68087747643682, 0],
            [44.25128948661409, 12.62814248833206, 0],
            [44.17900658031999, 12.64737862325255, 0],
            [44.1428439405235, 12.65699623245284, 0],
            [44.03746804601775, 12.60753045915111, 0],
            [43.92233695392933, 12.6122534028517, 0],
            [43.91505452048605, 12.64076494294132, 0],
            [43.68872171081409, 12.74136155285611, 0],
            [43.59958008563576, 12.74797629132767, 0],
            [43.50487323407283, 12.68490511860539, 0],
            [43.46348016666955, 12.68258479046409, 0],
            [43.45858011723924, 12.69350223442244, 0],
            [43.46868631724941, 12.7101198059951, 0],
            [43.4726339070711, 12.72760601386322, 0],
            [43.47777839039072, 12.76839151647396, 0],
            [43.47761901476242, 12.77136208114147, 0],
            [43.49674324334513, 12.81345020616906, 0],
            [43.49135962532618, 12.83401748436345, 0],
            [43.43914602252244, 12.90697304088518, 0],
            [43.41883136928274, 12.94346226428262, 0],
            [43.41218149645036, 12.96456708954477, 0],
            [43.37481871979744, 13.02106868030356, 0],
            [43.35146175818516, 13.07831668746111, 0],
            [43.30929293633108, 13.13110206395802, 0],
            [43.24818541817359, 13.23894194847323, 0],
            [43.2336189510937, 13.29513879380955, 0],
            [43.24799916330806, 13.32605094397659, 0],
            [43.25442520386521, 13.35542106010452, 0],
            [43.26028444073395, 13.49864680347843, 0],
            [43.2851919998156, 13.58816644176824, 0],
            [43.29114183558853, 13.60522814425562, 0],
            [43.29284236439778, 13.67794679516659, 0],
            [43.2300716105364, 13.83247240615536, 0],
            [43.23987196297897, 13.87461617225967, 0],
            [43.22220888093619, 13.91233506909614, 0],
            [43.16629836938618, 13.95307398899166, 0],
            [43.15580550672446, 13.98612589588083, 0],
            [43.13794373671666, 14.02718883661953, 0],
            [43.1149820900267, 14.06628358218536, 0],
            [43.09242479844383, 14.12891606546491, 0],
            [43.08297557482894, 14.17376478089668, 0],
            [43.07440424669587, 14.23377652396569, 0],
            [43.03989101740586, 14.29243243483566, 0],
            [43.0229486878814, 14.37723307021067, 0],
            [43.00364008695073, 14.41151454804571, 0],
            [43.01576596404076, 14.44241144749709, 0],
            [43.01021026139597, 14.5396800106415, 0],
            [42.99568035998283, 14.61129847241862, 0],
            [42.97592062094959, 14.68270714414176, 0],
            [42.93795530824261, 14.79711861654947, 0],
            [42.934485539105, 14.83485349246405, 0],
            [42.94413079148146, 14.87904706720664, 0],
            [42.92841728018674, 14.94039962728795, 0],
            [42.91020002044166, 14.98482299830575, 0],
            [42.87325857619703, 15.02155025828552, 0],
            [42.86482436704936, 15.07662269009335, 0],
            [42.87253832439973, 15.09384735172643, 0],
            [42.86845450112973, 15.13402170701895, 0],
            [42.82649146451813, 15.16551236198649, 0],
            [42.80397559455985, 15.28063111921989, 0],
            [42.77780060083867, 15.42616969526096, 0],
            [42.75360671642343, 15.53769209598378, 0],
            [42.69820700680172, 15.55992507969226, 0],
            [42.71876011991976, 15.59632109296984, 0],
            [42.71098812774041, 15.63957780896159, 0],
            [42.70178497816196, 15.67772912706604, 0],
            [42.69779663070201, 15.71615371014384, 0],
            [42.80868549560283, 15.85320434420785, 0],
            [42.83283940717921, 15.92006766887378, 0],
            [42.84559420366303, 16.06541747822138, 0],
            [42.81644193647467, 16.24069343175994, 0],
            [42.78744227930213, 16.38062867068193, 0],
            [42.7681691944451, 16.40384486365897, 0],
            [38.56734077643094, 17.99498561407787, 0],
          ],
        ],
      },
      properties: {
        name: "Indian Ocean, Gulf of Aden and Southern Red Sea",
        tessellate: true,
      },
    },
  ],
};
