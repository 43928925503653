import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import usePasswordTextField from "./useValidatedPassword";
import { CSSProperties, Dispatch, SetStateAction } from "react";
import PasswordInput, { InputContainer } from "../PasswordInput/PasswordInput";

const ValidationIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

const ValidationText = styled.div`
  color: #fff;
  font-size: 12px;
`;

const ValidatedPasswordInput: React.FC<{
  currentPassword: string;
  setCurrentPassword: (password: string) => void;
  setPasswordIsValid?: Dispatch<SetStateAction<boolean>>;
  label: string;
  error?: boolean;
  inputStyle?: CSSProperties;
  placeholder?: string;
}> = ({
  currentPassword,
  setCurrentPassword,
  setPasswordIsValid,
  label,
  error,
  inputStyle,
  placeholder,
}) => {
  const {
    passwordLengthIsvalid,
    passwordContainsUpperCase,
    passwordContainsNumber,
    passwordContainsSpecialCharacters,
  } = usePasswordTextField({
    currentPassword,
    setPasswordIsValid,
  });

  return (
    <InputContainer inputStyle={inputStyle}>
      <PasswordInput
        currentPassword={currentPassword}
        setCurrentPassword={setCurrentPassword}
        label={label}
        error={error}
        placeholder={placeholder}
      />
      <ValidationIconContainer>
        {passwordLengthIsvalid ? (
          <CheckCircleIcon
            sx={{
              fontSize: "12px",
              color: "green",
            }}
          />
        ) : (
          <CloseIcon
            sx={{
              fontSize: "12px",
              color: "red",
            }}
          />
        )}

        <ValidationText>Must be at least 8 characters in length</ValidationText>
      </ValidationIconContainer>
      <ValidationIconContainer>
        {passwordContainsUpperCase ? (
          <CheckCircleIcon
            sx={{
              fontSize: "12px",
              color: "green",
            }}
          />
        ) : (
          <CloseIcon
            sx={{
              fontSize: "12px",
              color: "red",
            }}
          />
        )}
        <ValidationText>
          Must contain at least one uppercase letter
        </ValidationText>
      </ValidationIconContainer>
      <ValidationIconContainer>
        {passwordContainsNumber ? (
          <CheckCircleIcon
            sx={{
              fontSize: "12px",
              color: "green",
            }}
          />
        ) : (
          <CloseIcon
            sx={{
              fontSize: "12px",
              color: "red",
            }}
          />
        )}
        <ValidationText>Must have at least one number</ValidationText>
      </ValidationIconContainer>
      <ValidationIconContainer>
        {passwordContainsSpecialCharacters ? (
          <CheckCircleIcon
            sx={{
              fontSize: "12px",
              color: "green",
            }}
          />
        ) : (
          <CloseIcon
            sx={{
              fontSize: "12px",
              color: "red",
            }}
          />
        )}
        <ValidationText>
          Must have at least one special character
        </ValidationText>
      </ValidationIconContainer>
    </InputContainer>
  );
};

export default ValidatedPasswordInput;
