import { api } from "../../services/api";
import {
  AddSharedType,
  UpdateSharedType,
  DeleteSharedType,
} from "./Shared.store";

export const addShared = async (data: AddSharedType) => {
  const response = await api.post("/shared", data);
  return response.data;
};

export const updateShared = async (data: UpdateSharedType) => {
  const response = await api.put("/shared", data);
  return response.data;
};

export const deleteShared = async (data: DeleteSharedType) => {
  const response = await api.delete(
    `/shared/${data.workStationId}?userId=${data.userId}`,
  );
  return response.data;
};

export const fetchShared = async (userId: string) => {
  const response = await api.get(`/shared?userId=${userId}`);
  return response.data;
};
