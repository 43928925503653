import {
  BunkeringInterface,
  ShipDetailsInterFace,
} from "../../context/useShipDetails";

export const getBunkeringEvent = (status: string) => {
  switch (status) {
    case "red":
      return "Unattributed";
    case "blue":
      return "Light";
    case "orange":
      return "Dark";
    case "ais":
      return "AIS";
  }
};

export const updateActiveStatus = (
  object: { [key: string]: ShipDetailsInterFace | BunkeringInterface },
  activeShipId: string,
) => {
  return Object.keys(object).reduce(
    (acc, key) => {
      acc[key] = {
        ...object[key],
        active: key === activeShipId,
      };
      return acc;
    },
    {} as { [key: string]: ShipDetailsInterFace | BunkeringInterface },
  );
};

export const getShipStatusByType = (type: string): string => {
  return type.toLowerCase() === "light"
    ? "blue"
    : type.toLowerCase() === "dark"
      ? "orange"
      : type.toLowerCase() === "ais"
        ? "ais"
        : "red";
};
