import React, { Dispatch, FormEvent, SetStateAction } from "react";
import { styled } from "styled-components";
import PasswordInput from "../PasswordInput/PasswordInput";
import ValidatedPasswordInput from "../ValidatedPasswordInput/ValidatedPasswordInput";
import ActionButton from "../ActionButon";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const ChangePasswordForm = styled.div`
  width: 600px;
  border-radius: 4px;
  background: #111326;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  padding: 36px 72px;
`;

const ChangePasswordTitle = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 36px;
`;

const ActionButtons = styled.div<{ includesCancelButton: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${({ includesCancelButton }) =>
    includesCancelButton ? "space-between" : "center"};
  margin-top: 36px;
`;

interface PasswordFormProps {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  currentPassword: string;
  setCurrentPassword: (input: string) => void;
  newPassword: string;
  setNewPassword: (input: string) => void;
  confirmPassword: string;
  hanldeSetConfirmPassword: (input: string) => void;
  passwordError: string | null;
  passwordIsValid: boolean;
  setPasswordIsValid: Dispatch<SetStateAction<boolean>>;
  cancelButton?: React.ReactNode;
}

const PasswordForm: React.FC<PasswordFormProps> = ({
  onSubmit,
  currentPassword,
  setCurrentPassword,
  newPassword,
  setNewPassword,
  confirmPassword,
  hanldeSetConfirmPassword,
  passwordError,
  passwordIsValid,
  setPasswordIsValid,
  cancelButton,
}: PasswordFormProps) => {
  return (
    <form onSubmit={onSubmit}>
      <ChangePasswordForm>
        <ChangePasswordTitle>Change Password</ChangePasswordTitle>
        {passwordError !== null && (
          <ErrorMessage
            message={passwordError}
            style={{ marginBottom: "36px" }}
          />
        )}
        <PasswordInput
          label="Current Password"
          currentPassword={currentPassword}
          setCurrentPassword={setCurrentPassword}
          placeholder="Current Password"
        />
        <ValidatedPasswordInput
          label="New Password"
          currentPassword={newPassword}
          setCurrentPassword={setNewPassword}
          setPasswordIsValid={setPasswordIsValid}
          error={passwordError !== null}
          inputStyle={{ marginTop: "9px" }}
          placeholder="New Password"
        />
        <PasswordInput
          label="Confirm Password"
          currentPassword={confirmPassword}
          setCurrentPassword={hanldeSetConfirmPassword}
          error={passwordError !== null}
          // errorMessage="Passwords do not match"
          inputStyle={{ marginTop: "9px" }}
          placeholder="Confirm Password"
        />
        <ActionButtons includesCancelButton={cancelButton !== undefined}>
          {cancelButton}
          <ActionButton
            label="Update"
            variantType="contained"
            width="200px"
            type="submit"
            disabled={
              currentPassword.length === 0 ||
              newPassword !== confirmPassword ||
              passwordError !== null ||
              !passwordIsValid
            }
          />
        </ActionButtons>
      </ChangePasswordForm>
    </form>
  );
};

export default PasswordForm;
