export const persianGulf = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [59.38048631955359, 22.70523101488843, 0],
            [61.62487181839235, 25.17481921346707, 0],
            [61.60638783364735, 25.17602792720832, 0],
            [61.58767930958176, 25.17653463543699, 0],
            [61.57598535029427, 25.18339370553574, 0],
            [61.55142703427354, 25.18282374724032, 0],
            [61.49709288095845, 25.16950920664868, 0],
            [61.49712272598925, 25.14572483139607, 0],
            [61.48368468129134, 25.14040575182293, 0],
            [61.47612027857015, 25.1255984692938, 0],
            [61.48548041754529, 25.11029080577267, 0],
            [61.47907082156539, 25.10605505691604, 0],
            [61.45282242458132, 25.09596730108364, 0],
            [61.42426607548159, 25.08482085286434, 0],
            [61.41202193411951, 25.07898114380284, 0],
            [61.4138218094478, 25.06631715383627, 0],
            [61.42549236738645, 25.06159046391062, 0],
            [61.42024789181747, 25.0610506211843, 0],
            [61.35139306374194, 25.076742605887, 0],
            [61.18381364160991, 25.12326423755068, 0],
            [61.18025597525858, 25.13435127503563, 0],
            [61.17674677388582, 25.14329791854077, 0],
            [61.17788415344902, 25.15069427131407, 0],
            [61.17427352798261, 25.16287419504524, 0],
            [61.04953807146397, 25.20157978816239, 0],
            [60.86872349279056, 25.23607741433658, 0],
            [60.84933934388906, 25.2428773697575, 0],
            [60.84699483441932, 25.24760131279806, 0],
            [60.80304898527037, 25.25425139214902, 0],
            [60.79656530776856, 25.25582753817353, 0],
            [60.75895876279919, 25.26307539318109, 0],
            [60.75242927060408, 25.27011654655825, 0],
            [60.72076258092211, 25.27417427879609, 0],
            [60.67981448708404, 25.27496555447554, 0],
            [60.6287575286167, 25.28257848408263, 0],
            [60.61288650404943, 25.28512614918463, 0],
            [60.60708090840962, 25.28976725187497, 0],
            [60.61933686020458, 25.29407455180826, 0],
            [60.62629655982101, 25.30099083754937, 0],
            [60.62207609489385, 25.31470425711284, 0],
            [60.61441335521059, 25.32519528380714, 0],
            [60.61322282101762, 25.33150093408927, 0],
            [60.5960655056434, 25.34621976679558, 0],
            [60.60179385015458, 25.35265197789146, 0],
            [60.60982888184406, 25.3701517267381, 0],
            [60.6078744995968, 25.38709642862066, 0],
            [60.60246177352577, 25.40525297897501, 0],
            [60.57592502335984, 25.42673625508887, 0],
            [60.54946035393071, 25.43975677296649, 0],
            [60.50259338299214, 25.44152855306542, 0],
            [60.4710333821271, 25.4344181603664, 0],
            [60.43902982648201, 25.41460016681177, 0],
            [60.42105919436376, 25.39648105078876, 0],
            [60.40777998434832, 25.37944876173324, 0],
            [60.40281658791059, 25.35243953176434, 0],
            [60.40903126093587, 25.33500973207921, 0],
            [60.43872188027404, 25.33634900150642, 0],
            [60.45345842378741, 25.32959217989164, 0],
            [60.44720422528484, 25.31684876139917, 0],
            [60.46667233755815, 25.29904886821316, 0],
            [60.47602267013244, 25.29543142257433, 0],
            [60.46795353977205, 25.28690893846158, 0],
            [60.45444159247224, 25.29155213243906, 0],
            [60.43038873972515, 25.2966351817518, 0],
            [60.42557223689042, 25.30980591529727, 0],
            [60.41496447801965, 25.31342078157034, 0],
            [60.38385383761285, 25.32055380578385, 0],
            [60.34265140588558, 25.32912169950669, 0],
            [60.32218238761737, 25.32999058936092, 0],
            [60.31238841084888, 25.33529903665671, 0],
            [60.29746112390561, 25.35093492948213, 0],
            [60.3036846599672, 25.35503805323075, 0],
            [60.30996089264922, 25.35793485965586, 0],
            [60.31167423510451, 25.36523329020273, 0],
            [60.30527138105024, 25.37731139727601, 0],
            [60.29397962447183, 25.38529539881377, 0],
            [60.27738395289889, 25.38716089539888, 0],
            [60.25453708979881, 25.38652972175703, 0],
            [60.22863439430577, 25.38102070352552, 0],
            [60.20945632586194, 25.37435772464735, 0],
            [60.19612323764384, 25.36653949477581, 0],
            [60.18863494629917, 25.3567594441361, 0],
            [60.18785403216636, 25.34745058524748, 0],
            [60.20273856924927, 25.33789318115114, 0],
            [60.21089922034337, 25.32907701863415, 0],
            [60.20553341560014, 25.32740411468162, 0],
            [60.19751225866278, 25.32570897875206, 0],
            [60.19072937962443, 25.33130223003472, 0],
            [60.18171372606576, 25.33606188843708, 0],
            [60.16651403433573, 25.335910898897, 0],
            [60.13422888297536, 25.34163794557029, 0],
            [60.11448795499484, 25.34588811149224, 0],
            [60.10764536934497, 25.3567270549568, 0],
            [60.11211814996871, 25.3571660953322, 0],
            [60.11563845164208, 25.36086173697886, 0],
            [60.11332312088376, 25.36690287678773, 0],
            [60.10786435028739, 25.37372256459775, 0],
            [60.08944875735698, 25.37878080141625, 0],
            [60.03613308881433, 25.38145020065064, 0],
            [59.94093792419992, 25.36663082477105, 0],
            [59.89953730784752, 25.35439500164357, 0],
            [59.8960342704974, 25.34909574461573, 0],
            [59.90015972767753, 25.34227057341826, 0],
            [59.88717883191642, 25.34211672688899, 0],
            [59.88936941310271, 25.34578088813388, 0],
            [59.88794600464495, 25.35142479551382, 0],
            [59.88027233932869, 25.35578156991514, 0],
            [59.87084417947166, 25.35688515854816, 0],
            [59.8666999925646, 25.36492238096944, 0],
            [59.85499200873804, 25.3692310481555, 0],
            [59.84728482665814, 25.37601013373897, 0],
            [59.84723293505716, 25.38004978985949, 0],
            [59.85258335686835, 25.3813293524118, 0],
            [59.85249925726411, 25.386990016147, 0],
            [59.84791585612822, 25.39381139014878, 0],
            [59.82584961926384, 25.40002772952503, 0],
            [59.79531587924836, 25.4033003941169, 0],
            [59.75362267405622, 25.40278445461086, 0],
            [59.6951553321319, 25.39832832352444, 0],
            [59.6598655359776, 25.39220289996025, 0],
            [59.60441455195769, 25.38780030744182, 0],
            [59.60343911183254, 25.39223781877863, 0],
            [59.5483771363156, 25.41051593813881, 0],
            [59.52725776677791, 25.42431321438232, 0],
            [59.52164555887746, 25.43955633790274, 0],
            [59.51298919605082, 25.44515700246475, 0],
            [59.489782630699, 25.46464919273919, 0],
            [59.47895204912996, 25.46735336462744, 0],
            [59.45513130068912, 25.4702499435484, 0],
            [59.42643452267586, 25.46983232640943, 0],
            [59.40946148441562, 25.46514213573703, 0],
            [59.27068376592338, 25.43942897947532, 0],
            [59.2559293365773, 25.4371774699589, 0],
            [59.22895286408814, 25.43831868610986, 0],
            [59.19138854791542, 25.43526570262474, 0],
            [59.11141714477966, 25.40557147077172, 0],
            [59.10470956253554, 25.40465287793777, 0],
            [59.08766710704781, 25.40277448460711, 0],
            [59.08483358002057, 25.41000362007862, 0],
            [59.06008734571201, 25.41243091527348, 0],
            [59.02819087425897, 25.41431288699125, 0],
            [58.95478138530834, 25.47196922697091, 0],
            [58.91391599959983, 25.51253748438337, 0],
            [58.88369111951, 25.53111559434693, 0],
            [58.78760301300574, 25.57539278185428, 0],
            [58.72716704654274, 25.5709773111189, 0],
            [58.69983158962556, 25.57003113591455, 0],
            [58.60830404482282, 25.58681861983421, 0],
            [58.57772432104464, 25.58782576862946, 0],
            [58.56144326980631, 25.59194818972446, 0],
            [58.46492386850256, 25.58700305198852, 0],
            [58.39009575793339, 25.59795259427822, 0],
            [58.33906538208259, 25.5907773013771, 0],
            [58.22837776998294, 25.56267842237645, 0],
            [58.17279321931136, 25.56058604771732, 0],
            [58.1207034788472, 25.5605863528308, 0],
            [58.04332642101389, 25.58865613352391, 0],
            [58.00403229063059, 25.63835683260843, 0],
            [57.9730593012339, 25.67595745629679, 0],
            [57.94177984040726, 25.70065011307565, 0],
            [57.86097059995667, 25.68606759257392, 0],
            [57.76160948649784, 25.63372392548003, 0],
            [57.77286929808955, 25.64696734153283, 0],
            [57.78020093810744, 25.69734437702597, 0],
            [57.77538719787956, 25.72149329788624, 0],
            [57.73085165874515, 25.74829467423123, 0],
            [57.68154845783815, 25.74175184498687, 0],
            [57.66199882249832, 25.73181432957506, 0],
            [57.64546110246705, 25.72934408149372, 0],
            [57.50098664751203, 25.74767719892556, 0],
            [57.37855358561005, 25.77656696576892, 0],
            [57.3074570553902, 25.78793034422646, 0],
            [57.29146708382608, 25.8653184297055, 0],
            [57.27850131259134, 25.92482550165522, 0],
            [57.25084042376734, 25.9794664358622, 0],
            [57.17999043034707, 26.0204353339434, 0],
            [57.17877352934736, 26.10053874202584, 0],
            [57.21912814212888, 26.15439511093985, 0],
            [57.19627722158577, 26.18727280455311, 0],
            [57.17670186820907, 26.20408981437488, 0],
            [57.13338510409002, 26.22787260581185, 0],
            [57.10361671890786, 26.29703711987889, 0],
            [57.06705114535199, 26.35654533153479, 0],
            [57.05787132741891, 26.4178033044533, 0],
            [57.04311214711817, 26.44773037983376, 0],
            [57.07978348242194, 26.5051624177993, 0],
            [57.08327059096339, 26.56986364424049, 0],
            [57.06705889465205, 26.68392569557464, 0],
            [57.02698269266038, 26.78176817085399, 0],
            [57.02014086486836, 26.80544669042057, 0],
            [57.01018861770849, 26.83875320919647, 0],
            [56.94437522057946, 26.90200900719634, 0],
            [56.92633368361049, 26.9537082337285, 0],
            [56.90148401780802, 26.98201946948225, 0],
            [56.85045633628071, 27.03329267683359, 0],
            [56.83160080251369, 27.05916127721364, 0],
            [56.82020308163073, 27.08394561422751, 0],
            [56.80171011917444, 27.10056006591018, 0],
            [56.78017028352581, 27.11839501890079, 0],
            [56.73846022488664, 27.12099508160798, 0],
            [56.70399031381434, 27.1277998048452, 0],
            [56.67534764043717, 27.13744611989451, 0],
            [56.63225201600743, 27.13733994873949, 0],
            [56.54099868518919, 27.15017807383286, 0],
            [56.48728909283302, 27.1536646351399, 0],
            [56.45609612314549, 27.15260689517372, 0],
            [56.40527626789092, 27.15749920715551, 0],
            [56.39116440918727, 27.17293463199683, 0],
            [56.36973154709372, 27.18546827240703, 0],
            [56.30739082406988, 27.18066373978685, 0],
            [56.22996485364492, 27.14747593965619, 0],
            [56.20505496283769, 27.13864151676854, 0],
            [56.18395103318217, 27.14321423804867, 0],
            [56.17593068305246, 27.15486765314406, 0],
            [56.14634126031557, 27.14984485350915, 0],
            [56.10863466023555, 27.12729366965738, 0],
            [56.08876620971917, 27.10667971201342, 0],
            [56.02009507562207, 27.0763634675831, 0],
            [55.98680313318161, 27.05526144852152, 0],
            [55.96713458842103, 27.03066176473559, 0],
            [55.91291245981609, 27.01324879823289, 0],
            [55.84822515145614, 27.0001675955177, 0],
            [55.7557549943376, 26.97802685380057, 0],
            [55.69806762462288, 26.97311509648155, 0],
            [55.65074737347989, 26.96858372494565, 0],
            [55.60406772737006, 26.92302476754268, 0],
            [55.57789188727266, 26.88357722172815, 0],
            [55.56367114508048, 26.84328182036356, 0],
            [55.53383820518318, 26.7891143798566, 0],
            [55.48374494153855, 26.75263521499179, 0],
            [55.43399960833829, 26.73732340478977, 0],
            [55.36956735906321, 26.74521618289546, 0],
            [55.33647702347325, 26.77826998169162, 0],
            [55.25695413932021, 26.77990752939417, 0],
            [55.22130384490073, 26.75324557497294, 0],
            [55.20639702004945, 26.72745641359506, 0],
            [55.15650601339087, 26.71733268119067, 0],
            [55.12506454607067, 26.69477397237281, 0],
            [55.08402260606933, 26.68501334440587, 0],
            [55.06918834393522, 26.65787505945852, 0],
            [55.05095241732774, 26.63852189770619, 0],
            [55.01721904452521, 26.63037566416228, 0],
            [54.97215607276893, 26.61243571361549, 0],
            [54.94247625117266, 26.58457671035616, 0],
            [54.88112527063635, 26.55274477451038, 0],
            [54.84769536258807, 26.51680476699081, 0],
            [54.82403087508608, 26.5170184129365, 0],
            [54.80649907336469, 26.51355455136768, 0],
            [54.79824646414495, 26.50258002041652, 0],
            [54.76276768299058, 26.50222915994457, 0],
            [54.72098967147958, 26.5081840931553, 0],
            [54.67523827892686, 26.50599646468725, 0],
            [54.6459417549477, 26.50061364823451, 0],
            [54.61632620336257, 26.50051132508439, 0],
            [54.60359391133107, 26.51578112002033, 0],
            [54.59116324420252, 26.54958286977283, 0],
            [54.56999347207778, 26.58166194065527, 0],
            [54.54691066186355, 26.5950966383288, 0],
            [54.49519388656951, 26.59125140975767, 0],
            [54.46006322049424, 26.58291053663494, 0],
            [54.42383133038036, 26.59304142903519, 0],
            [54.37760522590231, 26.61857117651859, 0],
            [54.35021399602172, 26.67552073652312, 0],
            [54.32503430783549, 26.69680746567975, 0],
            [54.287523867762, 26.72411619812315, 0],
            [54.25555577559626, 26.72613594063705, 0],
            [54.22537747944529, 26.71141276676384, 0],
            [54.18134920227825, 26.7052079062416, 0],
            [54.12136070202517, 26.71669975744315, 0],
            [54.07124573828805, 26.73529703103473, 0],
            [54.03509679797527, 26.74402388021708, 0],
            [53.98736044071134, 26.74817525996739, 0],
            [53.94145069458526, 26.72459824120609, 0],
            [53.8739497850021, 26.71310701084705, 0],
            [53.81182407259967, 26.70983063449547, 0],
            [53.76156850854147, 26.71892035851849, 0],
            [53.73238916389118, 26.71205985732975, 0],
            [53.73190942631665, 26.69748054504575, 0],
            [53.69585492232718, 26.72597420273275, 0],
            [53.65442540137958, 26.74623393355266, 0],
            [53.5847476061041, 26.78875081977593, 0],
            [53.53767002333047, 26.82332302837278, 0],
            [53.50033020046714, 26.84647455898072, 0],
            [53.48497660433574, 26.87349913976352, 0],
            [53.4767369697442, 26.96724781067627, 0],
            [53.44282127625139, 26.98267513849733, 0],
            [53.38716494823404, 26.98894972169192, 0],
            [53.29932682649488, 27.02914041078752, 0],
            [52.96491007023732, 27.14441730534066, 0],
            [52.78421641791225, 27.24258570059123, 0],
            [52.68709010323823, 27.29791460066586, 0],
            [52.5733272604076, 27.38697955407686, 0],
            [52.60468083427345, 27.40747090125053, 0],
            [52.65294464512505, 27.4049638008025, 0],
            [52.66634670055689, 27.42440837020268, 0],
            [52.6645130356341, 27.44693671072025, 0],
            [52.64581876007269, 27.45515224827677, 0],
            [52.60332979981563, 27.4745736454273, 0],
            [52.56575509557043, 27.51469844806666, 0],
            [52.53078371711006, 27.5781609418034, 0],
            [52.48648407423518, 27.62141107182271, 0],
            [52.44554604167289, 27.6454248599944, 0],
            [52.3562496599608, 27.65767532766775, 0],
            [52.33909150582399, 27.66589617570498, 0],
            [52.22722971777215, 27.69229283294102, 0],
            [52.13921587272206, 27.75465905719518, 0],
            [52.08191053621835, 27.80706876048971, 0],
            [52.05220113055171, 27.83776549569045, 0],
            [52.01433835444612, 27.84194458840084, 0],
            [51.95214997082216, 27.83256765639157, 0],
            [51.91658503855439, 27.82757031842052, 0],
            [51.84924438763375, 27.84298267733487, 0],
            [51.78798142887944, 27.83892121540528, 0],
            [51.71427569807251, 27.8220573102738, 0],
            [51.63681307415801, 27.83011282428078, 0],
            [51.58582286411732, 27.83962463235546, 0],
            [51.41259408757711, 27.91131191116593, 0],
            [51.25396399445338, 28.14105537957433, 0],
            [51.28130644518966, 28.23238632271423, 0],
            [51.21693031810425, 28.30622105965035, 0],
            [51.08951705357422, 28.51278947192976, 0],
            [51.07049165431576, 28.62883732749798, 0],
            [51.06350727561819, 28.74509086182682, 0],
            [51.00704775317486, 28.81215649779057, 0],
            [50.91749890909008, 28.81925469514406, 0],
            [50.8685364272601, 28.84619198035401, 0],
            [50.81245844395498, 28.94716238264168, 0],
            [50.83933453447888, 28.98979967922489, 0],
            [50.87360460191776, 29.07705710131461, 0],
            [50.83893048141104, 29.11526744090001, 0],
            [50.7445520981662, 29.12877826273343, 0],
            [50.69608736941619, 29.10823482720316, 0],
            [50.65039445126128, 29.13883834050574, 0],
            [50.64573699523361, 29.18603378451671, 0],
            [50.6539722635243, 29.41782194268801, 0],
            [50.61477359134399, 29.45922074077123, 0],
            [50.55664122807393, 29.53333981649549, 0],
            [50.51730621342393, 29.57478016598861, 0],
            [50.42399167403237, 29.64642028196872, 0],
            [50.32152454071737, 29.76538875339148, 0],
            [50.16308875649634, 29.92509592133453, 0],
            [50.14490508115345, 30.0129511256336, 0],
            [50.13172652729289, 30.10035766971447, 0],
            [50.08858495893517, 30.17192828499587, 0],
            [50.0296635327483, 30.20833262864185, 0],
            [49.96704919912532, 30.20362792439349, 0],
            [49.88749589777097, 30.17368545244272, 0],
            [49.75956710636217, 30.08206793157721, 0],
            [49.65024988265004, 30.03607648389974, 0],
            [49.57966722269158, 29.996481753733, 0],
            [49.53545329274028, 30.00319150929208, 0],
            [49.49867503631402, 30.04802244528269, 0],
            [49.49601169876613, 30.10578917477068, 0],
            [49.45560523619137, 30.14697554146526, 0],
            [49.37896071658895, 30.12385912445473, 0],
            [49.30383651033885, 30.12131805755743, 0],
            [49.26547821492107, 30.14612559582094, 0],
            [49.24824032728964, 30.1455099521324, 0],
            [49.24618002142075, 30.15531367102039, 0],
            [49.23295254051353, 30.22155446688351, 0],
            [49.25171721585008, 30.27719291330336, 0],
            [49.25344026026683, 30.27900103069633, 0],
            [49.26136140492484, 30.33708100164836, 0],
            [49.26108347919057, 30.33955471627991, 0],
            [49.28051566559732, 30.40696434037852, 0],
            [49.28378357349133, 30.41223599364854, 0],
            [49.29945979604934, 30.46188112038096, 0],
            [49.2358052027268, 30.4698877005531, 0],
            [49.22069532442393, 30.5009596745835, 0],
            [49.19018879096188, 30.49756143301248, 0],
            [49.15782213264136, 30.51897888064454, 0],
            [49.10100464489939, 30.51751628825195, 0],
            [49.06646963750224, 30.51542375660604, 0],
            [49.06071281276935, 30.48995140613121, 0],
            [49.05725561689886, 30.46967594127707, 0],
            [49.03783801141563, 30.46220262235623, 0],
            [49.03161528944468, 30.44918781026232, 0],
            [49.0386855964823, 30.43813463710453, 0],
            [49.0514851909239, 30.42757120969059, 0],
            [49.02490798823342, 30.4069983820007, 0],
            [49.00321560740308, 30.40265106549371, 0],
            [48.94833940619436, 30.40961502703196, 0],
            [48.921662879096, 30.40649473816274, 0],
            [48.89853547799884, 30.38120042967315, 0],
            [48.88593430330388, 30.35680492995458, 0],
            [48.87773983583967, 30.32779717651422, 0],
            [48.88043367776216, 30.30472321223439, 0],
            [48.94108738856842, 30.18930474292757, 0],
            [48.94172025600368, 30.13359957458739, 0],
            [48.93910248790765, 30.08092276402951, 0],
            [48.9272357998141, 30.03324673781636, 0],
            [48.88850860763853, 30.0015218918338, 0],
            [48.84663946403834, 29.99699050706659, 0],
            [48.73133608555159, 30.01843122665232, 0],
            [48.64964092251419, 29.93209875192712, 0],
            [48.57051062680635, 29.91244311992773, 0],
            [48.40697304764046, 29.89270422186566, 0],
            [48.35300679196489, 29.80961970812712, 0],
            [48.36021754855754, 29.70181188811314, 0],
            [48.26048488157161, 29.58919185837315, 0],
            [48.1908964575567, 29.53981360623572, 0],
            [48.16578954137523, 29.50716711574587, 0],
            [47.9935071331705, 29.55912844988527, 0],
            [47.81450171477144, 29.45097479263397, 0],
            [47.70976796548021, 29.35471060586351, 0],
            [47.85003254553515, 29.38878172170986, 0],
            [47.81899172956227, 29.36075755473092, 0],
            [47.82238936539976, 29.33354301637281, 0],
            [47.86880885789299, 29.33050759949104, 0],
            [47.93880806376207, 29.35723483621606, 0],
            [47.99687627147836, 29.39902869614007, 0],
            [48.01898815582983, 29.37111074183451, 0],
            [48.05118129712326, 29.3428905051537, 0],
            [48.10700357097001, 29.35564540171271, 0],
            [48.09309273031337, 29.28178182230634, 0],
            [48.14782262138451, 29.0790031183364, 0],
            [48.18870117487766, 28.95730487436259, 0],
            [48.24045338630052, 28.89586238075019, 0],
            [48.2966275577724, 28.8798180646003, 0],
            [48.28521563910769, 28.83253994902516, 0],
            [48.29860283838687, 28.76881873216657, 0],
            [48.34979582979916, 28.74607004143668, 0],
            [48.40317742489433, 28.75223004961454, 0],
            [48.38558357793655, 28.69691062006232, 0],
            [48.40194391469946, 28.5834698461463, 0],
            [48.46552720588723, 28.50305303074069, 0],
            [48.50705510621417, 28.50060345620069, 0],
            [48.50366523434771, 28.46902352247717, 0],
            [48.51498578946796, 28.42126232674726, 0],
            [48.54412300592566, 28.41515259193958, 0],
            [48.54364435335718, 28.39508507459315, 0],
            [48.5249600222616, 28.36079646880325, 0],
            [48.52605289017598, 28.31071516862669, 0],
            [48.61602630294943, 28.2200288225184, 0],
            [48.64444937818563, 28.20280877694197, 0],
            [48.62571955789751, 28.13479191539276, 0],
            [48.63887174315538, 28.07321586399138, 0],
            [48.68556742237588, 28.01997600218009, 0],
            [48.79981048709201, 28.01569495311322, 0],
            [48.79495498542308, 27.98658767661131, 0],
            [48.74809180456958, 27.96991284992172, 0],
            [48.77076650473305, 27.93067111007224, 0],
            [48.83561826255082, 27.89511876174092, 0],
            [48.8878949318236, 27.82617928623105, 0],
            [48.89079308779684, 27.78156367086372, 0],
            [48.89724984491804, 27.7049658735677, 0],
            [48.93313362564208, 27.61829933519854, 0],
            [48.98805307017766, 27.61588865707262, 0],
            [49.06625707583136, 27.56691770830716, 0],
            [49.15012198693012, 27.53993522377384, 0],
            [49.20386764468596, 27.54808688041775, 0],
            [49.25249925366742, 27.52880201084981, 0],
            [49.29161019597358, 27.48531057347124, 0],
            [49.33554286372618, 27.45684582010671, 0],
            [49.3164893393731, 27.40853286669454, 0],
            [49.31475125369406, 27.33115110212361, 0],
            [49.34244021197907, 27.26164677323511, 0],
            [49.40530264529091, 27.20226448783677, 0],
            [49.45244406883246, 27.13806521020121, 0],
            [49.48908656523047, 27.15364275599172, 0],
            [49.5351066583352, 27.19508361027078, 0],
            [49.55257411432898, 27.18217984442002, 0],
            [49.58021441868266, 27.1683176860105, 0],
            [49.5948652660586, 27.10502412449278, 0],
            [49.62708186430685, 27.04674945057002, 0],
            [49.6804223681775, 27.01708013795148, 0],
            [49.70693451126519, 26.95477972810842, 0],
            [49.8841741934024, 26.85095216868588, 0],
            [49.95621127809412, 26.85314488574845, 0],
            [50.08081497437707, 26.71078289056963, 0],
            [50.15771317234991, 26.64332781205465, 0],
            [50.12305695348102, 26.66495762897943, 0],
            [50.10877694713572, 26.66740278390015, 0],
            [50.04171161483889, 26.67644785224662, 0],
            [50.00579503104849, 26.73454155535246, 0],
            [49.99451614192195, 26.6870516853274, 0],
            [50.01074009433632, 26.64532712993473, 0],
            [50.02722323744207, 26.56064961674873, 0],
            [50.07432578693395, 26.46947786504371, 0],
            [50.17847303930547, 26.42756520098495, 0],
            [50.22172738179994, 26.37367385646372, 0],
            [50.22359003548929, 26.25206490974275, 0],
            [50.20743414290017, 26.1719681230143, 0],
            [50.16455458908553, 26.16141529052885, 0],
            [50.14775823061466, 26.047143024042, 0],
            [50.13529397090117, 26.0318041822659, 0],
            [50.04203791960254, 26.18928178521547, 0],
            [49.99524732282131, 26.13711908984412, 0],
            [50.00832004558101, 26.04492159541089, 0],
            [50.01861522661837, 26.00089107963445, 0],
            [50.06826952154327, 26.00309623545733, 0],
            [50.1115694390575, 25.98862805721078, 0],
            [50.11899527804941, 25.95333524664064, 0],
            [50.10792333832486, 25.92371498013506, 0],
            [50.19239184417267, 25.75070743438931, 0],
            [50.24153325633847, 25.69520157810237, 0],
            [50.26213926740935, 25.6463910403834, 0],
            [50.32550027204894, 25.57654245340715, 0],
            [50.43811386099345, 25.44161658171837, 0],
            [50.50848132144663, 25.39141084520126, 0],
            [50.5458587977621, 25.26887776290472, 0],
            [50.59105872686857, 25.06102874718169, 0],
            [50.66850139196113, 24.92906147553409, 0],
            [50.74995289284121, 24.77218827954898, 0],
            [50.82453600764642, 24.75454936220547, 0],
            [50.86535789945666, 24.84906912095649, 0],
            [50.81159042961424, 25.00617629972577, 0],
            [50.81753281799622, 25.07106198590176, 0],
            [50.77617434851275, 25.11269876742221, 0],
            [50.78099222376716, 25.27591108406087, 0],
            [50.7675389564266, 25.42169670970536, 0],
            [50.77422540757324, 25.52073837221805, 0],
            [50.72324628178782, 25.72679760443671, 0],
            [50.79716773687144, 25.74485653772156, 0],
            [50.86512200011982, 25.65476981710946, 0],
            [50.91394211111847, 25.79479815481438, 0],
            [50.94822374357199, 25.81169376091141, 0],
            [50.96814325062137, 25.89945737647972, 0],
            [51.04268721571819, 26.04267035574119, 0],
            [51.21925803515234, 26.16282651131268, 0],
            [51.35548361977781, 26.10985383118318, 0],
            [51.39551758994529, 26.01603259668828, 0],
            [51.4289374163566, 25.95219932609355, 0],
            [51.49483057786998, 25.94965127604992, 0],
            [51.63460501299676, 25.94504515393042, 0],
            [51.62103147663093, 25.91726672667177, 0],
            [51.58310885643073, 25.89495766349359, 0],
            [51.59163239061529, 25.81861616327357, 0],
            [51.59513194326161, 25.77769690577281, 0],
            [51.58420679347887, 25.69642263833878, 0],
            [51.54506314126077, 25.62033807728525, 0],
            [51.4957776162664, 25.57037210154378, 0],
            [51.48600683423936, 25.49984335001299, 0],
            [51.5325325242471, 25.42063456693884, 0],
            [51.546934833888, 25.32298273289754, 0],
            [51.55410821943345, 25.28578068908147, 0],
            [51.62266401892448, 25.27255883042929, 0],
            [51.63211726772991, 25.0541586094561, 0],
            [51.63930835583733, 24.96881474917804, 0],
            [51.52719843962245, 24.84824510285173, 0],
            [51.49523867789965, 24.76005270615759, 0],
            [51.43360032072957, 24.64993644744962, 0],
            [51.42057679206454, 24.6407032587469, 0],
            [51.41867432555441, 24.61726218229915, 0],
            [51.49528785064376, 24.57424263956055, 0],
            [51.41335882175719, 24.49685130802361, 0],
            [51.33055541760411, 24.43010188203851, 0],
            [51.30376132524064, 24.37627200061223, 0],
            [51.2923722309465, 24.30384873195877, 0],
            [51.32908869518895, 24.28856921356195, 0],
            [51.38932232206387, 24.31969618140938, 0],
            [51.4456814742078, 24.32726848117571, 0],
            [51.48523010556434, 24.30142254803282, 0],
            [51.538717073659, 24.24973266735207, 0],
            [51.60490145204151, 24.25616174730665, 0],
            [51.58493575049228, 24.37641768863034, 0],
            [51.61738382302963, 24.36436657995533, 0],
            [51.62383312467223, 24.33440138866421, 0],
            [51.62922509774609, 24.29363375718499, 0],
            [51.70481728542851, 24.2344960386655, 0],
            [51.73066444746213, 24.27741787607268, 0],
            [51.77119033215439, 24.28557107252889, 0],
            [51.78705552037528, 24.25983998406637, 0],
            [51.7863534470234, 24.19720849056529, 0],
            [51.79158494580308, 24.10992032517172, 0],
            [51.8027173266969, 24.0463021974917, 0],
            [51.8341890764363, 23.99663835005753, 0],
            [52.01135298661394, 23.98532114586986, 0],
            [52.15197017516465, 23.96603482365026, 0],
            [52.27633356906767, 23.97954183398198, 0],
            [52.45456757612958, 24.08409298495557, 0],
            [52.55570378334396, 24.14960717552634, 0],
            [52.573278048592, 24.20265681813793, 0],
            [52.63504574860131, 24.19398126934036, 0],
            [52.67404254560951, 24.14805749081162, 0],
            [52.77795001840817, 24.13116309811936, 0],
            [52.90850576161618, 24.15703556916553, 0],
            [53.13878784202527, 24.17508346775053, 0],
            [53.53265485091178, 24.08913545030058, 0],
            [53.6576186480057, 24.06580714868936, 0],
            [53.83730788481576, 24.07396196918303, 0],
            [54.12515594907308, 24.17527116598716, 0],
            [54.12836039682807, 24.21658556001276, 0],
            [54.14374046135877, 24.33515281845765, 0],
            [54.23651424448579, 24.40481323307098, 0],
            [54.43856674060972, 24.55204042109169, 0],
            [54.61517051191866, 24.70065805569775, 0],
            [54.74271548925775, 24.86733766616602, 0],
            [54.78306059613394, 24.85144348780496, 0],
            [55.02566004030768, 24.98990520533744, 0],
            [55.12216826168607, 25.06702505003105, 0],
            [55.4026726415694, 25.38089648328854, 0],
            [55.6340222320115, 25.57655401229796, 0],
            [55.78601622250108, 25.71568037300736, 0],
            [55.85778743012439, 25.72741531007386, 0],
            [55.94831026053372, 25.80280480408825, 0],
            [56.0482437326724, 25.93421199121114, 0],
            [56.15200329382767, 26.14701465518005, 0],
            [56.2024422863656, 26.26497473194798, 0],
            [56.22647384164696, 26.25314284029478, 0],
            [56.22880249237247, 26.21945659320442, 0],
            [56.24612524205554, 26.21374490688487, 0],
            [56.29604242088133, 26.22455816323936, 0],
            [56.33607556011409, 26.34015518397665, 0],
            [56.35265564539368, 26.38096354804767, 0],
            [56.37851828556934, 26.39086112737302, 0],
            [56.42278719916764, 26.38740321460234, 0],
            [56.42342072545413, 26.35808216569789, 0],
            [56.44282316555728, 26.35369908654085, 0],
            [56.44851793985916, 26.33796525273127, 0],
            [56.46740802474843, 26.32910079055855, 0],
            [56.48727114924053, 26.32980963740634, 0],
            [56.4905019741439, 26.35888738675679, 0],
            [56.51223693689782, 26.36686652048625, 0],
            [56.52324815444526, 26.38512299736579, 0],
            [56.53940850643522, 26.38394674137548, 0],
            [56.53997332731302, 26.36963503786109, 0],
            [56.52478585480329, 26.35481092231417, 0],
            [56.51510845736173, 26.3472897832362, 0],
            [56.51956917133039, 26.33673415480059, 0],
            [56.52076114131761, 26.3099504113639, 0],
            [56.49268029776839, 26.3106949842204, 0],
            [56.45441477130138, 26.30921404216236, 0],
            [56.43325751277081, 26.2977541464102, 0],
            [56.41991364071198, 26.28296828218373, 0],
            [56.40594893894094, 26.28243475918826, 0],
            [56.41315425128975, 26.25589295833992, 0],
            [56.43792671675995, 26.23894342218929, 0],
            [56.45963324810468, 26.2451092918702, 0],
            [56.48360020085622, 26.24599977131286, 0],
            [56.49230500637496, 26.23022908565401, 0],
            [56.48498481234495, 26.2156667528117, 0],
            [56.49772657294277, 26.19824246590069, 0],
            [56.48840490071166, 26.1835975933447, 0],
            [56.48603480593393, 26.14777082740619, 0],
            [56.47467759968978, 26.12228699863435, 0],
            [56.38624050333904, 26.13239632318668, 0],
            [56.42181266853277, 26.09932490306785, 0],
            [56.43417279013818, 26.09277257308224, 0],
            [56.45323340368485, 26.09474951652853, 0],
            [56.46482696271683, 26.08944326302906, 0],
            [56.48664581615996, 26.0905793692518, 0],
            [56.44747801866428, 26.06599732951797, 0],
            [56.41214555962611, 26.03216333799953, 0],
            [56.42710915794579, 25.99957121427949, 0],
            [56.44268245040814, 25.96204918878994, 0],
            [56.45161296702993, 25.94089249513689, 0],
            [56.42530286957774, 25.90782237220308, 0],
            [56.38796769796366, 25.84967900950841, 0],
            [56.33855114763535, 25.77141532081621, 0],
            [56.32021147791991, 25.73397046938491, 0],
            [56.28623735519068, 25.69518011407636, 0],
            [56.27196059570164, 25.6212319702732, 0],
            [56.34462090201912, 25.59691100326552, 0],
            [56.36457370192493, 25.54327190208582, 0],
            [56.36452735794636, 25.36987159473713, 0],
            [56.35944942380053, 25.3499859160363, 0],
            [56.38150528054526, 25.34368011685198, 0],
            [56.36109596232719, 25.14294052167554, 0],
            [56.37809108846695, 24.98825100375176, 0],
            [56.42640366076525, 24.83820862166955, 0],
            [56.5329633537988, 24.62028024679969, 0],
            [56.70295621699721, 24.40477612272533, 0],
            [56.87280939035268, 24.18584794450073, 0],
            [57.12902807781767, 23.9498586470908, 0],
            [57.45263948339036, 23.84541793348654, 0],
            [57.75859577294129, 23.76970581938921, 0],
            [57.79199779199525, 23.78443920347156, 0],
            [57.79370356020272, 23.76548083728564, 0],
            [57.89448377904808, 23.70819248254282, 0],
            [57.96674641331019, 23.70483601400299, 0],
            [58.08879517619802, 23.71282504223925, 0],
            [58.18748376193569, 23.6839845484078, 0],
            [58.26959904107321, 23.62464172257049, 0],
            [58.33766936307345, 23.60537013391373, 0],
            [58.43651967662183, 23.60532430660986, 0],
            [58.50847563863464, 23.64217741287056, 0],
            [58.57114168289535, 23.63300132118274, 0],
            [58.59303446365072, 23.61746010840663, 0],
            [58.60971817108008, 23.5767870730631, 0],
            [58.64779166042351, 23.53662590679438, 0],
            [58.73586888239127, 23.53348580071968, 0],
            [58.77137540448722, 23.47051467449231, 0],
            [58.79566112295235, 23.40388349880887, 0],
            [58.90470893939136, 23.31842525089616, 0],
            [58.93252797098675, 23.25189877989974, 0],
            [58.99749932661257, 23.19816280825006, 0],
            [58.99199488547184, 23.16358858753208, 0],
            [59.02803825038675, 23.09557389095062, 0],
            [59.06878836201998, 23.04930118061739, 0],
            [59.09157287376446, 23.01761294660895, 0],
            [59.19391270071277, 22.9575480385299, 0],
            [59.22429388158145, 22.88279538296257, 0],
            [59.26584961744523, 22.80639155397092, 0],
            [59.29072284353156, 22.75824466245073, 0],
            [59.32015714707374, 22.75454734986614, 0],
            [59.33621416755764, 22.74993232576739, 0],
            [59.34598181021997, 22.72570802406342, 0],
            [59.36237770949868, 22.70625779248907, 0],
            [59.38048631955359, 22.70523101488843, 0],
          ],
        ],
      },
      properties: {
        name: "Persian Gulf & Gulf of Oman 342,781 Sq Km",
        tessellate: true,
      },
    },
  ],
};
