import { useEffect, useContext, useRef, useState } from "react";
import { ShipSvgType, ShipSvgs } from "../../../utils/ShipSvgs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setMinimizePathToolModal } from "../MainMap.store";
import {
  setAnalyzeShape,
  setIsOpenPolygonShipAnalysisModal,
  setPolygonDataForSlider,
  setPolygonData,
} from "../PolygonShipAnalysis/PolygonShipAnalysis.store";
import { GET_SHIP_TOOLS_COMPONENT_ICON } from "../../../utils/Constants";
import { Box, Divider } from "@mui/material";
import {
  Add,
  FileUploadOutlined,
  HighlightOff,
  NavigateNextOutlined,
  Remove,
} from "@mui/icons-material";
import { DateRange, MapContext } from "../MainMap";
import styles from "./RightNavigationBar.module.scss";
import { RightSideNavigationSelectedFilter } from "../MainMap";
import ToolTipComponent from "../../../components/ToolTipComponent/ToolTipComponent";
import useGlobalFilters from "../../../context/useGlobalFilter";

interface RightNavigationBarProps {
  setSelectedShape: (val: ShipSvgType | null) => void;
  setshowDropPinDialog: React.Dispatch<React.SetStateAction<boolean>>;
  mapRef: any;
  setMapViewState: any;
  setDateRange: (val: DateRange) => void;
}

const RightNavigationBar = ({
  setSelectedShape,
  setshowDropPinDialog,
  setMapViewState,
  mapRef,
  setDateRange,
}: RightNavigationBarProps) => {
  const { drawRef, features, setFeatures, setShowSimilarShips, setShowShips } =
    useContext(MapContext);
  const dispatch = useDispatch();
  const { currentSelectedRightNavBar, onCurrentSelectedRightNavBarChange } =
    useGlobalFilters();
  const { minimizePathToolModal } = useSelector(
    (state: RootState) => state.mainMap,
  );
  const { isOpenPolygonShipAnalysisModal } = useSelector(
    (state: RootState) => state.polygonShipAnalysis,
  );

  const [showShapeSlider, setShowShapeSlider] = useState<boolean>(false);
  const [showMeasureSlider, setShowMeasureSlider] = useState<boolean>(false);
  const countShape = useRef(0);
  const inputFile = useRef(null) as any;

  useEffect(() => {
    if (!isOpenPolygonShipAnalysisModal) countShape.current = 0;
  }, [isOpenPolygonShipAnalysisModal]);
  useEffect(() => {
    let analyzeShapeArr: any = [];
    const featureArray = features && Object.keys(features.data);
    if (featureArray && featureArray.length) {
      featureArray.forEach((featureId: string) => {
        const drawnObject = featureId && features.data[featureId];
        if (drawnObject) {
          if (drawnObject?.renderDrawMode === "draw_line_string") return;
          if (drawnObject.count) {
            analyzeShapeArr = [
              ...analyzeShapeArr,
              {
                value: drawnObject.id,
                label: `Shape ${drawnObject.count}`,
              },
            ];
          }
        }
      });
      if (analyzeShapeArr.length > 0) {
        dispatch(setAnalyzeShape(analyzeShapeArr));
        dispatch(setIsOpenPolygonShipAnalysisModal(true));
      }
    }
  }, [features && Object.keys(features.data).length]);

  const uploadGeojson = (e: any) => {
    e.preventDefault();
    const fileReader = new FileReader();
    const name = e.target.files[0].name;

    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e: any) => {
      const resData = JSON.parse(e.target.result);

      const feature = resData.features[0];
      const randomId = uuidv4();
      const featureWithName = {
        ...feature,
        id: randomId,
        properties: {
          ...feature.properties,
          name,
          renderDrawMode: "draw_polygon",
        },
        count: features.count + 1,
      };

      setFeatures((currFeatures: any) => {
        const newFeatures: any = { ...currFeatures?.data };

        // drawRef.current
        //   .getAll()
        //   .features.data.forEach((f: any) => (newFeatures[f.id] = { ...f }));
        newFeatures[randomId] = featureWithName;
        drawRef.current.set({
          type: "FeatureCollection",
          features: Object.keys(newFeatures).map((key) => ({
            ...newFeatures[key],
          })),
        });
        return { count: currFeatures.count + 1, data: newFeatures };
      });

      inputFile.current.value = "";
    };
  };

  const handleDeleteClick = () => {
    if (!drawRef?.current) return;
    drawRef.current.trash();
    dispatch(setPolygonDataForSlider([]));
    dispatch(setPolygonData([]));
    dispatch(setIsOpenPolygonShipAnalysisModal(false));
    dispatch(setAnalyzeShape([]));
    setDateRange({
      startDate: new Date(new Date().getTime() - 60 * 60 * 1000),
      endDate: new Date(),
    });
    setSelectedShape(null);
    setShowShips(true);
    setShowSimilarShips(true);
  };

  const handleShipFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (
      currentSelectedRightNavBar === RightSideNavigationSelectedFilter.Ships
    ) {
      onCurrentSelectedRightNavBarChange();
    } else {
      onCurrentSelectedRightNavBarChange(
        RightSideNavigationSelectedFilter.Ships,
      );
    }
  };

  const handleLayerFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (
      currentSelectedRightNavBar === RightSideNavigationSelectedFilter.Layers
    ) {
      onCurrentSelectedRightNavBarChange();
    } else {
      onCurrentSelectedRightNavBarChange(
        RightSideNavigationSelectedFilter.Layers,
      );
    }
  };

  return (
    <>
      <input
        type="file"
        id="imgupload"
        style={{ display: "none" }}
        ref={inputFile}
        onChange={uploadGeojson}
      />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-end"
        className={styles.rightNavigationBar}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          className={styles.mapNavigationoptions}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={styles.mapNavigationIcon}
            onClick={() => handleDeleteClick()}
          >
            <HighlightOff fontSize="small" />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={styles.mapNavigationIcon}
            onClick={() => {
              setshowDropPinDialog(true);
            }}
          >
            {ShipSvgs({
              type: "pin",
            })}
          </Box>

          <Box
            display="flex"
            alignItems="center"
            className={styles.mapNavigationIconWrap}
          >
            {!showShapeSlider && (
              <Box
                id="triangle-main"
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={styles.mapNavigationIcon}
                onMouseEnter={() => {
                  setShowShapeSlider(true);
                  setSelectedShape(null);
                }}
              >
                {ShipSvgs({
                  type: "triangle",
                })}
              </Box>
            )}
            {showShapeSlider && (
              <Box
                display="flex"
                onMouseLeave={() => setShowShapeSlider(false)}
              >
                <ToolTipComponent title="Draw a Polygon">
                  <Box
                    id="triangle"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={`${styles.mapNavigationIcon}`}
                    onClick={async () => {
                      setSelectedShape("triangle");
                      (await drawRef.current) &&
                        drawRef.current.changeMode("draw_polygon");
                      setShowShapeSlider(false);
                    }}
                  >
                    {ShipSvgs({
                      type: "triangle",
                    })}
                  </Box>
                </ToolTipComponent>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  className={styles.navigationDivider}
                />
                <ToolTipComponent title="Draw a rectangle">
                  <Box
                    id="rectangle"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={`${styles.mapNavigationIcon}`}
                    onClick={() => {
                      setSelectedShape("rectangle");
                      drawRef.current &&
                        drawRef.current.changeMode("draw_rectangle");
                      // setShowTemporaryCircle(true);
                      // setShowShapeSlider(false);
                      // countShape.current++;
                      // dispatch(
                      //   setAnalyzeShape([
                      //     ...analyzeShape,
                      //     {
                      //       value: `Shape ${countShape.current}`,
                      //       label: `Shape ${countShape.current}`,
                      //     },
                      //   ])
                      // );
                    }}
                  >
                    {ShipSvgs({
                      type: "rectangle",
                    })}
                  </Box>
                </ToolTipComponent>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  className={styles.navigationDivider}
                />
                <ToolTipComponent title="Upload JSON">
                  <Box
                    id="file-upload"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.mapNavigationIcon}
                    onClick={() => inputFile.current.click()}
                  >
                    <FileUploadOutlined fontSize="small" />
                  </Box>
                </ToolTipComponent>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  className={styles.navigationDivider}
                />
                <ToolTipComponent title="Delete layers">
                  <Box
                    id="delete-bin"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.mapNavigationIcon}
                    onClick={() => {
                      setSelectedShape(null);
                    }}
                    sx={{
                      pointerEvents: "none",
                      opacity: 0.5,
                    }}
                  >
                    {ShipSvgs({
                      type: "deleteSvg",
                    })}
                  </Box>
                </ToolTipComponent>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  className={styles.navigationDivider}
                />
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={styles.mapNavigationIcon}
                >
                  <NavigateNextOutlined fontSize="small" />
                </Box>
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={styles.mapNavigationIconWrap}
          >
            {!showMeasureSlider && (
              <Box
                id="measureLine-main"
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={styles.mapNavigationIcon}
                onMouseEnter={() => {
                  setShowMeasureSlider(true);
                }}
              >
                {ShipSvgs({
                  type: "measureLine",
                })}
              </Box>
            )}
            {showMeasureSlider && (
              <Box
                display="flex"
                onMouseLeave={() => setShowMeasureSlider(false)}
              >
                <ToolTipComponent title="Draw a polyline">
                  <Box
                    id="measureLine"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={`${styles.mapNavigationIcon}`}
                    onClick={() => {
                      drawRef.current &&
                        drawRef.current.changeMode("draw_line_string");
                    }}
                  >
                    {ShipSvgs({
                      type: "measureLine",
                    })}
                  </Box>
                </ToolTipComponent>

                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  className={styles.navigationDivider}
                />

                <ToolTipComponent title="Draw a circle">
                  <Box
                    id="measureCircle"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={`${styles.mapNavigationIcon}`}
                    onClick={() => {
                      drawRef.current &&
                        drawRef.current.changeMode("draw_circle");
                    }}
                  >
                    {ShipSvgs({
                      type: "measureCircle",
                    })}
                  </Box>
                </ToolTipComponent>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  className={styles.navigationDivider}
                />
                <ToolTipComponent title="Change units[meters]">
                  <Box
                    id="selectUnit"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={`${styles.mapNavigationIcon}`}
                  >
                    {ShipSvgs({
                      type: "selectUnit",
                    })}
                  </Box>
                </ToolTipComponent>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  className={styles.navigationDivider}
                />
                <ToolTipComponent title="Delete layers">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.mapNavigationIcon}
                    sx={{
                      pointerEvents: "none",
                      opacity: 0.5,
                    }}
                  >
                    {ShipSvgs({
                      type: "deleteSvg",
                    })}
                  </Box>
                </ToolTipComponent>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  className={styles.navigationDivider}
                />
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={styles.mapNavigationIcon}
                >
                  <NavigateNextOutlined fontSize="small" />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          className={styles.mapNavigationoptions}
        >
          {Object.keys(minimizePathToolModal).map(
            (key: any) =>
              minimizePathToolModal[key] && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={styles.mapNavigationIcon}
                  onClick={() => {
                    dispatch(
                      setMinimizePathToolModal({
                        ...minimizePathToolModal,
                        [key]: false,
                      }),
                    );
                  }}
                >
                  {GET_SHIP_TOOLS_COMPONENT_ICON[key]}
                </Box>
              ),
          )}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={styles.mapNavigationIcon}
            sx={{
              pointerEvents: "none",
              opacity: 0.5,
            }}
          >
            {ShipSvgs({
              type: "search",
            })}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={styles.mapNavigationIconWrap}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={styles.mapNavigationIcon}
              onClick={() => {
                mapRef && mapRef?.current.zoomIn && mapRef.current.zoomIn();
                mapRef &&
                  mapRef?.current.deck &&
                  setMapViewState({
                    latitude: mapRef?.current.deck.viewState.zoom.latitude,
                    longitude: mapRef?.current.deck.viewState.zoom.longitude,
                    zoom: mapRef?.current.deck.viewState.zoom + 1,
                  });
              }}
            >
              <Add fontSize="small" />
            </Box>
            <Divider
              variant="middle"
              flexItem
              className={styles.navigationDivider}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={styles.mapNavigationIcon}
              onClick={() => {
                mapRef && mapRef?.current.zoomOut && mapRef.current.zoomOut();
                mapRef &&
                  mapRef?.current.deck &&
                  setMapViewState({
                    latitude: mapRef?.current.deck.viewState.zoom.latitude,
                    longitude: mapRef?.current.deck.viewState.zoom.longitude,
                    zoom: mapRef?.current.deck.viewState.zoom + 1,
                  });
              }}
            >
              <Remove fontSize="small" />
            </Box>
          </Box>

          <Box
            onClick={handleShipFilterClick}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={styles.mapNavigationIcon}
          >
            {ShipSvgs({
              type: "shipSVG",
            })}
          </Box>
          <Box
            onClick={handleLayerFilterClick}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={styles.mapNavigationIcon}
          >
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className={styles.navigationDivider}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={styles.mapNavigationIcon}
            >
              {ShipSvgs({
                type: "layers",
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RightNavigationBar;

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16),
  );
}
