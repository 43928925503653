import { FormEvent, useEffect, useState } from "react";
import useFireBaseAuth from "../../context/useFireBaseAuth";

const usePasswordModal = () => {
  const { changeUserPassword, handleClosePasswordModal } = useFireBaseAuth();
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfimPassword] = useState<string>("");
  const [passwordError, setErrorMessage] = useState<string | null>(null);
  const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false);

  useEffect(() => {
    const validateMatachingPasswords = (): boolean => {
      if (
        confirmPassword.length > newPassword.length ||
        (newPassword.length === confirmPassword.length &&
          newPassword !== confirmPassword)
      ) {
        setErrorMessage("Passwords do not match");
        return false;
      } else {
        setErrorMessage(null);
      }
      return true;
    };
    const validateSeparatePasswords = (): void => {
      if (
        currentPassword.length &&
        currentPassword === newPassword &&
        currentPassword === confirmPassword
      ) {
        setErrorMessage("Current Password and New Password are the same");
      }
    };
    const passwordsAreValid = validateMatachingPasswords();
    if (passwordsAreValid) {
      validateSeparatePasswords();
    }
  }, [newPassword, confirmPassword]);

  const hanldeSetConfirmPassword = (input: string) => {
    setConfimPassword(input);
  };

  const clearForm = (): void => {
    setCurrentPassword("");
    setNewPassword("");
    setConfimPassword("");
  };

  const closeChangePasswordModal = (): void => {
    clearForm();
    handleClosePasswordModal();
  };

  const handleChangePassword = async (
    event: FormEvent<HTMLFormElement>,
  ): Promise<boolean> => {
    event.preventDefault();
    await changeUserPassword(currentPassword, newPassword);
    closeChangePasswordModal();
    return true;
  };

  return {
    handleChangePassword,
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
    passwordError,
    confirmPassword,
    passwordIsValid,
    hanldeSetConfirmPassword,
    setPasswordIsValid,
    closeChangePasswordModal,
  };
};

export default usePasswordModal;
