import { useState, useEffect, useRef } from "react";
import { api } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { User } from "firebase/auth";
import { useSnackbar } from "../../context/SnackbarContext";

const useEmailMfa = (user: User | null, isFlipped: boolean) => {
  const [mfaCode, setMfaCode] = useState(Array(6).fill(""));

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  // State to record if email code was send
  const [emailAuth, setEmailAuth] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);

  // navigation function
  const navigate = useNavigate();

  // snackbar context
  const { setNewSnackBar } = useSnackbar();

  useEffect(() => {
    if (mfaCode.every((element) => element === "") && error) {
      setError(false);
    }
  }, [mfaCode, error]);

  // Function to send email code
  const sendEmailCode = async (): Promise<void> => {
    // Send email code to user
    const response = await api.post("/mfa/send-email-code", {
      email: user?.email,
      uid: user?.uid,
    });

    // Set snackbar message and open snackbar
    setNewSnackBar({ message: response.data, severity: "success" });
    setEmailAuth(true);
    // Set resend email time to 60 seconds
  };

  const handleChangeCode = (e: any, index: number) => {
    setError(false);
    const value = e.target.value;
    if (/^\d{0,1}$/.test(value)) {
      const updatedCode = [...mfaCode];
      updatedCode[index] = value;
      setMfaCode(updatedCode);

      if (value !== "" && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyPress = (e: any, index: number) => {
    if (e.key === "Backspace" && index > 0 && mfaCode[index] === "") {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const verifyEmailOtp = async (): Promise<void> => {
    // Check if user exists
    if (user) {
      try {
        // Verify email code
        const response = await api.post("/mfa/verify-email-code", {
          uid: user?.uid,
          token: mfaCode.join(""),
          email: user?.email,
        });

        // If email code is valid, set isMFAVerified to true and navigate to theia
        if (response.data.valid) {
          localStorage.setItem("isMFAVerified", "true");
          navigate("/theia");
        }
        console.log(
          response.data.valid ? "Login successful!" : "Login failed."
        );
      } catch (error) {
        setError(true);
        console.error("Error logging in:", error);
      }
    }
  };

  useEffect(() => {
    if (!isFlipped) {
      setTimeout(() => {
        setEmailAuth(false);
        error && setError(false);
        setMfaCode(Array(6).fill(""));
      }, 1000);
    }
  }, [isFlipped]);

  // Return necessary functions and states
  return {
    emailAuth,
    sendEmailCode,
    verifyEmailOtp,
    error,
    mfaCode,
    setMfaCode,
    handleChangeCode,
    handleKeyPress,
    inputRefs,
  };
};

export default useEmailMfa;
