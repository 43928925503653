import { Button } from "@mui/material";
import styled from "styled-components";

interface ButtonProps {
  label: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  variantType: "contained" | "outlined";
  disabled?: boolean;
  width?: string;
  style?: React.CSSProperties;
}

interface ButtonStyleProps {
  width?: string;
  style?: React.CSSProperties;
}

const StyledTextButton = styled(Button)<ButtonStyleProps>(
  ({ width, style }) => ({
    background: "#0094FF ",
    height: "32px",
    color: "#FFF",
    width: width || "120px",
    textTransform: "none",
    "&.Mui-disabled": {
      color: "#FFF",
      background: "#7d7d84",
    },
    "&:hover": {
      backgroundColor: "#26A4FF",
    },
    ...(style && style),
  }),
);

const StyledOutlineButton = styled(Button)<ButtonStyleProps>(
  ({ width, style }) => ({
    background: "transparent",
    height: "32px",
    color: "#FFF",
    width: width || "120px",
    border: "1px solid #FFF",
    textTransform: "none",
    "&.Mui-disabled": {
      background: "#7d7d84",
      color: "#FFF",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      borderColor: "#fff",
    },
    ...(style && style),
  }),
);

const ActionButton: React.FC<ButtonProps> = ({
  label,
  onClick,
  disabled,
  width,
  variantType,
  type,
  style,
}) => {
  return (
    <>
      {variantType === "contained" ? (
        <StyledTextButton
          variant="contained"
          onClick={onClick}
          disabled={disabled}
          width={width}
          type={type}
          style={style}
        >
          {label}
        </StyledTextButton>
      ) : (
        <StyledOutlineButton
          variant="outlined"
          onClick={onClick}
          disabled={disabled}
          width={width}
          style={style}
        >
          {label}
        </StyledOutlineButton>
      )}
    </>
  );
};

export default ActionButton;
