import { useEffect, useRef, useState } from "react";
import { calculateTimeSince } from "../../../../utils/utils";
import useCurrentMapState from "../../../../context/useCurrentMapState";
import useGlobalFilters from "../../../../context/useGlobalFilter";
import { DateTime } from "luxon";
import { OSInteligenceItem } from "../../SidebarComponents/OSWebcams/model";
import {
  WebcamHistory,
  getWebcamFullImage,
  getWebcamImageHistory,
} from "./model";
import useOutsideClick from "../../../../utils/useOutsideClick";

export const useWebcamModal = (selectedWebcam: OSInteligenceItem) => {
  const { selectedWebcamUrl, handleCloseModal } = useCurrentMapState();
  const { date } = useGlobalFilters();
  const { mapRef } = useCurrentMapState();

  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageIsLoading, setImageIsLoading] = useState<boolean>(true);
  const [webcamHistooryIsLoading, setWebcamHistoryIsLoading] =
    useState<boolean>(true);
  const [webcamHistoory, setWebcamHistory] = useState<WebcamHistory[]>([]);

  const [currentSelectedImage, setCurrentSelectedImage] = useState<{
    selectedTimestamp: string;
    timesSince: string;
    selectedThumbnailImage: string;
  }>({
    selectedTimestamp: selectedWebcam.timestamp,
    timesSince: calculateTimeSince(selectedWebcam.timestamp),
    selectedThumbnailImage: "",
  });

  const [page, setPage] = useState(0);

  const modalRef = useRef<HTMLDivElement>(null);

  const getModalPosition = (yPosition: number): number => {
    let modalPostionOffset = 0;

    const mapHeight = mapRef.current.getMap().getContainer().clientHeight;
    const distanceFromBottom = mapHeight - yPosition;
    // calculate modal postion with height of modal and height of header
    const modalPosition = 570 - distanceFromBottom;
    if (modalPosition > 0) {
      modalPostionOffset = modalPosition;
    }
    return yPosition - modalPostionOffset;
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if ((page + 1) * 5 < webcamHistoory.length) {
      setPage(page + 1);
    }
  };

  const handlFetchFullSizeImage = async (webCamUrl: string | null) => {
    setImageIsLoading(true);
    if (webCamUrl !== null) {
      const imageData = await getWebcamFullImage({
        url: webCamUrl,
      });
      const blobURL = URL.createObjectURL(imageData);
      setImageUrl(blobURL);
    }
    setImageIsLoading(false);
  };

  useOutsideClick(modalRef, handleCloseModal);

  useEffect(() => {
    const handleFetchWebcamHistory = async () => {
      setWebcamHistoryIsLoading(true);
      if (date !== null) {
        const imageData = await getWebcamImageHistory({
          camId: selectedWebcam.id,
          startDate: date?.format("YYYY-MM-DD"),
          endDate: date?.add(1, "day").format("YYYY-MM-DD"),
        });
        setWebcamHistory(imageData);
      }
      setWebcamHistoryIsLoading(false);
    };

    handleFetchWebcamHistory();
    handlFetchFullSizeImage(selectedWebcamUrl);
  }, [selectedWebcam, date]);

  const formatDate = () => {
    return DateTime.fromISO(currentSelectedImage.selectedTimestamp, {
      zone: "utc",
    }).toFormat("MMM dd, yyyy 'at' HHmm 'UTC'");
  };

  const currentThumbnails = [...webcamHistoory]
    .reverse()
    .slice(page * 5, (page + 1) * 5);

  const handleThumbnailImageSelect = (thumbnail: WebcamHistory) => {
    handlFetchFullSizeImage(thumbnail.url);
    setCurrentSelectedImage({
      selectedTimestamp: thumbnail.updatedAtUtc,
      selectedThumbnailImage: thumbnail.docId,
      timesSince: calculateTimeSince(thumbnail.pulledAtUtc),
    });
  };

  return {
    modalRef,
    currentSelectedImage,
    webcamModalIsLoading: imageIsLoading && webcamHistooryIsLoading,
    imageIsLoading,
    imageUrl,
    screenShotDate: formatDate(),
    page,
    handlePreviousPage,
    handleNextPage,
    currentThumbnails,
    handleThumbnailImageSelect,
    webcamHistoory,
    getModalPosition: getModalPosition,
  };
};
