import { useEffect, useState } from "react";
import DeckGL from "@deck.gl/react/typed";
import {
  COORDINATE_SYSTEM,
  _GlobeView as GlobeView,
} from "@deck.gl/core/typed";
import { useDispatch } from "react-redux";
import { SimpleMeshLayer } from "@deck.gl/mesh-layers/typed";
import { GeoJsonLayer } from "@deck.gl/layers/typed";
import { SphereGeometry } from "@luma.gl/engine";
import MapLayerPopover from "./MapLayerPopover";
import moment from "moment";
import MapTooltip from "../../../components/MapTooltip/MapTooltip";
import { EventHoverData, PathHoverData } from "./types";
import useCopyCoordinates from "./hooks/useCopyCoordinates";
import useGlobalFilters from "../../../context/useGlobalFilter";

const DeckglRender = ({
  setZoomLevel,
  zoomLevel,
  setMapLatLng,
  mapViewState,
  selectedTabId,
  setChangeSaved,
  deckGlLayers,
  mapRef,
  setMapSnapshot,
  mapLatLng,
  shipPaths,
  mapStyle,
}: any) => {
  const dispatch = useDispatch();
  const { showCursor, setShowCursor } = useGlobalFilters();
  const EARTH_RADIUS_METERS = 6.3e6;
  const backgroundLayers = [
    new SimpleMeshLayer({
      id: "earth-sphere",
      data: [0],
      mesh: new SphereGeometry({
        radius: EARTH_RADIUS_METERS,
        nlat: 18,
        nlong: 36,
      }),
      coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      getPosition: [0, 0, 0],
      getColor: mapStyle.includes("dark") ? [38, 38, 38] : [255, 255, 255],
    }),
    new GeoJsonLayer({
      id: "earth-land",
      data: "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_land.geojson",
      stroked: false,
      filled: true,
      opacity: 0.1,
      getFillColor: mapStyle.includes("dark") ? [100, 100, 100] : [30, 80, 120],
    }),
  ];

  const [clickedCoordinates, setClickedCoordinates] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });
  const [currentUserHoverCoordinates, setCurrentUserHoverCoordinates] =
    useState<[number, number] | null>(null);
  const [selectedMapObject, setSelectedMapObject] = useState<any | null>(null);
  const [pathHoverData, setPathHoverData] = useState<PathHoverData | null>(
    null,
  );

  const [eventHoverData, setEventHoverData] = useState<EventHoverData | null>(
    null,
  );

  const handleMapClick = (event: any): void => {
    const pathAnalysis = shipPaths?.[event?.object?.synmax_ship_id]?.analysis;
    if (event?.object && pathAnalysis) {
      const { timestamp, longitude, latitude } = event.object;
      setPathHoverData(() => ({
        x: event.x,
        y: event.y,
        longitude,
        latitude,
        timestamp: `${moment
          .utc(timestamp * 1000)
          .format("DD/MM/YYYY HH:mm:ss")} UTC`,
      }));
    } else {
      setPathHoverData(null);
    }
    if (event.object && event.object?.iconString) {
      const { position, iconString } = event.object;
      if (position?.length !== 2) return;
      setEventHoverData({
        x: event.x,
        y: event.y,
        longitude: position[1],
        latitude: position[0],
        iconString: iconString,
      });
    } else {
      setEventHoverData(null);
    }

    if (event.object !== undefined) {
      setSelectedMapObject(event.object);
    } else {
      setSelectedMapObject(null);
    }
    setClickedCoordinates({ x: event.x, y: event.y });
  };

  useEffect(() => {
    setSelectedMapObject(null);
    setClickedCoordinates({
      x: 0,
      y: 0,
    });
  }, [zoomLevel, mapLatLng]);

  useCopyCoordinates(currentUserHoverCoordinates);

  return (
    <>
      <DeckGL
        ref={mapRef}
        onAfterRender={() => {
          const base64Image =
            //@ts-ignore
            mapRef?.current?.deck?.canvas?.toDataURL("image/png");
          setMapSnapshot(base64Image);
        }}
        views={new GlobeView({})}
        initialViewState={mapViewState}
        controller={true}
        layers={[backgroundLayers, deckGlLayers]}
        onViewStateChange={(e: any) => {
          setMapLatLng({
            lat: e.viewState.latitude,
            lng: e.viewState.longitude,
          });
          setZoomLevel(e.viewState.zoom);
          dispatch(setChangeSaved(false));
          setPathHoverData(null);
          setEventHoverData(null);
        }}
        onClick={handleMapClick}
        key={selectedTabId}
        onHover={(object) => {
          if (
            object.coordinate &&
            Array.isArray(object.coordinate) &&
            object.coordinate.length === 2
          ) {
            setCurrentUserHoverCoordinates([
              object.coordinate[0],
              object.coordinate[1],
            ] as [number, number]);
          } else {
            setCurrentUserHoverCoordinates(null);
          }
          if (object.layer) {
            const layerId = object.layer.id;
            if (
              layerId === "selectedShips" ||
              layerId === "light" ||
              layerId === "unattributed" ||
              layerId === "attributed" ||
              layerId === "spoofingEvents" ||
              layerId === "opticalSTS" ||
              layerId === "AISSTS" ||
              layerId === "sanctionedShips"
            ) {
              setShowCursor(true);
            }
          } else {
            setShowCursor(false);
          }
        }}
        getCursor={() => (showCursor ? "pointer" : "grab")}
        onDrag={() => {
          setPathHoverData(null);
          setEventHoverData(null);
        }}
      >
        <MapTooltip
          pathAnalysisData={pathHoverData}
          eventData={eventHoverData}
        />
      </DeckGL>
      <MapLayerPopover
        selectedMapObject={selectedMapObject}
        clickedCoordinates={clickedCoordinates}
      />
    </>
  );
};

export default DeckglRender;
