import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Map from "./MainMap";
import WorkStations from "./WorkStations";
import Recent from "../Recent/Recent";
import Shared from "../Shared/Shared";
import BannerModal from "./BannerModal/BannerModal";
import MapProviderLayers from "./MapProviderLayers/MapProviderLayers";
import ChangePasswordModal from "../../components/ChangePasswordModal/ChangePasswordModal";
import UserInactiveModal from "../../components/UserInactiveModal/UserInactiveModal";

const Theia = () => {
  const { isDashboard } = useSelector((state: RootState) => state.header);
  const { isRecent } = useSelector((state: RootState) => state.homePanel);
  const { isShared } = useSelector((state: RootState) => state.shared);

  return (
    <>
      <BannerModal />
      <ChangePasswordModal />
      <UserInactiveModal />
      {isDashboard && !isRecent && !isShared ? (
        <WorkStations />
      ) : isRecent && isDashboard && !isShared ? (
        <Recent />
      ) : isShared && !isRecent && isDashboard ? (
        <Shared />
      ) : (
        <MapProviderLayers>
          <Map />
        </MapProviderLayers>
      )}
    </>
  );
};

export default Theia;
