import { useState } from "react";
import SelectInput from "../../SelectInput/SelectInput";
import DateSlider from "../../DateSlider/DateSlider";
import { CUSTOM_SLIDER_MENU_ITEMS } from "../../../utils/Constants";
import { Box } from "@mui/material";
import { PauseOutlined, PlayArrowOutlined } from "@mui/icons-material";
import styles from "./CustomSlider.module.scss";

interface CustomSliderProps {
  sliderTitle: string;
  showPlaybackSpeed?: boolean;
  handleChange?: any;
  playbackSpeed?: string;
  showPlayIcon?: boolean;
  setShowPlayIcon?: ((val: boolean) => void) | undefined;
  maxValue?: number | number[];
  minValue?: number | number[];
  step?: number;
  label?: any;
  updateSlider?: any;
  handleSliderChange?: (event: any, newValue: number) => void;
  handlePlayPause?: (showPlayIcon: boolean | undefined) => void;
  sliderValue?: number;
  isFuturePath?: boolean;
}

const CustomSlider = ({
  sliderTitle,
  showPlaybackSpeed = false,
  handleChange,
  playbackSpeed,
  showPlayIcon,
  setShowPlayIcon,
  maxValue,
  minValue,
  step,
  label,
  updateSlider,
  handleSliderChange,
  handlePlayPause,
  sliderValue,
  isFuturePath,
}: CustomSliderProps) => {
  const [sliderValueFuture, setSliderValueFuture] = useState(minValue);

  const handleSlider = (event: Event, newValue: number | number[]) => {
    setSliderValueFuture(newValue);
    updateSlider && updateSlider(newValue);
  };

  const handleLabelFormat = () => {
    if (isFuturePath) {
      return label(sliderValueFuture);
    } else {
      return label;
    }
  };
  return (
    <Box className={styles.customSliderMain}>
      <h5 className={styles.customSliderHead}>{sliderTitle}</h5>
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        className={styles.customSliderWrapper}
      >
        {showPlaybackSpeed && (
          <Box
            display="flex"
            alignItems="stretch"
            justifyContent="center"
            className={styles.customSliderDetail}
          >
            <SelectInput
              id="pathToolsModal"
              name="pathToolsModal"
              value={`${playbackSpeed}`}
              onChange={(e) => handleChange(e.target.value)}
              optionsArray={CUSTOM_SLIDER_MENU_ITEMS}
              className={styles.editMainSelect}
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={styles.playPauseButton}
            >
              {showPlayIcon ? (
                <PlayArrowOutlined
                  onClick={() =>
                    handlePlayPause && handlePlayPause(showPlayIcon)
                  }
                />
              ) : (
                <PauseOutlined
                  fontSize="small"
                  onClick={() =>
                    handlePlayPause && handlePlayPause(showPlayIcon)
                  }
                />
              )}
            </Box>
          </Box>
        )}
        {isFuturePath ? (
          <DateSlider
            value={sliderValueFuture}
            minDate={minValue}
            maxDate={maxValue}
            step={step}
            onChange={handleSlider}
            valueLabelFormat={handleLabelFormat}
          />
        ) : (
          <DateSlider
            value={sliderValue}
            minDate={minValue}
            maxDate={maxValue}
            step={step}
            onChange={handleSliderChange}
            valueLabelFormat={handleLabelFormat}
          />
        )}
      </Box>
    </Box>
  );
};

export default CustomSlider;
