import React from "react";
import {
  EventHoverData,
  PathHoverData,
} from "../../pages/Theia/RenderShips/types";

interface TooltipProps {
  pathAnalysisData?: PathHoverData | null;
  eventData?: EventHoverData | null;
}

const MapTooltip: React.FC<TooltipProps> = ({
  pathAnalysisData,
  eventData,
}) => {
  const position = pathAnalysisData
    ? { left: pathAnalysisData.x + 10, top: pathAnalysisData.y }
    : eventData
      ? { left: eventData.x + 10, top: eventData.y }
      : { left: 0, top: 0 };

  return (
    <>
      {(pathAnalysisData || eventData) && (
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            pointerEvents: "none",
            background: "white",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
            left: position.left,
            top: position.top,
            color: "#000",
            fontSize: "10px",
            fontFamily: "Arial, sans-serif",
            whiteSpace: "nowrap",
            transform: "translateY(-50%)",
          }}
        >
          {pathAnalysisData && (
            <>
              <div style={{ lineHeight: "15px" }}>
                <strong>Longitude:</strong> {pathAnalysisData.longitude}
              </div>
              <div style={{ lineHeight: "15px" }}>
                <strong>Latitude:</strong> {pathAnalysisData.latitude}
              </div>
              <div style={{ lineHeight: "15px" }}>
                <strong>Timestamp:</strong> {pathAnalysisData.timestamp}
              </div>
            </>
          )}
          {eventData && (
            <div
              style={{ lineHeight: "15px", fontSize: "15px", padding: "5px" }}
            >
              {eventData!.iconString}
            </div>
          )}
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "-5px",
              transform: "translateY(-50%)",
              width: 0,
              height: 0,
              borderTop: "5px solid transparent",
              borderBottom: "5px solid transparent",
              borderRight: "5px solid white",
            }}
          />
        </div>
      )}
    </>
  );
};

export default MapTooltip;
