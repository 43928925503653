import { Dialog } from "@mui/material";
import useFireBaseAuth from "../../context/useFireBaseAuth";
import ActionButton from "../ActionButon";
import usePasswordModal from "./usePasswordModal";
import PasswordForm from "../ChangePasswordForm/ChangePasswordForm";

const ChangePasswordModal: React.FC = () => {
  const { changePasswordModalIsOpen } = useFireBaseAuth();
  const {
    handleChangePassword,
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
    passwordError,
    confirmPassword,
    passwordIsValid,
    hanldeSetConfirmPassword,
    setPasswordIsValid,
    closeChangePasswordModal,
  } = usePasswordModal();

  return (
    <Dialog maxWidth="lg" open={changePasswordModalIsOpen}>
      <PasswordForm
        onSubmit={handleChangePassword}
        currentPassword={currentPassword}
        setCurrentPassword={setCurrentPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        confirmPassword={confirmPassword}
        hanldeSetConfirmPassword={hanldeSetConfirmPassword}
        passwordError={passwordError}
        passwordIsValid={passwordIsValid}
        setPasswordIsValid={setPasswordIsValid}
        cancelButton={
          <ActionButton
            label="Cancel"
            variantType="outlined"
            onClick={closeChangePasswordModal}
            width="200px"
          />
        }
      />
    </Dialog>
  );
};

export default ChangePasswordModal;
