export const IFA = {
  type: "FeatureCollection",
  name: "IFA Cable.kmz",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Name: "IFA Cable",
        tessellate: 1,
        extrude: 0,
        visibility: -1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [1.195727246600475, 51.083145462872281, 0.0],
          [1.199777207714987, 51.083361383717367, 0.0],
          [1.204174551826906, 51.082489126837928, 0.0],
          [1.209158557288765, 51.081333040681862, 0.0],
          [1.212535091888944, 51.080560022880469, 0.0],
          [1.217004560826256, 51.080607291569827, 0.0],
          [1.220836867885089, 51.080940930112817, 0.0],
          [1.224812396617523, 51.081330221615431, 0.0],
          [1.226418895500305, 51.081500097555242, 0.0],
          [1.241992561251666, 51.078702783216272, 0.0],
          [1.252751321089931, 51.077249962820247, 0.0],
          [1.264260865905564, 51.07569048782986, 0.0],
          [1.275289530710446, 51.074302805810369, 0.0],
          [1.294632811391632, 51.071800146145613, 0.0],
          [1.319833510286499, 51.071962435457174, 0.0],
          [1.335942992087149, 51.072010144445663, 0.0],
          [1.35443590140411, 51.072089555652433, 0.0],
          [1.368473670154329, 51.072125555996671, 0.0],
          [1.378973399657992, 51.072248874895493, 0.0],
          [1.387575191446726, 51.072556354784354, 0.0],
          [1.395324716442421, 51.072789521068763, 0.0],
          [1.401178460633037, 51.072931383183743, 0.0],
          [1.405602158047017, 51.072968688295788, 0.0],
          [1.41102819023226, 51.073194084427087, 0.0],
          [1.416161057792247, 51.073343810652503, 0.0],
          [1.420588550408439, 51.073202711897238, 0.0],
          [1.428577196804155, 51.07242582151536, 0.0],
          [1.439682238276347, 51.07130412069997, 0.0],
          [1.449925463554431, 51.070227255581997, 0.0],
          [1.457157978332448, 51.069501539212382, 0.0],
          [1.47234669167422, 51.068071962617267, 0.0],
          [1.488868771187846, 51.066422818241868, 0.0],
          [1.50236634010394, 51.065126398386717, 0.0],
          [1.516308818686882, 51.063869236168223, 0.0],
          [1.530585514898737, 51.062546369302638, 0.0],
          [1.53963497512609, 51.061627521416867, 0.0],
          [1.548648904761614, 51.060500398004628, 0.0],
          [1.563140510531538, 51.052982031433039, 0.0],
          [1.581740985555, 51.043615440785914, 0.0],
          [1.597999110689712, 51.035302994618483, 0.0],
          [1.617563108715598, 51.025158052180657, 0.0],
          [1.63507637720576, 51.015839013054922, 0.0],
          [1.659226903439055, 51.003261188682572, 0.0],
          [1.672774212829309, 50.996086279656282, 0.0],
          [1.686488988190014, 50.988723854043457, 0.0],
          [1.698833219538318, 50.982216978273961, 0.0],
          [1.713570079403335, 50.974535562782542, 0.0],
          [1.723776848580241, 50.9685447505246, 0.0],
          [1.734321758405928, 50.962555535894268, 0.0],
          [1.740380400710839, 50.959037201922882, 0.0],
          [1.743310162940779, 50.957339226983187, 0.0],
          [1.747900144322674, 50.952251912285199, 0.0],
          [1.751589406088037, 50.947610299044221, 0.0],
          [1.75379020982215, 50.944800276916467, 0.0],
        ],
      },
    },
  ],
};
