export const seaOfAzov = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.76547519254345, 45.18094213738703, 0],
            [29.85235431081293, 45.18725952637644, 0],
            [29.99273167183236, 45.1912298639301, 0],
            [30.04013216811786, 45.0892325044831, 0],
            [30.97870896184699, 44.7770599306103, 0],
            [31.1749683781504, 44.73739005507774, 0],
            [31.41005182800892, 44.04794680970885, 0],
            [31.3325868207281, 43.45150434002685, 0],
            [40.01001908738502, 43.38540779147201, 0],
            [39.97842592766821, 43.39243649847439, 0],
            [39.96666805642008, 43.3973824077388, 0],
            [39.9488202556315, 43.40044651456296, 0],
            [39.94181012172901, 43.40695517553098, 0],
            [39.92348677701115, 43.41678272336445, 0],
            [39.9137799796946, 43.42874367747295, 0],
            [39.88830220491555, 43.48587626961324, 0],
            [39.8619812367757, 43.51390218012393, 0],
            [39.85045891407751, 43.51251204517857, 0],
            [39.84669454970252, 43.51564883373672, 0],
            [39.84200048626639, 43.52111514630463, 0],
            [39.82902417163401, 43.5275336624079, 0],
            [39.80417135098907, 43.53763569773584, 0],
            [39.76554718984561, 43.55766117343396, 0],
            [39.71830027533057, 43.58629849001687, 0],
            [39.700516169247, 43.6330316486269, 0],
            [39.67299196027935, 43.64915950296621, 0],
            [39.60844640500057, 43.66688251189303, 0],
            [39.58575539202159, 43.70812814695683, 0],
            [39.5356987867213, 43.74742337485831, 0],
            [39.48318124970949, 43.78314310419492, 0],
            [39.39515150497336, 43.87066989437461, 0],
            [39.33512655224948, 43.89912155116909, 0],
            [39.31238354482361, 43.92410345974821, 0],
            [39.28452946810174, 43.95800402803275, 0],
            [39.16639001867219, 44.0219272677277, 0],
            [39.09050006437802, 44.08248572925977, 0],
            [39.06734692859801, 44.09773244261449, 0],
            [39.03942284838701, 44.09301540910614, 0],
            [39.03259492636998, 44.12393900891378, 0],
            [39.01949754121493, 44.14932004717442, 0],
            [38.98982008228871, 44.16570469177788, 0],
            [38.96560114891862, 44.1691052750968, 0],
            [38.93486456573356, 44.18066732404102, 0],
            [38.90667899648968, 44.18685942465174, 0],
            [38.89130900697991, 44.18859212654328, 0],
            [38.86958258204301, 44.1983196771247, 0],
            [38.84191225446031, 44.2374645926351, 0],
            [38.82650647810098, 44.24368364858621, 0],
            [38.82367637857213, 44.2554997371934, 0],
            [38.80154411078657, 44.27278803127184, 0],
            [38.7692337645997, 44.2907573401655, 0],
            [38.74721837951441, 44.29798356797285, 0],
            [38.72673836915087, 44.29869677029033, 0],
            [38.70609754427907, 44.30760827164266, 0],
            [38.66737844014749, 44.31644120823668, 0],
            [38.64540739306032, 44.32265611571922, 0],
            [38.58233654338802, 44.34742656855558, 0],
            [38.5371981900886, 44.35611237683575, 0],
            [38.51667404886683, 44.35859577148639, 0],
            [38.46923828987735, 44.37069217496673, 0],
            [38.42670014814745, 44.37208994428562, 0],
            [38.38074719163766, 44.37199506899715, 0],
            [38.3523431301615, 44.37712354307416, 0],
            [38.33863895167315, 44.38688576007161, 0],
            [38.31337483061398, 44.3991378170419, 0],
            [38.29252562188566, 44.40902487679409, 0],
            [38.25980575262484, 44.41500053452904, 0],
            [38.2256363839741, 44.41193954337793, 0],
            [38.20524367744705, 44.41749406132161, 0],
            [38.19661213225038, 44.43143448073586, 0],
            [38.17888683152847, 44.44722654344427, 0],
            [38.15614104611448, 44.45707557193382, 0],
            [38.14323698341283, 44.48020693784138, 0],
            [38.13314993589788, 44.49240998888386, 0],
            [38.12882188527836, 44.50405079979284, 0],
            [38.11478225024493, 44.51095272243847, 0],
            [38.04637906723133, 44.55176453801018, 0],
            [38.06112521944054, 44.55816564777334, 0],
            [38.07363962943074, 44.55837003738832, 0],
            [38.07590222191187, 44.56219533989343, 0],
            [38.06980941330632, 44.57203510002358, 0],
            [38.05400680148741, 44.58411110479614, 0],
            [38.03854731907454, 44.58556463892258, 0],
            [38.02180678255527, 44.5821911285444, 0],
            [38.03338988187197, 44.56527926438314, 0],
            [38.02973443617775, 44.55938431005202, 0],
            [38.00306990316808, 44.56543038434524, 0],
            [37.99093357953644, 44.57066489005481, 0],
            [37.97534431135101, 44.57790028788803, 0],
            [37.9356953213716, 44.60372674934016, 0],
            [37.90717690579293, 44.63413270714754, 0],
            [37.92852921570071, 44.64478777721303, 0],
            [37.92766178920958, 44.65506932165511, 0],
            [37.91626251472221, 44.66411692809827, 0],
            [37.89752261286633, 44.6774574826298, 0],
            [37.84607798599691, 44.71050084817524, 0],
            [37.80759009122087, 44.72974212576096, 0],
            [37.78522001641077, 44.73041615533106, 0],
            [37.77340794507901, 44.72433576062288, 0],
            [37.78765007069487, 44.7053167937268, 0],
            [37.79758673147948, 44.68559382065693, 0],
            [37.79531359251937, 44.66908923288991, 0],
            [37.73759856917518, 44.65269353594416, 0],
            [37.70283050240556, 44.65424949123575, 0],
            [37.67752977913791, 44.66397680073847, 0],
            [37.63866405748259, 44.67046025401314, 0],
            [37.61455729569271, 44.66801555781107, 0],
            [37.57615067880558, 44.6727912480731, 0],
            [37.56062157122699, 44.68014775190778, 0],
            [37.55205995275993, 44.67722366742958, 0],
            [37.52902259230942, 44.68262439168971, 0],
            [37.50040802525903, 44.69453664144655, 0],
            [37.4492328113469, 44.71659291688687, 0],
            [37.44919118128814, 44.71795883727136, 0],
            [37.41248767554925, 44.74281544820839, 0],
            [37.38929226575881, 44.75985333268209, 0],
            [37.38280458014224, 44.7827501921391, 0],
            [37.3417574945089, 44.83037298989134, 0],
            [37.3441260440073, 44.84735020684822, 0],
            [37.29877631407621, 44.89327454056829, 0],
            [37.30384953179393, 44.89888110226064, 0],
            [37.31353415443061, 44.89910412328562, 0],
            [37.32145129442584, 44.90614664719412, 0],
            [37.31815349623714, 44.92460452616884, 0],
            [37.2870693225308, 44.95649251914222, 0],
            [37.2214151876956, 44.99757635182169, 0],
            [37.078701104716, 45.05442032800057, 0],
            [36.89509234703126, 45.10662842828526, 0],
            [36.8523294075949, 45.11475646468323, 0],
            [36.80375354708649, 45.12121643660541, 0],
            [36.68559209397286, 45.12829922685379, 0],
            [36.63420288708402, 45.14093057645769, 0],
            [36.61732817538087, 45.16493246957735, 0],
            [36.61489291098775, 45.18166943500247, 0],
            [36.5985277421447, 45.19458332324728, 0],
            [36.60432524965142, 45.21157732154718, 0],
            [36.6361059423935, 45.21842005943515, 0],
            [36.68386386888388, 45.22665920207614, 0],
            [36.72617780027739, 45.21893667738957, 0],
            [36.76439150295064, 45.24093378474149, 0],
            [36.867168762968, 45.25061140994362, 0],
            [36.97536437680579, 45.27955712302474, 0],
            [36.99312390653444, 45.30473621854921, 0],
            [36.99709774364275, 45.32304312551833, 0],
            [36.94843980236957, 45.32828525633329, 0],
            [36.87402007463928, 45.32216321943515, 0],
            [36.80240366352547, 45.30237102971486, 0],
            [36.78551902730421, 45.33380161108725, 0],
            [36.76810719662998, 45.36594798545468, 0],
            [36.77276440537855, 45.37878310763203, 0],
            [36.78013908284505, 45.37795718850646, 0],
            [36.79933571993584, 45.35752384003217, 0],
            [36.81015693208011, 45.35610663522777, 0],
            [36.84925270537219, 45.3740252340728, 0],
            [36.84937995287458, 45.38054494666212, 0],
            [36.81855013033586, 45.38142473141477, 0],
            [36.81346169655006, 45.3850597141568, 0],
            [36.81176965103891, 45.38913134327433, 0],
            [36.79108478394958, 45.39199537503762, 0],
            [36.76000478580353, 45.41434606557228, 0],
            [36.7810027622773, 45.44102022591604, 0],
            [36.84691185436953, 45.45172420720161, 0],
            [36.85577590570254, 45.45058869039676, 0],
            [36.91976559537919, 45.43518288736232, 0],
            [37.09989121972891, 45.35947480532481, 0],
            [37.21704263572888, 45.33214261598283, 0],
            [37.29308934041413, 45.32947008672277, 0],
            [37.37103559650956, 45.34429052596679, 0],
            [37.38543394956521, 45.35010389414913, 0],
            [37.41708806087249, 45.35287029831876, 0],
            [37.45480280127332, 45.35164056129307, 0],
            [37.54327021325108, 45.38989264789326, 0],
            [37.59131624877008, 45.4708974960898, 0],
            [37.60501825965227, 45.63513682169021, 0],
            [37.62143939050194, 45.66671925876287, 0],
            [37.66479677594688, 45.69062610669788, 0],
            [37.72962629601246, 45.70515623282319, 0],
            [37.78564082523585, 45.7463972918143, 0],
            [37.82831740136787, 45.7520188539343, 0],
            [37.86077517402654, 45.79126000327153, 0],
            [37.96082469139472, 46.02500963173814, 0],
            [37.99967982723025, 46.04660855435996, 0],
            [38.05052741814513, 46.05192498677206, 0],
            [38.09427135628011, 46.05877152095848, 0],
            [38.11978722833607, 46.04991574631784, 0],
            [38.16043423883387, 46.04780980835467, 0],
            [38.18234044664871, 46.09173651469384, 0],
            [38.18319743693127, 46.11269567198522, 0],
            [38.22401722286669, 46.14184541204269, 0],
            [38.244124736662, 46.16510384773089, 0],
            [38.249083353201, 46.21869301825121, 0],
            [38.26447405671468, 46.25206986312204, 0],
            [38.28597262175891, 46.26367116776458, 0],
            [38.27796355613288, 46.27637594082475, 0],
            [38.24091135923182, 46.31204341250358, 0],
            [38.15938132387549, 46.37115504076056, 0],
            [38.09955219497084, 46.39738454662179, 0],
            [38.05451254243088, 46.40421524674787, 0],
            [38.01627220483512, 46.40437987320171, 0],
            [38.00374359131071, 46.38684172323208, 0],
            [38.014096571391, 46.37117983114575, 0],
            [37.99795002099122, 46.36413635741357, 0],
            [37.97703445807271, 46.36907271160374, 0],
            [37.92353590785563, 46.39983645170904, 0],
            [37.82945798099409, 46.52043530758597, 0],
            [37.75870082142549, 46.65320158789609, 0],
            [37.7208855963328, 46.69322627534587, 0],
            [37.80492815224354, 46.65025240736814, 0],
            [37.88223711051565, 46.63726752489089, 0],
            [37.97564246913645, 46.63354396334545, 0],
            [38.05871152949297, 46.64390681723383, 0],
            [38.13411169917583, 46.69103862194573, 0],
            [38.22835673098701, 46.70222409269237, 0],
            [38.29327797207966, 46.74311804348299, 0],
            [38.29052922202535, 46.72271987148112, 0],
            [38.31509357540074, 46.66950498679481, 0],
            [38.34719293170497, 46.66239471402739, 0],
            [38.4199788491366, 46.65806690039956, 0],
            [38.4886291693382, 46.64381667677427, 0],
            [38.55765040212943, 46.66351419592166, 0],
            [38.59287145460512, 46.6692077780487, 0],
            [38.59099105928325, 46.7046732463186, 0],
            [38.56488051819414, 46.73152439597475, 0],
            [38.51023193886702, 46.75426327151083, 0],
            [38.44944390027653, 46.75727396603423, 0],
            [38.40757230807417, 46.75598619905995, 0],
            [38.39951009300751, 46.77099397250683, 0],
            [38.44179944503143, 46.84934002785218, 0],
            [38.5019790470253, 46.88495775720747, 0],
            [38.56329803446844, 46.85505829217467, 0],
            [38.6478487910626, 46.87180214158148, 0],
            [38.71952317285528, 46.87478979263344, 0],
            [38.85592837256348, 46.91227178409328, 0],
            [38.89604041161257, 46.96696731796702, 0],
            [38.91944601288801, 46.95811105420918, 0],
            [39.10177441347595, 47.03892803217009, 0],
            [39.18738737017938, 47.03223761954307, 0],
            [39.27068662593178, 47.01760286102394, 0],
            [39.28931591758928, 47.04107291342537, 0],
            [39.28816933764699, 47.04483000632587, 0],
            [39.23242675374744, 47.07395727065013, 0],
            [39.22989497751408, 47.1041056810371, 0],
            [39.22155731390761, 47.15987287619793, 0],
            [39.21004201657222, 47.20054819327107, 0],
            [39.21633062244588, 47.23983793223417, 0],
            [39.19261411662859, 47.27517772549982, 0],
            [39.07119783941967, 47.28115586134972, 0],
            [39.03813116890694, 47.26127116622659, 0],
            [38.96689926000442, 47.26827113052352, 0],
            [38.94401281387064, 47.24017730942812, 0],
            [38.96018093826142, 47.20032237680562, 0],
            [38.89146130087376, 47.1959853767836, 0],
            [38.86636154202198, 47.1731696731485, 0],
            [38.76890093584906, 47.16852977267435, 0],
            [38.63543562159268, 47.14010302673846, 0],
            [38.5755763374319, 47.14173501109288, 0],
            [38.56981592895807, 47.10399772209724, 0],
            [38.48366188727132, 47.13772227430931, 0],
            [38.4027052945346, 47.13902612128629, 0],
            [38.32614842722973, 47.13735294051887, 0],
            [38.24887877525816, 47.12427290386933, 0],
            [38.08526919210535, 47.01554676862133, 0],
            [38.08490127637988, 47.02684192748573, 0],
            [38.13042118466095, 47.05540219148256, 0],
            [38.07012917965877, 47.1064915638153, 0],
            [38.03998602760692, 47.08116087104732, 0],
            [37.95790676828766, 47.0866375441856, 0],
            [37.88316877680371, 47.10274256757694, 0],
            [37.78772447750378, 47.07924317564986, 0],
            [37.64399396971915, 47.07137482584276, 0],
            [37.57932206306143, 47.07995639749821, 0],
            [37.51236931058104, 47.06505942550216, 0],
            [37.30264933137865, 46.85466455243327, 0],
            [37.27799262111587, 46.86316160230122, 0],
            [37.29818457640662, 46.87943888811823, 0],
            [37.31700088034332, 46.90171065477296, 0],
            [37.24323884197045, 46.94305193232645, 0],
            [36.97469707657154, 46.85376627150616, 0],
            [36.87395219498238, 46.80335535347994, 0],
            [36.83347868241933, 46.6990022796823, 0],
            [36.76860885862278, 46.62190175885053, 0],
            [36.74731208215045, 46.62962290854088, 0],
            [36.75053471610065, 46.65006262806119, 0],
            [36.76262603363791, 46.66923353673597, 0],
            [36.81013209658354, 46.72326608326286, 0],
            [36.75255986009227, 46.7684681877006, 0],
            [36.665785685069, 46.78419190653528, 0],
            [36.52884040591853, 46.75545144842754, 0],
            [36.38383066626062, 46.71576723398992, 0],
            [36.17320665528559, 46.48881891721602, 0],
            [36.15526241574192, 46.49427827614747, 0],
            [36.12509792232398, 46.48496867674501, 0],
            [36.09490777877358, 46.49227312240261, 0],
            [36.10507368032792, 46.50470703815732, 0],
            [36.13149925045761, 46.52068924977587, 0],
            [36.14213651249453, 46.55880060970945, 0],
            [36.18045690228712, 46.54495746481278, 0],
            [36.21254453480996, 46.57542304418862, 0],
            [36.25271288245253, 46.61668974100149, 0],
            [36.22240344841205, 46.65875357402206, 0],
            [36.15947725791488, 46.66052716277477, 0],
            [36.10053967636377, 46.65182626173856, 0],
            [35.96073050756169, 46.65925338528167, 0],
            [35.75700580810432, 46.59108401620273, 0],
            [35.56844406971446, 46.46834611403997, 0],
            [35.46973321949937, 46.44271997773897, 0],
            [35.13100146124037, 46.09020045469853, 0],
            [34.99377128862482, 46.07523084726731, 0],
            [34.98713373495848, 46.10142299409221, 0],
            [35.02513238611007, 46.14163988612154, 0],
            [35.19001873520685, 46.19165294322172, 0],
            [35.2403754789536, 46.2805337922462, 0],
            [35.13034794586371, 46.32735645994001, 0],
            [35.06774183256759, 46.26736506746941, 0],
            [34.90020598837398, 46.23145098121206, 0],
            [34.83075121671779, 46.1904823729125, 0],
            [34.85178730587763, 46.01999572415771, 0],
            [34.89503883522175, 45.90223254558448, 0],
            [35.03616411102604, 45.67875714524953, 0],
            [35.13117351302161, 45.48262849421865, 0],
            [35.03751149139504, 45.44111485224359, 0],
            [35.09466421407507, 45.35223608672429, 0],
            [35.14353857359675, 45.32065402319499, 0],
            [35.15209677401452, 45.34474289624853, 0],
            [35.28293716923324, 45.30781706868811, 0],
            [35.36925290287046, 45.27147216127402, 0],
            [35.52768667019945, 45.28405685221368, 0],
            [35.61735186660339, 45.31623374310428, 0],
            [35.73545188911, 45.34941470540804, 0],
            [35.76576532088455, 45.39545551727111, 0],
            [35.82449285402976, 45.4438234186841, 0],
            [35.95643151618343, 45.36756372628911, 0],
            [36.07799705802508, 45.39660136129952, 0],
            [36.07655293926397, 45.44265226631986, 0],
            [36.2927733493983, 45.47612130224351, 0],
            [36.39058609487406, 45.44682238055177, 0],
            [36.51136080036045, 45.44816696416201, 0],
            [36.54151983863753, 45.4191592839712, 0],
            [36.5847945989454, 45.43792950946849, 0],
            [36.61196812372311, 45.43004361176813, 0],
            [36.61011847506073, 45.40300606998029, 0],
            [36.64857701526247, 45.37867286184194, 0],
            [36.59941049280793, 45.34747786593203, 0],
            [36.54054354918672, 45.34380939251667, 0],
            [36.50110074572288, 45.3624235231163, 0],
            [36.47449455954056, 45.35829638933287, 0],
            [36.48781985846186, 45.31720106887921, 0],
            [36.44689128366471, 45.29611365648272, 0],
            [36.4032487265607, 45.21206565572779, 0],
            [36.4203350404954, 45.12832287050878, 0],
            [36.45653888695578, 45.09596216204096, 0],
            [36.37620402187925, 45.05565706826171, 0],
            [36.21909024800976, 45.02689262495771, 0],
            [36.04837739380423, 45.04983714892929, 0],
            [35.99341110615519, 45.03300704180212, 0],
            [35.94983388553432, 45.01681493283278, 0],
            [35.90296979353231, 45.01749138685769, 0],
            [35.84023929199444, 44.99725319584351, 0],
            [35.81169104887228, 45.02124474743105, 0],
            [35.80866174645585, 45.04428500636759, 0],
            [35.77887277591358, 45.06634074769462, 0],
            [35.60707212317265, 45.13010898923708, 0],
            [35.48100159794615, 45.11573331202867, 0],
            [35.39398813114562, 45.06594327891462, 0],
            [35.38354348904195, 45.03054188430935, 0],
            [35.42456517913111, 45.0109538661235, 0],
            [35.36548731491713, 44.99152647011118, 0],
            [35.25906551203417, 44.96146583392328, 0],
            [35.22318304333273, 44.91453279881901, 0],
            [35.22383191398484, 44.9145654001909, 0],
            [35.16530515335932, 44.89655824491025, 0],
            [35.12515438871849, 44.82117354032285, 0],
            [35.10971676063643, 44.79210252095474, 0],
            [35.07046525705773, 44.79181155098909, 0],
            [35.0447795461921, 44.80391510774214, 0],
            [35.00301321295542, 44.83272045810757, 0],
            [34.96263734573849, 44.83665694830503, 0],
            [34.9026264254288, 44.82398779478201, 0],
            [34.8720501504321, 44.81369285098116, 0],
            [34.79919219274925, 44.81557301693093, 0],
            [34.75683345657919, 44.8139285331025, 0],
            [34.63628309205279, 44.78435324281884, 0],
            [34.51889673168692, 44.73928379161362, 0],
            [34.44565860771248, 44.70703045186375, 0],
            [34.37905926051928, 44.61917723864438, 0],
            [34.37021579532455, 44.59180112835504, 0],
            [34.35059949626154, 44.59009545358511, 0],
            [34.3453659627029, 44.54796498648486, 0],
            [34.31034449996355, 44.55313231209909, 0],
            [34.27824878349922, 44.54284625847621, 0],
            [34.26296091995145, 44.51467494005193, 0],
            [34.21194855783232, 44.50380103590383, 0],
            [34.17270869626462, 44.49796721249877, 0],
            [34.13758470652753, 44.45365019380666, 0],
            [34.12544669344192, 44.42713383556993, 0],
            [33.98344014514018, 44.39849591133791, 0],
            [33.93991268594358, 44.39304404243425, 0],
            [33.87757415966249, 44.40457910513253, 0],
            [33.73720847010569, 44.38889730090133, 0],
            [33.70513686327654, 44.41971863178834, 0],
            [33.66882402251655, 44.41872926309457, 0],
            [33.64646730385571, 44.42753657644121, 0],
            [33.62923490426724, 44.47855868119449, 0],
            [33.58864042917381, 44.49427360313479, 0],
            [33.54201465733473, 44.48909366059759, 0],
            [33.5068215795928, 44.50231657263192, 0],
            [33.48276629682845, 44.49851324759849, 0],
            [33.47956836974018, 44.51045737774034, 0],
            [33.41311983974558, 44.55313195034076, 0],
            [33.37676021529211, 44.58436850448881, 0],
            [33.4014857945749, 44.5899668967534, 0],
            [33.41406513390359, 44.58653468734335, 0],
            [33.42643206346478, 44.59766086451409, 0],
            [33.4645577789666, 44.60957315444411, 0],
            [33.50107651219071, 44.61410107439477, 0],
            [33.53336387575477, 44.61732821277185, 0],
            [33.54040599579764, 44.62397658541811, 0],
            [33.51231237171307, 44.6310477179023, 0],
            [33.51863424681231, 44.63974093008213, 0],
            [33.53099109634741, 44.6386534655139, 0],
            [33.54561678376276, 44.68152087086337, 0],
            [33.53463545481655, 44.79194400342823, 0],
            [33.54935159074284, 44.83474069111946, 0],
            [33.57815661163171, 44.83949663148903, 0],
            [33.6162977936242, 44.90962367489012, 0],
            [33.60088960360586, 44.97275023997039, 0],
            [33.60420435824045, 44.99998329445981, 0],
            [33.54001533181135, 45.10770011611901, 0],
            [33.47861671172257, 45.15871085591751, 0],
            [33.38261721201898, 45.1975452700203, 0],
            [33.3566920722866, 45.1755716869834, 0],
            [33.30924971935946, 45.17314974332348, 0],
            [33.27809881977871, 45.14530713113316, 0],
            [33.24902405691596, 45.15400121760239, 0],
            [33.21914071484212, 45.17756323522127, 0],
            [33.15223429431867, 45.20135250293408, 0],
            [33.11578142035006, 45.22938452570216, 0],
            [33.06294682232801, 45.27731239717825, 0],
            [32.9274716045872, 45.35307047724641, 0],
            [32.76521868000761, 45.36511503406732, 0],
            [32.66405956184622, 45.32196785699717, 0],
            [32.50669625927374, 45.34335243156946, 0],
            [32.51733122826919, 45.37248719213518, 0],
            [32.48690704451221, 45.394891546486, 0],
            [32.52295636023931, 45.439883373906, 0],
            [32.60700019966497, 45.48386708485181, 0],
            [32.7982892279818, 45.55287271243657, 0],
            [32.84875675332377, 45.60172516773049, 0],
            [33.12328863447395, 45.71520165372734, 0],
            [33.17587149994504, 45.81521652760931, 0],
            [33.18075110890186, 45.78593032013313, 0],
            [33.19031278009705, 45.76722408432034, 0],
            [33.23145889863657, 45.75225834651345, 0],
            [33.30743515238616, 45.76593996993188, 0],
            [33.4138130881563, 45.82426878731503, 0],
            [33.46527449761547, 45.85078670623202, 0],
            [33.53527056981183, 45.87911756732144, 0],
            [33.60135772672872, 45.89091039559673, 0],
            [33.66023887958558, 45.90228031676783, 0],
            [33.66293334861354, 45.90446224266098, 0],
            [33.71522199056584, 45.95004172201252, 0],
            [33.63320332677779, 45.93436166179559, 0],
            [33.61423582786165, 45.93331719107681, 0],
            [33.62165165258941, 45.97237857817976, 0],
            [33.60416919445403, 46.05079436946813, 0],
            [33.51327704433518, 46.02228724277695, 0],
            [33.43918662717811, 46.02827337578525, 0],
            [33.33335851431036, 46.09343084080106, 0],
            [33.27363303396177, 46.06452711020134, 0],
            [33.27409477074261, 46.09813562131334, 0],
            [33.24928254416174, 46.13434822729241, 0],
            [33.21534188168659, 46.11098090292356, 0],
            [33.19509342555158, 46.1209806239896, 0],
            [33.10488503683958, 46.10242831977913, 0],
            [32.92673079817357, 46.07342058059113, 0],
            [33.10967504397302, 46.01421769266749, 0],
            [32.93091742407601, 46.00447440021505, 0],
            [32.41295147389992, 46.09026466691676, 0],
            [32.13128256601129, 46.14751056834245, 0],
            [31.66093190916717, 46.22346531668439, 0],
            [31.57640043619931, 46.25426587548419, 0],
            [31.570076892137, 46.2568830703196, 0],
            [31.56360206665187, 46.26050996293381, 0],
            [31.497327106398, 46.31508676749988, 0],
            [31.49458174482304, 46.36090202701431, 0],
            [31.51833421145402, 46.37994975461954, 0],
            [31.5398519252122, 46.37532639121874, 0],
            [31.54168823784201, 46.35398093225088, 0],
            [31.54546227263701, 46.30110494557131, 0],
            [31.56951150946944, 46.27011165626603, 0],
            [31.60728927663396, 46.25541208211577, 0],
            [31.66071577172307, 46.25405179340632, 0],
            [31.66379348554289, 46.25324651113285, 0],
            [31.7182206408835, 46.27437658734456, 0],
            [31.7208406759544, 46.27659841119883, 0],
            [31.76903013282187, 46.31972195984805, 0],
            [31.81023870634873, 46.33420596490494, 0],
            [31.95131085405088, 46.3480108019609, 0],
            [32.0122803254227, 46.40606178415612, 0],
            [31.99420107940473, 46.42923292305088, 0],
            [31.89314380987376, 46.44371272909063, 0],
            [31.80775433666457, 46.46124731020299, 0],
            [31.72432180631593, 46.47478941796846, 0],
            [31.7209245519631, 46.44807868631865, 0],
            [31.79047822566709, 46.36849070178632, 0],
            [31.75489274309802, 46.36901265911496, 0],
            [31.73621833603359, 46.39518894294822, 0],
            [31.70414192736275, 46.431628550521, 0],
            [31.57855983487328, 46.51654011767781, 0],
            [31.53278559460419, 46.55308576183836, 0],
            [31.5050049838916, 46.57868197899227, 0],
            [31.44640192236978, 46.61260657642744, 0],
            [31.34852401384345, 46.60337066905917, 0],
            [31.14754714473563, 46.62811386620726, 0],
            [30.82854309502949, 46.55783422850728, 0],
            [30.77209166074209, 46.54938627209702, 0],
            [30.72447903551168, 46.51507417112897, 0],
            [30.75241662912167, 46.48975903656616, 0],
            [30.77422855573405, 46.42117545287495, 0],
            [30.44567026056432, 46.06316380087303, 0],
            [30.18728431790594, 45.84307505714052, 0],
            [29.68031894535922, 45.56175779402166, 0],
            [29.63094101055882, 45.51941127167399, 0],
            [29.62147748827723, 45.49091101706732, 0],
            [29.70169427559998, 45.47008866040529, 0],
            [29.75155905183307, 45.4929104248785, 0],
            [29.756702278258, 45.37597843496953, 0],
            [29.76547519254345, 45.18094213738703, 0],
          ],
        ],
      },
      properties: {
        name: "Sea of Azov and Black Sea 168,279 Sq Km",
        tessellate: true,
      },
    },
  ],
};
