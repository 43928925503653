import React, { CSSProperties } from "react";
import { OutlinedInput, InputLabel, FormHelperText } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import styled from "styled-components";

interface InputContainerProps {
  inputStyle?: React.CSSProperties;
}

const OutlinedInputStyled = styled(OutlinedInput)({
  height: "32px",
  backgroundColor: "#00000080",
  borderColor: "#ffffff40",
  color: "#FFF",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ffffff40",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ffffff40",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff",
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FA5849",
  },
});

const InputContainer = styled.div<InputContainerProps>`
  ${({ inputStyle }) => inputStyle && { ...inputStyle }}
`;

const StyledFormHelperText = styled(FormHelperText)(() => ({
  color: "#FFF",
  marginTop: "4px",
  fontSize: "12px",
  display: "flex",
  alignItems: "center",
}));

const OutlinedInputField: React.FC<{
  value: string;
  setValue: (value: string) => void;
  label: string;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: boolean;
  errorMessage?: string;
  inputStyle?: CSSProperties;
  placeholder?: string;
}> = ({
  label,
  value,
  setValue,
  onBlur,
  onFocus,
  error,
  errorMessage,
  inputStyle,
  placeholder,
}) => {
  const handleOnBlur = (): void => {
    if (onBlur) onBlur();
  };

  const handleOnFocus = (): void => {
    if (onFocus) onFocus();
  };

  return (
    <InputContainer inputStyle={inputStyle}>
      <InputLabel
        htmlFor="outlined-input"
        sx={{ color: "#FFF", paddingBottom: "16px" }}
        shrink={false}
      >
        {label}
      </InputLabel>
      <div>
        <OutlinedInputStyled
          id="outlined-input"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          notched={false}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          error={error}
          placeholder={placeholder}
          fullWidth
        />
        {error && errorMessage && (
          <StyledFormHelperText>
            <CloseIcon
              sx={{
                fontSize: "12px",
                color: "red",
              }}
            />
            {errorMessage}
          </StyledFormHelperText>
        )}
      </div>
    </InputContainer>
  );
};

export default OutlinedInputField;
