import { Navigate, useRoutes } from "react-router-dom";
import SignIn from "../pages/Auth/SignIn/SignIn";
import SignUp from "../pages/Auth/SignUp/SignUp";
import ForgetPassword from "../pages/Auth/ForgetPassword/ForgetPassword";
import EmailCheck from "../pages/Auth/EmailCheck/EmailCheck";
import MFA from "../pages/MFA/MFA";
import AuthWrapper from "../pages/Auth/AuthWrapper";
import Theia from "../pages/Theia/Theia";
import LayoutWrapper from "../layout/LayoutWrapper/LayoutWrapper";
import Spinner from "../components/Spinner/Spinner";
import MFAWrapper from "../pages/MFA/MFAWrapper";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import useFireBaseAuth from "../context/useFireBaseAuth";
import ChangePasswordSignIn from "../components/ChangePasswordSignIn/ChangePasswordSignIn";

export default function Routes() {
  const { getCurrentUserLoggedIn } = useFireBaseAuth();
  const currentLoggedInUser = getCurrentUserLoggedIn();
  const isMFAVerified = localStorage.getItem("isMFAVerified");
  const { showLoader } = useSelector(
    (state: RootState) => state.globalTheiaState,
  );
  const routes = useRoutes([
    {
      element: <AuthWrapper />,
      children: [
        { path: "login", element: <SignIn /> },
        { path: "register", element: <SignUp /> },
        { path: "reset-password", element: <ForgetPassword /> },
        { path: "resend-email", element: <EmailCheck /> },
        {
          path: "change-password",
          element: <ChangePasswordSignIn />,
        },
        // { path: "mfa", element: <MFA /> },
      ],
    },
    {
      element: <MFAWrapper />,
      children: [{ path: "mfa", element: <MFA /> }],
    },
    {
      element: <LayoutWrapper />,
      children: [
        {
          path: "theia",
          element:
            isMFAVerified === "true" && currentLoggedInUser ? (
              <Theia />
            ) : isMFAVerified === "false" ? (
              <Navigate to="/mfa" />
            ) : (
              !showLoader && <Spinner />
            ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/login" /> },
  ]);

  return routes;
}
