export const elecLink = {
  type: "FeatureCollection",
  name: "ElecLink Cable.kmz",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Name: "ElecLink Cable",
        tessellate: 1,
        extrude: 0,
        visibility: -1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [1.228359422166101, 51.10069954539064, 0.0],
          [1.241039830234707, 51.094349750455727, 0.0],
          [1.253824294291039, 51.087989058890969, 0.0],
          [1.266790936963584, 51.081580085099368, 0.0],
          [1.280907367749908, 51.074344794001981, 0.0],
          [1.299643008098523, 51.068840589790419, 0.0],
          [1.315880018279549, 51.064101531424747, 0.0],
          [1.328059404981321, 51.060578771659188, 0.0],
          [1.351584803771286, 51.053696765371349, 0.0],
          [1.375958945453919, 51.046491120611222, 0.0],
          [1.396434702603706, 51.040477517871103, 0.0],
          [1.41261628811292, 51.035703950233732, 0.0],
          [1.419514862642264, 51.033925447255243, 0.0],
          [1.435908610078525, 51.030468677076691, 0.0],
          [1.467156540349701, 51.024110770484491, 0.0],
          [1.492913925080936, 51.018673531448933, 0.0],
          [1.517834141515386, 51.013439898005707, 0.0],
          [1.524486485661967, 51.012057235641223, 0.0],
          [1.547148215833389, 51.004931073154253, 0.0],
          [1.555770955276174, 51.002231577596348, 0.0],
          [1.586788173444049, 50.99247629644487, 0.0],
          [1.616805850789333, 50.983099607782748, 0.0],
          [1.646625639674479, 50.973717874059979, 0.0],
          [1.678534214374878, 50.963587107784313, 0.0],
          [1.687306029279818, 50.960875183088703, 0.0],
          [1.712152046565483, 50.951439353688031, 0.0],
          [1.731112688206056, 50.944155767693573, 0.0],
          [1.744630815397892, 50.939011257209131, 0.0],
        ],
      },
    },
  ],
};
