export const gulfOfGuinea = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.199993355235909, 6.112496696633114, 0],
            [3.000013363698097, -0.6666631468155622, 0],
            [8.700103259018716, -0.6668396014561676, 0],
            [8.699159335494253, -0.6624168641412006, 0],
            [8.699047263339976, -0.6492496863088943, 0],
            [8.701396796266852, -0.6343643838766555, 0],
            [8.703897996671943, -0.6289530478469352, 0],
            [8.70713133114027, -0.6259505413591377, 0],
            [8.70721916278291, -0.6256145591868401, 0],
            [8.70828674963671, -0.6203186086841219, 0],
            [8.712260001996832, -0.6190961564684829, 0],
            [8.715685604547813, -0.6217453908727204, 0],
            [8.716726948643707, -0.6263949793362756, 0],
            [8.715839700521915, -0.6305964326005854, 0],
            [8.711012403358913, -0.6331548107726024, 0],
            [8.70913610953836, -0.6352414543009617, 0],
            [8.710511114039939, -0.6405610905995434, 0],
            [8.710504904363985, -0.6410662063264644, 0],
            [8.710587222268778, -0.6412354725751841, 0],
            [8.714925105763786, -0.6453246898902957, 0],
            [8.715262269931891, -0.6453284268356783, 0],
            [8.719971267050013, -0.6464753253164203, 0],
            [8.720725895258814, -0.6468203957677546, 0],
            [8.73271602986565, -0.6506494117227481, 0],
            [8.741566801827522, -0.6518451255584905, 0],
            [8.74164974372851, -0.6519301222303628, 0],
            [8.748420894744973, -0.65612763138707, 0],
            [8.751869431095074, -0.6568391360951387, 0],
            [8.76766572624665, -0.6709330022667365, 0],
            [8.781668836605851, -0.6795388848063144, 0],
            [8.774127659511748, -0.6991888888069676, 0],
            [8.791927783987095, -0.7219296340587423, 0],
            [8.797965161941406, -0.737783212895864, 0],
            [8.793363850464049, -0.7461899597455703, 0],
            [8.80439383913205, -0.7699953664143817, 0],
            [8.810888961288283, -0.7960094350702202, 0],
            [8.838395908130545, -0.8087197012210238, 0],
            [8.850890783630124, -0.8003926630882205, 0],
            [8.85205631972818, -0.7970232041357744, 0],
            [8.856259267956188, -0.772817159897268, 0],
            [8.86769461850723, -0.7577106222065221, 0],
            [8.887406941319188, -0.760743566027968, 0],
            [8.904834835195432, -0.7648711827764568, 0],
            [8.899947695070985, -0.7479057821230747, 0],
            [8.890446167729749, -0.739343930426612, 0],
            [8.889444580965502, -0.7263602453196188, 0],
            [8.900216291162732, -0.7202708968050493, 0],
            [8.930702207170839, -0.7228652392296864, 0],
            [8.941829356210329, -0.7393458656595716, 0],
            [8.943397142490488, -0.751774376245545, 0],
            [8.959854095866891, -0.7446225569345019, 0],
            [8.955510935815829, -0.7265195662631341, 0],
            [8.949562953355565, -0.7005069988494316, 0],
            [8.952608187474675, -0.6785424728944109, 0],
            [8.954316942203548, -0.6768686569556889, 0],
            [8.980602026430704, -0.6483802775216198, 0],
            [8.981175036312987, -0.6478219493949186, 0],
            [8.999926495845965, -0.6406802079651764, 0],
            [9.023523393898481, -0.6533408044161407, 0],
            [9.049493320804675, -0.6603831086754471, 0],
            [9.070996713972303, -0.6606085570243754, 0],
            [9.086927769737487, -0.6534356970162404, 0],
            [9.117090062686204, -0.6368116010672951, 0],
            [9.131440715869806, -0.6222711654953885, 0],
            [9.159223131206481, -0.5732644016401387, 0],
            [9.1735667713926, -0.5536702852095373, 0],
            [9.188304820411833, -0.5504402673197487, 0],
            [9.210091286747705, -0.5196336266373007, 0],
            [9.228055500970422, -0.4876303426369871, 0],
            [9.241706198317832, -0.4815574947794248, 0],
            [9.286302210244973, -0.3972490229042975, 0],
            [9.302385224286498, -0.3127228145370466, 0],
            [9.30331079762623, -0.2788790402832328, 0],
            [9.303940345771045, -0.223011208989805, 0],
            [9.319170396320274, -0.1791052693480114, 0],
            [9.328402607271759, -0.1147913218496487, 0],
            [9.32831248825689, -0.05178298542343562, 0],
            [9.347816860299744, -0.02490615568742403, 0],
            [9.350045484437718, 0.02811180056811714, 0],
            [9.334982902870152, 0.0903682165602924, 0],
            [9.335002170017969, 0.09206163495027599, 0],
            [9.313005032540945, 0.195054786941533, 0],
            [9.312729520947185, 0.218794140138557, 0],
            [9.301610241839082, 0.3071571909066136, 0],
            [9.316411772962848, 0.3239120643500988, 0],
            [9.33820421382987, 0.3378424978211889, 0],
            [9.350765601403896, 0.3478790600464564, 0],
            [9.371259941795469, 0.3081516589140928, 0],
            [9.376551967729137, 0.275350913575331, 0],
            [9.405066536467558, 0.2225059742464498, 0],
            [9.430707265958986, 0.1946041416118318, 0],
            [9.450866029884963, 0.1797275768916826, 0],
            [9.451988607490376, 0.1791516351219102, 0],
            [9.484104806549041, 0.1195822518913718, 0],
            [9.500373674533282, 0.1120793710324258, 0],
            [9.493452788968659, 0.1499406314187622, 0],
            [9.51169507029274, 0.1677948990016876, 0],
            [9.544961969767979, 0.1617935993466139, 0],
            [9.571467774088616, 0.1558626143580917, 0],
            [9.582616417810979, 0.1393734255532251, 0],
            [9.587846939137318, 0.09811331406836353, 0],
            [9.59622139753742, 0.08729269877268163, 0],
            [9.596361159961294, 0.103103220164683, 0],
            [9.597190777128198, 0.1267953445667088, 0],
            [9.611915262870168, 0.1373718961562717, 0],
            [9.613071166578917, 0.1390509406076625, 0],
            [9.623468635746722, 0.1524722296217852, 0],
            [9.644336575931076, 0.1465993490706193, 0],
            [9.655491483785571, 0.1267086080073867, 0],
            [9.656585045154271, 0.1238746510522852, 0],
            [9.669479149173053, 0.1130042985285814, 0],
            [9.666791690459004, 0.1299803354173698, 0],
            [9.680490281048765, 0.1326397238609448, 0],
            [9.69114444071235, 0.128577571289951, 0],
            [9.741002763288284, 0.1174040398599791, 0],
            [9.762000333673013, 0.1273268620498951, 0],
            [9.79457679778523, 0.1704167590529844, 0],
            [9.72318075188285, 0.1926118724507722, 0],
            [9.70992879937061, 0.2220768734359401, 0],
            [9.668144316496281, 0.2219479890608898, 0],
            [9.5685545869938, 0.2918004919600026, 0],
            [9.552193846481433, 0.2908476820899485, 0],
            [9.524174641436156, 0.3069355676030663, 0],
            [9.505851951360357, 0.2806272006151956, 0],
            [9.475463577931764, 0.3396130517252948, 0],
            [9.449955161048306, 0.3810755382638062, 0],
            [9.416176291122216, 0.4435684280793059, 0],
            [9.383542909727494, 0.4969350989501296, 0],
            [9.342059949461586, 0.5216283453882132, 0],
            [9.318674854949956, 0.5010213584001478, 0],
            [9.304757149388008, 0.5186551703212, 0],
            [9.307679410449621, 0.5293442642271585, 0],
            [9.3089419743849, 0.5987363621401067, 0],
            [9.321595528989056, 0.6183221642350527, 0],
            [9.35152180409704, 0.6196771275765347, 0],
            [9.369602377650288, 0.6188880219317151, 0],
            [9.398072616481905, 0.6450958197948911, 0],
            [9.445964341620464, 0.641787268187025, 0],
            [9.499832357756679, 0.6688638743479945, 0],
            [9.526639650502142, 0.6437345519050576, 0],
            [9.552644360489692, 0.5971848220908268, 0],
            [9.554317093104794, 0.5954726874696684, 0],
            [9.569712078446326, 0.5586605742304976, 0],
            [9.609133113588593, 0.553734216716356, 0],
            [9.60747014533393, 0.6084602225999128, 0],
            [9.610703507756117, 0.6462302490333285, 0],
            [9.607422893838235, 0.7061318524750837, 0],
            [9.617897339540187, 0.7430646957964558, 0],
            [9.610375577249249, 0.7764182638255299, 0],
            [9.59001806327733, 0.8239859574614263, 0],
            [9.568448022103269, 0.9145100161096107, 0],
            [9.551214675317283, 0.9378554207430357, 0],
            [9.569711647416435, 0.9819771956369275, 0],
            [9.58580430825526, 1.009996587986797, 0],
            [9.582254845439016, 1.04779552845943, 0],
            [9.526729540910658, 1.127926719467992, 0],
            [9.48500615523303, 1.129515528267924, 0],
            [9.42389272706813, 1.109273435196659, 0],
            [9.383227604178844, 1.105782051960093, 0],
            [9.375630878462637, 1.131207635738325, 0],
            [9.347010202657922, 1.155882926805426, 0],
            [9.338243803785824, 1.184229125895927, 0],
            [9.368815225364575, 1.190642412038573, 0],
            [9.369937727152456, 1.190066323879706, 0],
            [9.378655931159654, 1.213092980936527, 0],
            [9.391046396925733, 1.25436309775431, 0],
            [9.406002602685277, 1.282395446094412, 0],
            [9.437712987263062, 1.293322954879348, 0],
            [9.458929599313898, 1.327501627201492, 0],
            [9.456263343591012, 1.397506656532281, 0],
            [9.510849770544123, 1.436027632359224, 0],
            [9.51090237206704, 1.441102330746568, 0],
            [9.552026179861786, 1.490278491451122, 0],
            [9.554305589625905, 1.492508478520125, 0],
            [9.574300692495035, 1.516538119586435, 0],
            [9.59348726190602, 1.568812855687497, 0],
            [9.610129451301406, 1.594592298164816, 0],
            [9.60943146734971, 1.63355643908367, 0],
            [9.635616150675812, 1.70698454527205, 0],
            [9.69063424327491, 1.752781417195462, 0],
            [9.69265321109506, 1.752758677041191, 0],
            [9.749799968712033, 1.806597013979188, 0],
            [9.746282511171195, 1.85305056328518, 0],
            [9.797486915135805, 1.915033764583146, 0],
            [9.79950694361885, 1.915011148340043, 0],
            [9.802002956278148, 1.957364464751027, 0],
            [9.757245572526426, 2.107214716529799, 0],
            [9.784010030125138, 2.149323008085095, 0],
            [9.782000049395542, 2.319036188127392, 0],
            [9.8188981551543, 2.359088947025195, 0],
            [9.828263986176216, 2.462143424932211, 0],
            [9.819740790272903, 2.583816009167951, 0],
            [9.851124498480432, 2.722726743867625, 0],
            [9.886446095730072, 2.801091169913694, 0],
            [9.893388950415138, 2.873728128733038, 0],
            [9.904361579037086, 2.940319737176554, 0],
            [9.912637403065856, 2.954384467839063, 0],
            [9.964400679243893, 3.040835125600851, 0],
            [9.96546838631197, 3.11979342891752, 0],
            [9.965599467326314, 3.129913483680073, 0],
            [9.910511039366979, 3.25407814462873, 0],
            [9.809212295168857, 3.413330841734903, 0],
            [9.710498881475534, 3.500508671580584, 0],
            [9.640203742316903, 3.545685125929154, 0],
            [9.621367742077588, 3.663045088769915, 0],
            [9.592530609684001, 3.790692237035573, 0],
            [9.534343036938784, 3.833799223128678, 0],
            [9.372996938769784, 3.886097546639716, 0],
            [9.27233557579838, 3.937821362350312, 0],
            [9.175438715484336, 3.967246789852516, 0],
            [9.054591474520084, 4.043697933669193, 0],
            [8.986109046940733, 4.089043436764636, 0],
            [8.98592037841659, 4.208203147803835, 0],
            [8.905583662693342, 4.281814218975867, 0],
            [8.906392796325157, 4.356481123497238, 0],
            [8.866666045227321, 4.462225141793519, 0],
            [8.854947101930632, 4.514994363341656, 0],
            [8.721144923575459, 4.549038950029453, 0],
            [8.69262814459291, 4.479861078100568, 0],
            [8.56491991321863, 4.471109976727625, 0],
            [8.494400267688004, 4.518419637125022, 0],
            [8.496846838901346, 4.566978097387254, 0],
            [8.499757933229121, 4.670285664781277, 0],
            [8.32523586659308, 4.682458779516318, 0],
            [8.34289819386154, 4.601142140947229, 0],
            [8.279399836272653, 4.530965405600046, 0],
            [7.994629591699187, 4.536906887302046, 0],
            [7.678371826635402, 4.499514874077229, 0],
            [7.575335161337648, 4.441795577500262, 0],
            [7.366743215531272, 4.431521759702237, 0],
            [7.177786355809084, 4.370577135956325, 0],
            [7.125125752455403, 4.381118995583744, 0],
            [7.127623061012011, 4.441680046278627, 0],
            [7.013821883763248, 4.430544220728812, 0],
            [7.019569368467772, 4.384028836228376, 0],
            [6.986791052485779, 4.349953845409513, 0],
            [6.85486178698683, 4.389446876893124, 0],
            [6.864726156158786, 4.346913179279475, 0],
            [6.728125074328945, 4.341941713167396, 0],
            [6.455126570160933, 4.297434705217336, 0],
            [6.102020415211669, 4.275683196968873, 0],
            [5.959926223218693, 4.327169380321142, 0],
            [5.797328802685067, 4.429451852025928, 0],
            [5.646597189941495, 4.584572538890678, 0],
            [5.433024670780179, 4.952046786630585, 0],
            [5.356777417937222, 5.162948980049672, 0],
            [5.322835991729895, 5.357870880234465, 0],
            [5.298575710350928, 5.421048109109754, 0],
            [5.158007263972522, 5.526115691856113, 0],
            [5.160024581532321, 5.574760857314414, 0],
            [5.105365871723048, 5.684397701419837, 0],
            [5.046437399714412, 5.782057872834654, 0],
            [4.788382123415262, 6.091221909522394, 0],
            [4.592088319160332, 6.224087076585212, 0],
            [4.477670693010243, 6.325009192212728, 0],
            [4.293069572420691, 6.37630031227804, 0],
            [4.012561430575873, 6.424046585802654, 0],
            [3.722937579806496, 6.429474599533585, 0],
            [3.438163019607554, 6.424800662427366, 0],
            [2.824435151927707, 6.386337770693423, 0],
            [2.563565287076413, 6.350540357809833, 0],
            [2.258637760367841, 6.345146196362303, 0],
            [1.546343463577076, 6.216761366566734, 0],
            [1.336551357121818, 6.162071448936334, 0],
            [1.199993355235909, 6.112496696633114, 0],
          ],
        ],
      },
      properties: { name: "Gulf of Guinea 544,526 Sq Km", tessellate: true },
    },
  ],
};
