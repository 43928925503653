import React from "react";
import {
  ShipDetailsInterFace,
  BunkeringInterface,
} from "../../../context/useShipDetails";
import TabsWithTable from "../../../components/Sidebar/TabsWithTable";
import { Box } from "@mui/material";
import CountryFlagIcon from "../../../components/CountryFlagIcon/CountryFlagIcon";

interface ShipProps {
  events: { [key: string]: ShipDetailsInterFace | BunkeringInterface };
  handleTableRowClick: (id: string, type: string, tabIndex?: string) => void;
  getEventDetails: (eventType: string) => {
    name: string;
    color: string;
  };
}

const MyEvents: React.FC<ShipProps> = ({
  events,
  handleTableRowClick,
  getEventDetails,
}) => {
  const columns = ["event", "ship", "flag", "date"];

  const rows = Object.values(events).map((event) => {
    const ship1EventDetails =
      event.type === "opticalSTS" && event?.ship1_status
        ? getEventDetails(event?.ship1_status.toLowerCase() || "unknown")
        : getEventDetails(event.type || "unknown");

    const ship2EventDetails =
      event.type === "opticalSTS" && event?.ship2_status
        ? getEventDetails(event?.ship2_status.toLowerCase() || "unknown")
        : getEventDetails(event.type || "unknown");

    if (event.type === "opticalSTS" || event.type === "AISSTS") {
      const shipId1 = event.synmax_ship_id_1;
      const shipId2 = event.synmax_ship_id_2;
      const ship1Event =
        shipId1 &&
        (event as unknown as { [key: string]: ShipDetailsInterFace })[shipId1];
      const ship2Event =
        shipId2 &&
        (event as unknown as { [key: string]: ShipDetailsInterFace })[shipId2];

      return {
        id: event.synmax_ship_id,
        event: (
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ color: ship1EventDetails.color }}>
              Bunkering {event?.ship1_status ? `(${event?.ship1_status})` : ""}
            </span>
            <span style={{ color: ship2EventDetails.color }}>
              Bunkering {event?.ship2_status ? `(${event?.ship2_status})` : ""}
            </span>
          </Box>
        ),
        ship: (
          <Box style={{ display: "flex", flexDirection: "column" }}>
            {ship1Event && (
              <span>
                {!ship1Event?.name ? ship1Event?.object_id : ship1Event?.name}
              </span>
            )}
            {ship2Event && (
              <span>
                {!ship2Event?.name ? ship2Event?.object_id : ship2Event?.name}
              </span>
            )}
          </Box>
        ),
        flag: (
          <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
            {ship1Event && ship1Event.flag ? (
              <CountryFlagIcon
                size={15}
                countryCode={ship1Event.flag.toLowerCase()}
              />
            ) : (
              "---"
            )}
            {ship2Event && ship2Event.flag ? (
              <CountryFlagIcon
                size={15}
                countryCode={ship2Event.flag.toLowerCase()}
              />
            ) : (
              "---"
            )}
          </Box>
        ),
        date: null,
      };
    } else {
      return {
        id: event.synmax_ship_id,
        event: (
          <span style={{ color: ship1EventDetails.color }}>
            {ship1EventDetails.name}
          </span>
        ),
        ship: event.name,
        flag: event.flag && (
          <CountryFlagIcon size={15} countryCode={event.flag.toLowerCase()} />
        ),
        date: null,
      };
    }
  });

  const recentRows = Object.values(events)
    .reverse()
    .filter((event) => events[event.synmax_ship_id])
    .map((event) => {
      const ship1EventDetails =
        event.type === "opticalSTS" && event?.ship1_status
          ? getEventDetails(event?.ship1_status.toLowerCase() || "unknown")
          : getEventDetails(event.type || "unknown");

      const ship2EventDetails =
        event.type === "opticalSTS" && event?.ship2_status
          ? getEventDetails(event?.ship2_status.toLowerCase() || "unknown")
          : getEventDetails(event.type || "unknown");
      if (event.type === "opticalSTS" || event.type === "AISSTS") {
        const shipId1 = event.synmax_ship_id_1;
        const shipId2 = event.synmax_ship_id_2;
        const ship1Event =
          shipId1 &&
          (event as unknown as { [key: string]: ShipDetailsInterFace })[
            shipId1
          ];
        const ship2Event =
          shipId2 &&
          (event as unknown as { [key: string]: ShipDetailsInterFace })[
            shipId2
          ];

        return {
          id: event.synmax_ship_id,
          event: (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ color: ship1EventDetails.color }}>
                Bunkering{" "}
                {event?.ship1_status ? `(${event?.ship1_status})` : ""}
              </span>
              <span style={{ color: ship2EventDetails.color }}>
                Bunkering{" "}
                {event?.ship2_status ? `(${event?.ship2_status})` : ""}
              </span>
            </Box>
          ),
          ship: (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              {ship1Event && (
                <span>
                  {!ship1Event?.name ? ship1Event?.object_id : ship1Event?.name}
                </span>
              )}
              {ship2Event && (
                <span>
                  {!ship2Event?.name ? ship2Event?.object_id : ship2Event?.name}
                </span>
              )}
            </Box>
          ),
          flag: (
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "2px" }}
            >
              {ship1Event && ship1Event.flag ? (
                <CountryFlagIcon
                  size={15}
                  countryCode={ship1Event.flag.toLowerCase()}
                />
              ) : (
                "---"
              )}
              {ship2Event && ship2Event.flag ? (
                <CountryFlagIcon
                  size={15}
                  countryCode={ship2Event.flag.toLowerCase()}
                />
              ) : (
                "---"
              )}
            </Box>
          ),
          date: null,
        };
      } else {
        return {
          id: event.synmax_ship_id,
          event: (
            <span style={{ color: ship1EventDetails.color }}>
              {ship1EventDetails.name}
            </span>
          ),
          ship: events[event.synmax_ship_id].name,
          flag: events[event.synmax_ship_id].flag && (
            <CountryFlagIcon
              size={15}
              countryCode={events[event?.synmax_ship_id]?.flag?.toLowerCase()!}
            />
          ),
          date: null,
        };
      }
    });

  const tabData = {
    "1": rows,
    "2": recentRows,
  };

  return (
    <TabsWithTable
      columns={columns}
      tabLabels={["My Events", "Recently Viewed"]}
      tabData={tabData}
      handleRowClick={handleTableRowClick}
      type="event"
    />
  );
};
export default MyEvents;
