import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PolygonLayer } from "@deck.gl/layers/typed";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/store";
import {
  fetchFuturePathData,
  fetchFuturePathPredictionData,
  fetchFuturePathProgressData,
  setFuturePathIntialValue,
  setIntervalId,
  setLayersFuturePath,
  setPathCluster,
} from "./FuturePathPrediction.store";
import FuturePathPredictionModal from "./FuturePathPredictionModal/FuturePathPredictionModal";
import FuturePathPredictionSlider from "./FuturePathPredictionSlider/FuturePathPredictionSlider";
import Loader from "../../../../components/ShipTools/Loader/Loader";
import {
  AllDetailsTabs,
  BunkeringInterface,
} from "../../../../context/useShipDetails";

export interface FuturePathParams {
  action?: string;
  lat?: number;
  lon?: number;
  speed?: number;
  length?: number;
  heading?: number;
  ship_type?: string;
}

export type OpenType =
  | "editFuturePath"
  | "closeModal"
  | "futurePathLoader"
  | "futurePathSlider";

const FuturePathPrediction = ({
  shipDetailTabValue,
  shipDetailsTabs,
  bunkeringValue,
}: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const { openPathToolModal } = useSelector(
    (state: RootState) => state.mainMap,
  );
  const {
    futurePathPredictionData,
    futurePathProgressData,
    futurePathData,
    intervalId,
  } = useSelector((state: RootState) => state.futurePathPrediction);

  const [futurePathParams, setFuturePathParams] = useState<FuturePathParams>(
    {},
  );
  const [open, setOpen] = useState<OpenType>("closeModal");
  const [EditFuturePathParams, setEditFuturePathParams] =
    useState<boolean>(false);

  useEffect(() => {
    if (openPathToolModal.futurePrediction) {
      setOpen("editFuturePath");
    } else {
      clearInterval(intervalId);
      setOpen("closeModal");
    }
  }, [openPathToolModal.futurePathPrediction]);

  useEffect(() => {
    if (shipDetailsTabs.length > 0) {
      shipDetailsTabs.map((detailTab: AllDetailsTabs | BunkeringInterface) => {
        if (detailTab.synmax_ship_id === shipDetailTabValue) {
          const isBunkering =
            detailTab.type === "AISSTS" || detailTab.type === "opticalSTS";

          const id =
            bunkeringValue === "2"
              ? detailTab?.synmax_ship_id_2
              : detailTab?.synmax_ship_id_1;

          const tab: AllDetailsTabs =
            isBunkering &&
            detailTab.hasOwnProperty("synmax_ship_id_1") &&
            detailTab.hasOwnProperty("synmax_ship_id_2") &&
            id
              ? (detailTab as BunkeringInterface)[id]
              : detailTab;

          const speed =
            Array.isArray(tab?.speeds) && tab.speeds.length > 0
              ? tab.speeds.reduce((a, c) => a + c, 0) / tab.speeds.length
              : 0;

          setFuturePathParams({
            action: "start",
            heading: tab.heading,
            lat: tab.latitude,
            length: tab.length,
            lon: tab.longitude,
            ship_type: tab.ship_type,
            speed: parseFloat(Math.floor(speed).toFixed(2)),
          });
        }
      });
    }
  }, [shipDetailsTabs]);

  useEffect(() => {
    if (futurePathPredictionData.success) {
      const intervalInstance = setInterval(() => {
        dispatch(
          fetchFuturePathProgressData({
            action: "progress",
            task_id: futurePathPredictionData.task_id,
          }),
        );
      }, 10000);
      dispatch(setIntervalId(intervalInstance));
    }
  }, [futurePathPredictionData]);

  useEffect(() => {
    if (futurePathProgressData.status === "Finished") handleFuturePathLayers();
  }, [futurePathProgressData]);

  useEffect(() => {
    if (futurePathData?.clusters?.length > 0)
      dispatch(setPathCluster(futurePathData.clusters));
  }, [futurePathData]);

  useEffect(() => {
    if (futurePathData?.futures?.length > 0) getPolygonLayers();
  }, [futurePathData]);

  const getPolygonLayers = () => {
    let finalLayers: any = [];
    futurePathData.futures.length &&
      futurePathData.futures.map((slider: any) => {
        let layers: any = [];
        slider.clusters.map((cluster: any, i: any) => {
          const { probability, wkt } = cluster;
          const wktString: any = wkt[0];
          const regex = /(-?\d+\.\d+)\s(-?\d+\.\d+)/g;
          const matches = [...wktString.matchAll(regex)];
          const coordinatesArray = matches.map((match) => [
            parseFloat(match[2]),
            parseFloat(match[1]),
          ]);

          const newDatapoints: any = [];
          coordinatesArray.forEach((point: any) =>
            newDatapoints.push([point[1], point[0]]),
          );
          layers = [
            ...layers,
            new PolygonLayer({
              id: "PolygonLayer",
              data: [{ data: newDatapoints }],
              getPolygon: (d) => d.data,
              getElevation: 10,
              getFillColor: [255, 0, 0],
              getLineColor: [0, 0, 0],
              getLineWidth: 20,
              lineWidthMinPixels: 1,
              pickable: true,
              opacity: probability,
            }),
          ];
        });
        finalLayers.push(layers);
      });
    dispatch(setLayersFuturePath(finalLayers));
    setOpen("futurePathSlider");
  };

  const handleSubmit = () => {
    dispatch(setFuturePathIntialValue());
    dispatch(fetchFuturePathPredictionData({ shipData: futurePathParams }));
  };

  const handleFuturePathLayers = async () => {
    clearInterval(intervalId);
    await dispatch(
      fetchFuturePathData({
        action: "result",
        task_id: futurePathPredictionData.task_id,
      }),
    );
  };

  return (
    <>
      <FuturePathPredictionModal
        futurePathParams={futurePathParams}
        setFuturePathParams={setFuturePathParams}
        open={open}
        setOpen={setOpen}
        EditFuturePathParams={EditFuturePathParams}
        setEditFuturePathParams={setEditFuturePathParams}
        handleSubmit={handleSubmit}
      />
      {open === "futurePathLoader" && (
        <Loader
          handleCancelButtonClick={() => {
            clearInterval(intervalId);
            setOpen("editFuturePath");
          }}
          loadingMessage={futurePathProgressData?.message || "Loading  ...."}
          loaderProgress={futurePathProgressData?.progress || 0}
        />
      )}
      {open === "futurePathSlider" && (
        <FuturePathPredictionSlider
          maxValue={futurePathData?.futures?.length}
          minValue={0}
        />
      )}
    </>
  );
};

export default FuturePathPrediction;
