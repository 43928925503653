import React, { useState } from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  updateWorkStationData,
  deleteWorkStationData,
} from "../../common/Common.store";
import {
  addTabData,
  setIsDashboard,
  setTabValue,
} from "../../layout/Header/Header.store";
import { setIsRecent } from "../../layout/HomePanel/HomePanel.store";
import { setIsShared } from "../Shared/Shared.store";
import { addNotificationData } from "../../layout/Header/Notification/Notification.store";
import WorkCard from "../../layout/WorkCard/WorkCard";
import CreateEditWorkStationDialog, {
  WorkStationData,
} from "../Theia/Dialog/CreateEditWorkStationDialog";
import DeleteDialog from "../../layout/Dialog/DeleteDialog";
import {
  findFolderId,
  findWorkStationInTabs,
  getUserByEmail,
  onDragStart,
} from "../../common/Common";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  GridView,
  FormatListBulleted,
  KeyboardArrowDown,
} from "@mui/icons-material";
import styles from "./Recent.module.scss";
import useFireBaseAuth from "../../context/useFireBaseAuth";
import { useSnackbar } from "../../context/SnackbarContext";

const Recent = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();

  const { currentUser } = useFireBaseAuth();
  const { tabs } = useSelector((state: RootState) => state.header);
  const { searchText } = useSelector((state: RootState) => state.homePanel);
  const { users } = useSelector((state: RootState) => state.common);
  const { allProjects } = useSelector((state: RootState) => state.common);
  const { setNewSnackBar } = useSnackbar();

  const userId = currentUser.uid;

  const [workStationName, setWorkStationName] = useState<string>("");
  const [workStationId, setWorkStationId] = useState<string>("");
  const [folderId, setFolderId] = useState<string>("");
  const [isEditWorkStation, setIsEditWorkStation] = useState<boolean>(false);
  const [isDeleteWorkStation, setIsDeleteWorkStation] =
    useState<boolean>(false);
  const [isShareWorkStation, setIsShareWorkStation] = useState<boolean>(false);
  const [workView, setWorkView] = useState<string>("Last Viewed");

  const handleChange = (event: SelectChangeEvent) => {
    setWorkView(event.target.value as string);
  };

  const editWorkStation = async (workStationData: WorkStationData | string) => {
    const { workStationName, workStationMode } =
      workStationData as WorkStationData;
    for (const key in tabs) {
      if (
        tabs[key].id === allProjects[folderId].workStations[workStationId].tabId
      ) {
        const data = {
          userId,
          folderId: folderId,
          workStationId,
          newWorkStationName: workStationName,
          tabId: key,
        };
        dispatch(updateWorkStationData(data));
        return;
      }
    }
    const data = {
      userId,
      folderId,
      workStationId,
      newWorkStationName: workStationName,
      newWorkStationMode: workStationMode,
    };
    dispatch(updateWorkStationData(data));
    setNewSnackBar({ message: "Work Station Updated", severity: "success" });
  };

  const deleteWorkStation = async () => {
    for (const key in tabs) {
      if (
        tabs[key].id === allProjects[folderId].workStations[workStationId].tabId
      ) {
        const data = {
          userId,
          folderId: folderId,
          workStationId,
          tabId: key,
        };
        dispatch(deleteWorkStationData(data));
        return;
      }
    }
    const data = {
      userId,
      folderId,
      workStationId,
    };
    dispatch(deleteWorkStationData(data));
    setNewSnackBar({ message: "Work Station Deleted", severity: "error" });
  };

  const shareWorkStation = async (userEmail: string | WorkStationData) => {
    const emailUserId = await getUserByEmail(users, userEmail.toString());
    const shareWorkData = {
      userId: emailUserId as string,
      workStationName,
      sharedUserId: userId,
      type: "share",
    };
    dispatch(addNotificationData(shareWorkData));
    setNewSnackBar({ message: "Work Station Shared", severity: "success" });
  };

  const addTabList = (name: string, workId: string) => {
    const workFolderId = findFolderId(allProjects, workId);
    const workStationData = findWorkStationInTabs(workId, tabs);
    const updateDatedata = {
      userId,
      folderId: workFolderId,
      workStationId: workId,
      isWorkStationDate: true,
    };

    if (workStationData) {
      dispatch(setTabValue(workStationData.value));
    } else {
      const maxTabValue =
        Object.keys(tabs).length > 0
          ? Math.max(...Object.values(tabs).map((item) => item.value)) + 1
          : 0;
      const newTab = {
        userId,
        tabName: name,
        tabValue: maxTabValue,
        folderId: workFolderId as string,
        workStationId: workId,
      };
      dispatch(addTabData(newTab));
      dispatch(setTabValue(maxTabValue));
    }
    dispatch(setIsDashboard(false));
    dispatch(setIsRecent(false));
    dispatch(setIsShared(false));
    dispatch(updateWorkStationData(updateDatedata));
  };

  return (
    <Box display="flex" flexDirection="column" className={styles.recentMain}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        top="0"
        className={styles.recentWrap}
      >
        <Typography variant="h6" className={styles.recentHead}>
          Recent
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          gap="20px"
          className={styles.viewedByWrap}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            className={styles.lastViewedWrap}
          >
            <FormControl
              className={styles.inputField}
              variant="standard"
              fullWidth
            >
              <Select
                className={styles.lastViewed}
                id="work-view"
                value={workView}
                onChange={handleChange}
                IconComponent={KeyboardArrowDown}
              >
                <MenuItem value="Last Viewed">Last Viewed</MenuItem>
                <MenuItem value="Alphabetical">Alphabetical</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <GridView className={styles.gridIcon} />
          <FormatListBulleted className={styles.listIcon} />
        </Box>
      </Box>
      {!allProjects.msg && Object.keys(allProjects).length > 0 ? (
        <Box className={styles.recentDashbordMain}>
          <Box
            sx={{
              gridTemplateColumns: {
                xl: "repeat(4,1fr)",
                lg: "repeat(3,1fr)",
                md: "repeat(2,1fr)",
              },
            }}
            display="grid"
            gridTemplateColumns="repeat(3,1fr)"
            gap={3}
            className={styles.recentDashbord}
          >
            {Object.keys(allProjects)
              .flatMap((data: string) => {
                return Object.keys(allProjects[data].workStations)
                  .filter((work: string) =>
                    allProjects[data].workStations[work].name
                      .toLowerCase()
                      .includes(searchText.toLowerCase()),
                  )
                  .map((work: string) => ({
                    folderId: data,
                    workId: work,
                    workStationData: allProjects[data].workStations[work],
                  }));
              })
              .sort((a, b) => {
                if (workView === "Last Viewed") {
                  // Sort by createdAt in descending order
                  const timeA = new Date(
                    a.workStationData.createdAt?._seconds
                      ? a.workStationData.createdAt?._seconds * 1000 +
                        a.workStationData.createdAt?._nanoseconds / 1000000
                      : a.workStationData.createdAt,
                  ).getTime();
                  const timeB = new Date(
                    b.workStationData.createdAt?._seconds
                      ? b.workStationData.createdAt?._seconds * 1000 +
                        b.workStationData.createdAt?._nanoseconds / 1000000
                      : b.workStationData.createdAt,
                  ).getTime();
                  return timeB - timeA;
                } else {
                  // Sort by workStation name in ascending order
                  const nameA = a.workStationData.name.toLowerCase();
                  const nameB = b.workStationData.name.toLowerCase();
                  return nameA.localeCompare(nameB);
                }
              })
              .map(({ folderId, workId, workStationData }) => (
                <Box
                  key={workId}
                  onDragStart={(e) => onDragStart(e, workStationData.name)}
                  draggable
                >
                  <WorkCard
                    key={workId}
                    work={workId}
                    workStationName={workStationData.name}
                    thumbnail={workStationData.thumbnail}
                    setWorkStationName={setWorkStationName}
                    setWorkStationId={setWorkStationId}
                    setIsEditWorkStation={setIsEditWorkStation}
                    setIsDeleteWorkStation={setIsDeleteWorkStation}
                    setIsShareWorkStation={setIsShareWorkStation}
                    folderId={folderId}
                    setFolderId={setFolderId}
                    addTabList={addTabList}
                  />
                </Box>
              ))}
          </Box>
        </Box>
      ) : (
        <Typography variant="h6" className={styles.CardAddText}>
          Please create folder first
        </Typography>
      )}
      {isEditWorkStation && (
        <CreateEditWorkStationDialog
          open={isEditWorkStation}
          setOpen={setIsEditWorkStation}
          name="work_station_name"
          label="Work Station Name"
          title="Edit Work Station Name"
          buttonName="Change"
          addEditFolder={editWorkStation}
          oldWorkStationName={workStationName}
        />
      )}
      {isDeleteWorkStation && (
        <DeleteDialog
          open={isDeleteWorkStation}
          setOpen={setIsDeleteWorkStation}
          deleteFolderWorkStation={deleteWorkStation}
        />
      )}
      {isShareWorkStation && (
        <CreateEditWorkStationDialog
          open={isShareWorkStation}
          setOpen={setIsShareWorkStation}
          name="user_email"
          label="Enter User Email"
          title="User Email"
          buttonName="Send"
          addEditFolder={shareWorkStation}
        />
      )}
    </Box>
  );
};

export default Recent;
