export const daboDelgado = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [40.8799816164999, -10.15343644320549, 0],
            [40.31498362799353, -10.32668840034827, 0],
            [40.32575904673899, -10.35911317026805, 0],
            [40.33275738949688, -10.36140645484449, 0],
            [40.35083697764149, -10.35796708851828, 0],
            [40.3747518075322, -10.35739467317741, 0],
            [40.39341830877213, -10.36485017992413, 0],
            [40.41091432077955, -10.3608284429685, 0],
            [40.41440566304313, -10.33443479468928, 0],
            [40.38465933365567, -10.32239407637501, 0],
            [40.40039657347706, -10.2965713740287, 0],
            [40.41556828863747, -10.31148566973566, 0],
            [40.4278159564297, -10.32754527403161, 0],
            [40.43482992575066, -10.33844905513673, 0],
            [40.44358558453103, -10.34648068263663, 0],
            [40.44242490257404, -10.36254754848973, 0],
            [40.43018226725717, -10.37288368420609, 0],
            [40.42143576115688, -10.39010017827704, 0],
            [40.42085963656966, -10.41247929920279, 0],
            [40.41737035839926, -10.42970419919785, 0],
            [40.42204921150744, -10.44922112135208, 0],
            [40.42789736059215, -10.46185872187402, 0],
            [40.4471630107999, -10.47629605994413, 0],
            [40.47870098553131, -10.48315134275843, 0],
            [40.50147795976619, -10.48600056779651, 0],
            [40.51199182125308, -10.48426652253616, 0],
            [40.52309283964767, -10.48425255183554, 0],
            [40.52951883791864, -10.50549639635775, 0],
            [40.54178574203594, -10.52673361172809, 0],
            [40.54355421168562, -10.54165196901156, 0],
            [40.53127861052925, -10.55372422079963, 0],
            [40.51785212731871, -10.5640669061215, 0],
            [40.51785905753651, -10.5772681090793, 0],
            [40.5166850678569, -10.58760276934958, 0],
            [40.53714524481687, -10.57839928875093, 0],
            [40.56345938421997, -10.59042987442212, 0],
            [40.57514254752811, -10.60017767034005, 0],
            [40.5798280684773, -10.60476649112606, 0],
            [40.57983269506354, -10.60821069760412, 0],
            [40.5792521753675, -10.61165566646738, 0],
            [40.57808863048506, -10.61395272931922, 0],
            [40.57633840877659, -10.61567639295103, 0],
            [40.57400319573314, -10.61797493204695, 0],
            [40.57283386077344, -10.62027242800401, 0],
            [40.57049225524197, -10.62314285921957, 0],
            [40.56991217150895, -10.62486744959678, 0],
            [40.56874410421331, -10.62773875273374, 0],
            [40.56874676750032, -10.63003502731091, 0],
            [40.56875139130404, -10.63290504870566, 0],
            [40.56875615868099, -10.63462720160313, 0],
            [40.56875353882214, -10.63807191715862, 0],
            [40.56875506003306, -10.64094218376846, 0],
            [40.56875688369532, -10.64438645707039, 0],
            [40.56875782384716, -10.64610860720215, 0],
            [40.56934378587208, -10.64955328420103, 0],
            [40.56934679008088, -10.65242256138263, 0],
            [40.57051735383808, -10.65529236890265, 0],
            [40.5711048423824, -10.65758795860889, 0],
            [40.57286159853189, -10.65930906835533, 0],
            [40.57461835985646, -10.6616041966584, 0],
            [40.57637515082778, -10.66389936751366, 0],
            [40.57871787931668, -10.66504627121208, 0],
            [40.58047502164086, -10.66561947842859, 0],
            [40.58398870029691, -10.66619177618285, 0],
            [40.58808140617907, -10.66676273698717, 0],
            [40.59042018326227, -10.66676099962668, 0],
            [40.59275893641811, -10.66733324978946, 0],
            [40.59627031783574, -10.66733102591641, 0],
            [40.5980261369811, -10.66790395791311, 0],
            [40.60036667861728, -10.66905051921947, 0],
            [40.60388032083532, -10.66904848632137, 0],
            [40.60622335992542, -10.67019397165444, 0],
            [40.61032003464938, -10.6707661623894, 0],
            [40.61324633521139, -10.6713385194341, 0],
            [40.61558881069323, -10.67133705989161, 0],
            [40.61792943863816, -10.6724835632447, 0],
            [40.62027113036213, -10.67305610721371, 0],
            [40.62319754340749, -10.67420225715306, 0],
            [40.62495313046924, -10.67477488071656, 0],
            [40.62787592537408, -10.67650776605865, 0],
            [40.62964134072435, -10.67821754450441, 0],
            [40.6314093035033, -10.67994064916157, 0],
            [40.63317996480654, -10.68223854023001, 0],
            [40.63494398491135, -10.68453544676962, 0],
            [40.63553082597296, -10.68625789855471, 0],
            [40.63611841096414, -10.68912891105435, 0],
            [40.63612119141555, -10.69257466127296, 0],
            [40.63612612866974, -10.69602084970305, 0],
            [40.63493987496283, -10.69831497614923, 0],
            [40.63260544030693, -10.70004056596421, 0],
            [40.63026687905824, -10.7006168878104, 0],
            [40.62792831602665, -10.70119316824582, 0],
            [40.62500228667169, -10.70119517183222, 0],
            [40.62149197113304, -10.70119770053423, 0],
            [40.61973528508157, -10.70062445047314, 0],
            [40.61563550485701, -10.70005210272057, 0],
            [40.61328625964894, -10.69890330856832, 0],
            [40.61094179130524, -10.69832975841486, 0],
            [40.60859743537792, -10.69775621482174, 0],
            [40.60625320181273, -10.69718266067003, 0],
            [40.60390816862174, -10.6966088320882, 0],
            [40.60156331773737, -10.69603520258471, 0],
            [40.59922578179594, -10.6960372687866, 0],
            [40.59571954515812, -10.69604031197099, 0],
            [40.59279770772969, -10.6960428113729, 0],
            [40.58929042302513, -10.69547150745426, 0],
            [40.58753367142346, -10.69432398458858, 0],
            [40.58460567177528, -10.69260286042001, 0],
            [40.58284950808827, -10.69202935428737, 0],
            [40.57933829916893, -10.69203095096206, 0],
            [40.5769975815051, -10.69203198715378, 0],
            [40.57466156617384, -10.69375618654019, 0],
            [40.57290948672486, -10.69490579227342, 0],
            [40.5705724025721, -10.69605563515169, 0],
            [40.56765326413932, -10.69835450617474, 0],
            [40.56531470304321, -10.69892990642759, 0],
            [40.56356409516273, -10.70065382773937, 0],
            [40.56064125591936, -10.70237781531769, 0],
            [40.55830347040234, -10.70410169089995, 0],
            [40.55538084815789, -10.70582451334529, 0],
            [40.55304295361669, -10.70754780485947, 0],
            [40.5501208151915, -10.70984535536561, 0],
            [40.54836818889612, -10.71156846311102, 0],
            [40.54544400027524, -10.71271727671878, 0],
            [40.54369135218258, -10.71444032423314, 0],
            [40.54193777517066, -10.71558898667813, 0],
            [40.53959995142343, -10.71731192957174, 0],
            [40.53667500114896, -10.71960782113374, 0],
            [40.53374915891371, -10.72132929020396, 0],
            [40.53082328340064, -10.72362443703924, 0],
            [40.52965322215725, -10.72362449062716, 0],
            [40.52673696900025, -10.72649763082776, 0],
            [40.52381777479495, -10.72764808109095, 0],
            [40.52206477758978, -10.72822286144402, 0],
            [40.51914289602761, -10.72994513072926, 0],
            [40.51680742850188, -10.7316693482854, 0],
            [40.51505854562117, -10.73454095514242, 0],
            [40.51506356026089, -10.73741281044914, 0],
            [40.5150695232419, -10.74085931464805, 0],
            [40.51448879718376, -10.74315702809761, 0],
            [40.51215329586908, -10.74545490189343, 0],
            [40.51098652985291, -10.74775192585104, 0],
            [40.50923510250664, -10.75004903775959, 0],
            [40.5086510370154, -10.75062332801861, 0],
            [40.50689875406135, -10.75234612509204, 0],
            [40.50456003955969, -10.75234619917229, 0],
            [40.50046865963402, -10.75464295026831, 0],
            [40.49871500955658, -10.75579086571684, 0],
            [40.49579199113799, -10.7575125614251, 0],
            [40.495207941055, -10.75923422974975, 0],
            [40.49521198853407, -10.76210609505239, 0],
            [40.49521692624515, -10.76555244083492, 0],
            [40.49638991647414, -10.76727655266328, 0],
            [40.49639008512379, -10.76957160608661, 0],
            [40.49814522767952, -10.77301489595934, 0],
            [40.49990037881917, -10.77588432200308, 0],
            [40.50165548912346, -10.77818001481285, 0],
            [40.50341064400176, -10.77990198377516, 0],
            [40.50516581407192, -10.78162393878257, 0],
            [40.50809108401334, -10.7827725804519, 0],
            [40.50984632742414, -10.78449457480964, 0],
            [40.51394198971032, -10.78621746825564, 0],
            [40.51686756063631, -10.78736606094447, 0],
            [40.51803822774774, -10.78794049023885, 0],
            [40.52213268103765, -10.78908849616502, 0],
            [40.52447120373296, -10.78966150878468, 0],
            [40.5291552936098, -10.79081286782903, 0],
            [40.53149857339219, -10.79253702680276, 0],
            [40.5350137013527, -10.79311502491525, 0],
            [40.5367720291275, -10.79369159504925, 0],
            [40.54087577856691, -10.79599368487616, 0],
            [40.54146155263903, -10.79656823008103, 0],
            [40.54614629698846, -10.7982928988678, 0],
            [40.5502454327563, -10.80001653537045, 0],
            [40.55317398942777, -10.80116658851865, 0],
            [40.55551749446492, -10.80231648768157, 0],
            [40.55786111286297, -10.80346643047399, 0],
            [40.56137759362003, -10.8057659589258, 0],
            [40.56313476444002, -10.80634117284764, 0],
            [40.56489551852743, -10.80921445594911, 0],
            [40.56782446812215, -10.81036439794948, 0],
            [40.56958459308861, -10.81266314945087, 0],
            [40.57192819730931, -10.81438661700519, 0],
            [40.5748572984082, -10.81668423228123, 0],
            [40.57720025378028, -10.81783329941472, 0],
            [40.57837213443607, -10.81898207285164, 0],
            [40.58130032044152, -10.81955696597945, 0],
            [40.58364379126024, -10.82070673685129, 0],
            [40.58657162142729, -10.82070650654938, 0],
            [40.5906726137018, -10.82185683351956, 0],
            [40.5930162370456, -10.8218580549536, 0],
            [40.59535996524156, -10.82185928529038, 0],
            [40.59887787167937, -10.82301152700639, 0],
            [40.60180527268527, -10.82301031891003, 0],
            [40.60473419579859, -10.8230110388675, 0],
            [40.60824976174559, -10.82358639994843, 0],
            [40.61059468795442, -10.82473618431129, 0],
            [40.61352912698157, -10.82588850546781, 0],
            [40.61411931334567, -10.82761383705223, 0],
            [40.61412461621941, -10.82991358109549, 0],
            [40.61354424845612, -10.83278825994722, 0],
            [40.61237553209936, -10.83451210994547, 0],
            [40.61120917083016, -10.83796019353244, 0],
            [40.61004005345413, -10.84025807555988, 0],
            [40.60887422362374, -10.84428055640215, 0],
            [40.60770401130563, -10.84600350431764, 0],
            [40.60653647415109, -10.84887681564555, 0],
            [40.60478639792224, -10.85060401607439, 0],
            [40.6030446541886, -10.8534863813462, 0],
            [40.60188030199868, -10.85521439498852, 0],
            [40.60071865308801, -10.85809265641946, 0],
            [40.59896851605062, -10.86096845247533, 0],
            [40.59721758541615, -10.86384384809052, 0],
            [40.59546246747669, -10.86499348210784, 0],
            [40.59370734321627, -10.86614304523249, 0],
            [40.59078305439895, -10.86844251364162, 0],
            [40.58785746216788, -10.87016679765334, 0],
            [40.58493163142817, -10.8718908476965, 0],
            [40.58141809359595, -10.87303851518612, 0],
            [40.57848759864869, -10.87418359808809, 0],
            [40.57555722598345, -10.87532859518555, 0],
            [40.57438636594272, -10.87647667783226, 0],
            [40.57145567680841, -10.87704720201475, 0],
            [40.56852866583134, -10.87876924428139, 0],
            [40.56618464931601, -10.87934072055441, 0],
            [40.56325959717071, -10.88049039285488, 0],
            [40.56091896111288, -10.8810647744629, 0],
            [40.55974570856572, -10.88106044639682, 0],
            [40.51806343435029, -10.90410479764969, 0],
            [40.50228637027734, -10.93166291872515, 0],
            [40.49470261614778, -10.95175325616404, 0],
            [40.49414884717756, -10.98618805718353, 0],
            [40.50175772657147, -11.00454728809843, 0],
            [40.53454690588196, -11.022904615034, 0],
            [40.55973116321118, -11.03093408319149, 0],
            [40.55860423930756, -11.05505756388266, 0],
            [40.53582627078526, -11.09699021510105, 0],
            [40.51010156116068, -11.12686230655676, 0],
            [40.48905604727015, -11.15443818923645, 0],
            [40.4896753067861, -11.17743435454926, 0],
            [40.50611325426596, -11.19642666927166, 0],
            [40.45963877943807, -11.21819201083941, 0],
            [40.42915659820184, -11.23812146880769, 0],
            [40.42252289626461, -11.25718206757267, 0],
            [40.41015181238127, -11.29185847791291, 0],
            [40.38807885450073, -11.3187242235058, 0],
            [40.37921503074623, -11.32822539135104, 0],
            [40.36994883247696, -11.32086085843325, 0],
            [40.35757560813731, -11.33211338268706, 0],
            [40.36817139534561, -11.35940992528211, 0],
            [40.38097121644348, -11.36677669633571, 0],
            [40.38625834522346, -11.35811146168721, 0],
            [40.40260602837196, -11.37588219130028, 0],
            [40.40921466084722, -11.39797229378252, 0],
            [40.46576674979592, -11.40622348416394, 0],
            [40.45250252226472, -11.43480110612505, 0],
            [40.44763275692217, -11.45645468574754, 0],
            [40.44850845329884, -11.4759452362887, 0],
            [40.43612103353114, -11.50063373504079, 0],
            [40.43832094754289, -11.51796772257178, 0],
            [40.43123763675993, -11.53356852914234, 0],
            [40.42105023138939, -11.55351573313212, 0],
            [40.4214944238503, -11.580143926284, 0],
            [40.43917523362637, -11.59921744870608, 0],
            [40.45774203312722, -11.6065864120141, 0],
            [40.4577383758368, -11.62001521829656, 0],
            [40.44004779205128, -11.6373354296457, 0],
            [40.43473511100651, -11.64729127289388, 0],
            [40.43871562680073, -11.65032647442459, 0],
            [40.43337318591149, -11.72654504344112, 0],
            [40.44310598112924, -11.74818312224174, 0],
            [40.45592122849619, -11.75210862633346, 0],
            [40.46254433733476, -11.78590136734895, 0],
            [40.46872479193512, -11.8270719259248, 0],
            [40.48642642594482, -11.84009088901134, 0],
            [40.51652985754524, -11.85571107449154, 0],
            [40.48995539354756, -11.87871036852594, 0],
            [40.48242416378114, -11.9025352875191, 0],
            [40.48640080170073, -11.92896004147692, 0],
            [40.49436234948991, -11.95624813461483, 0],
            [40.50586702539205, -11.97963656165665, 0],
            [40.55059016756029, -11.99869642602414, 0],
            [40.51604699388245, -12.02121516328074, 0],
            [40.50098603144167, -12.04026618692161, 0],
            [40.49876784233811, -12.06452107908915, 0],
            [40.51028026069573, -12.08271452949524, 0],
            [40.50230481351976, -12.10740798296767, 0],
            [40.49299933859116, -12.12343912513346, 0],
            [40.4841313314351, -12.14854052806748, 0],
            [40.52089803997407, -12.20654589619528, 0],
            [40.51779395594943, -12.21650804082411, 0],
            [40.50227866195584, -12.22907002783463, 0],
            [40.49341109074691, -12.24899029762532, 0],
            [40.48587227791671, -12.26761026998526, 0],
            [40.4987203061889, -12.29143157233787, 0],
            [40.51245866521524, -12.32868470905892, 0],
            [40.50404044551478, -12.33990041854228, 0],
            [40.49573879069458, -12.36745558760971, 0],
            [40.50320160391138, -12.38123291172134, 0],
            [40.51896665766485, -12.37150638423977, 0],
            [40.53887961261037, -12.34962183202658, 0],
            [40.57040953293767, -12.33178258940489, 0],
            [40.59696202965764, -12.32204641161341, 0],
            [40.61687407457639, -12.33014556584456, 0],
            [40.61686856909099, -12.35284610233051, 0],
            [40.60442518330784, -12.37797713944451, 0],
            [40.59778751432238, -12.39094614513735, 0],
            [40.56293401459081, -12.40635272409078, 0],
            [40.52642503462958, -12.42417998797518, 0],
            [40.5056786929305, -12.44038396261886, 0],
            [40.49239677475774, -12.46873924453342, 0],
            [40.48160228870218, -12.48655990578695, 0],
            [40.47496074501835, -12.50357423182663, 0],
            [40.49736018325462, -12.53436226763803, 0],
            [40.52060383124779, -12.53275435967195, 0],
            [40.54799862234656, -12.52708716882015, 0],
            [40.57373443338101, -12.53195143954154, 0],
            [40.57954763231479, -12.53600250642197, 0],
            [40.56875619659431, -12.54734773079625, 0],
            [40.55630381806513, -12.56274523718792, 0],
            [40.55713304672495, -12.58219121267341, 0],
            [40.56295089580226, -12.61785304133049, 0],
            [40.58288869233416, -12.63325764056321, 0],
            [40.59618563820118, -12.63488853811081, 0],
            [40.60698855896008, -12.63489262549735, 0],
            [40.61779664399452, -12.65516010997517, 0],
            [40.61614685786518, -12.67057223568645, 0],
            [40.58954442634824, -12.65677250341843, 0],
            [40.57791517642364, -12.65758438447009, 0],
            [40.56546071782194, -12.6746163534897, 0],
            [40.57045642582916, -12.69652333895005, 0],
            [40.57877183812518, -12.70626250360672, 0],
            [40.5912383547102, -12.70221246899568, 0],
            [40.60620802249117, -12.71357717054538, 0],
            [40.61453336267473, -12.73062007533512, 0],
            [40.61288072845494, -12.74603767930379, 0],
            [40.62951422785238, -12.74523686706397, 0],
            [40.62035399027272, -12.78298293377516, 0],
            [40.60122287814674, -12.8081451848675, 0],
            [40.59789245992152, -12.81059635916337, 0],
            [40.593741313423, -12.81301374784272, 0],
            [40.59041481739656, -12.81382686550266, 0],
            [40.58708925691467, -12.8146403612385, 0],
            [40.58376416623715, -12.81545154465686, 0],
            [40.58127030643205, -12.81626259087417, 0],
            [40.5779448438681, -12.81788672246657, 0],
            [40.57461737189946, -12.81950583721277, 0],
            [40.5721232724493, -12.82113197184477, 0],
            [40.56962851261873, -12.82437708923204, 0],
            [40.56713398924337, -12.82681111430619, 0],
            [40.56463943927884, -12.82924499273597, 0],
            [40.5613134140625, -12.83168012044832, 0],
            [40.55964937991147, -12.83330390943001, 0],
            [40.5571543698612, -12.83573870927912, 0],
            [40.55465917468644, -12.83898418847162, 0],
            [40.55216417690073, -12.84222957575174, 0],
            [40.54966964047727, -12.84628565605933, 0],
            [40.54884063840058, -12.85034182317952, 0],
            [40.54717245826471, -12.85439417143776, 0],
            [40.54634078097088, -12.85844700168268, 0],
            [40.54551199589423, -12.86331301904282, 0],
            [40.54468051523421, -12.867365572969, 0],
            [40.54384638396233, -12.869793905434, 0],
            [40.5430147726749, -12.87465635637328, 0],
            [40.54135208862103, -12.87871138563391, 0],
            [40.53968907991444, -12.8835747473399, 0],
            [40.53802606416699, -12.88762707485644, 0],
            [40.53719456950554, -12.89167883732814, 0],
            [40.53553147201055, -12.89411063029639, 0],
            [40.53303982894825, -12.89896939091767, 0],
            [40.53054511679022, -12.90221165197671, 0],
            [40.52888177064082, -12.90464357599725, 0],
            [40.52555241624297, -12.90789024052247, 0],
            [40.52388925278985, -12.91032177014306, 0],
            [40.52222895722618, -12.9135593248682, 0],
            [40.52056142694306, -12.91761688592565, 0],
            [40.51890075721609, -12.92085746362791, 0],
            [40.51640430443307, -12.92328825259077, 0],
            [40.51307735750992, -12.92572131609116, 0],
            [40.50974796871652, -12.9257223875606, 0],
            [40.50642507320379, -12.92653089074437, 0],
            [40.50559465494651, -12.9232885364068, 0],
            [40.50476372820111, -12.92004609819003, 0],
            [40.50393558197817, -12.91599276569849, 0],
            [40.50227344731804, -12.91032098194402, 0],
            [40.50227390416799, -12.90788970848936, 0],
            [40.50227463016482, -12.90464806844438, 0],
            [40.5022768925928, -12.90059379111514, 0],
            [40.50144638198994, -12.89654396486011, 0],
            [40.50061471378855, -12.89168257190835, 0],
            [40.49895283268963, -12.88763098215134, 0],
            [40.49562812525296, -12.88520074434509, 0],
            [40.49313478560838, -12.88358059068216, 0],
            [40.4906410876973, -12.88358003422647, 0],
            [40.48482245686946, -12.88357551210839, 0],
            [40.47900226809843, -12.88356898657865, 0],
            [40.47318523693359, -12.88357015710549, 0],
            [40.46986030465111, -12.88357166234429, 0],
            [40.46653492345105, -12.88519363374782, 0],
            [40.46237866515026, -12.88600561996627, 0],
            [40.46071575080432, -12.88762670105005, 0],
            [40.45739085038211, -12.88924592692859, 0],
            [40.45322724533638, -12.89167182001686, 0],
            [40.44824053935955, -12.89491272968079, 0],
            [40.44574909933618, -12.89896734441128, 0],
            [40.44158934805928, -12.90463480235987, 0],
            [40.43909432566072, -12.9070640016241, 0],
            [40.43659914161842, -12.90949311975087, 0],
            [40.4332721951496, -12.91273204094031, 0],
            [40.42911372489233, -12.91597118338923, 0],
            [40.42495612836616, -12.91840029861545, 0],
            [40.42163078306718, -12.91920971275273, 0],
            [40.41913582115661, -12.92164042021828, 0],
            [40.41747248445833, -12.92407157386614, 0],
            [40.41497785536885, -12.9273129660412, 0],
            [40.41414523939332, -12.93136516441161, 0],
            [40.41248141079757, -12.93541697410791, 0],
            [40.41164955711261, -12.93865782901779, 0],
            [40.41164731524284, -12.94351955592121, 0],
            [40.41164605782813, -12.9491908241568, 0],
            [40.41164129474614, -12.95243325183986, 0],
            [40.41247024077316, -12.95810541305789, 0],
            [40.41413115916274, -12.96134708131405, 0],
            [40.41579233695317, -12.96620940653234, 0],
            [40.41828403194678, -12.9710720225348, 0],
            [40.42077647807541, -12.97431397154697, 0],
            [40.42326867546725, -12.97836624174944, 0],
            [40.42409908146543, -12.98079743051542, 0],
            [40.42575983161959, -12.9856596996636, 0],
            [40.42825227054764, -12.98971197101465, 0],
            [40.42825081494264, -12.99376328528386, 0],
            [40.42824873891676, -12.99943505473331, 0],
            [40.42824726177308, -13.00348621510616, 0],
            [40.4282452557859, -13.00915734524665, 0],
            [40.42824398174487, -13.01158786384304, 0],
            [40.42907283085002, -13.01563917247715, 0],
            [40.42990265294844, -13.01807051943834, 0],
            [40.43156244095319, -13.02374288956117, 0],
            [40.43322364793299, -13.02698461226207, 0],
            [40.43571613483874, -13.03103700350764, 0],
            [40.43821210065916, -13.03265843543093, 0],
            [40.44320551630746, -13.03670779311858, 0],
            [40.44569843606584, -13.03833029323275, 0],
            [40.44985755404374, -13.03833066314663, 0],
            [40.45401414117912, -13.03833305367498, 0],
            [40.4581710514574, -13.03914523089195, 0],
            [40.46232756178079, -13.04157777820395, 0],
            [40.46648438671451, -13.04401039201143, 0],
            [40.46980968908413, -13.04725294195798, 0],
            [40.47396728517958, -13.05130566205016, 0],
            [40.47895685066627, -13.05130541009799, 0],
            [40.48727330154189, -13.04968424699658, 0],
            [40.48893775535306, -13.04563535407058, 0],
            [40.49143388255273, -13.04158597015507, 0],
            [40.4922663217023, -13.03915630121101, 0],
            [40.49476291376512, -13.034295929983, 0],
            [40.49476353947045, -13.03186551987508, 0],
            [40.49726020006927, -13.02457439353238, 0],
            [40.49892371076022, -13.02295431883029, 0],
            [40.50141907969684, -13.01971395459939, 0],
            [40.50391515676849, -13.0148526844238, 0],
            [40.50641022331099, -13.01161174645315, 0],
            [40.5080710041862, -13.00594241289158, 0],
            [40.50890635304862, -13.00188937698179, 0],
            [40.50890668991018, -12.99702733266261, 0],
            [40.50890978719345, -12.99297780491426, 0],
            [40.50807795207265, -12.98892559872406, 0],
            [40.50641480051709, -12.98568170783799, 0],
            [40.50474896399081, -12.98243840883073, 0],
            [40.50142696928192, -12.97920118123971, 0],
            [40.49893400285216, -12.97595980477327, 0],
            [40.49809517074317, -12.9743444501719, 0],
            [40.48064447659718, -12.96299080937261, 0],
            [40.50143317058455, -12.95003213181728, 0],
            [40.51140694129453, -12.95571149585692, 0],
            [40.51390020297347, -12.95652113531086, 0],
            [40.51722458926673, -12.96056987388412, 0],
            [40.5205517876397, -12.96219108072388, 0],
            [40.52387550731725, -12.96300380748317, 0],
            [40.52636861413269, -12.96300519226211, 0],
            [40.53052355237447, -12.96300741417992, 0],
            [40.533846841989, -12.96462994573945, 0],
            [40.53550554066648, -12.96788132169465, 0],
            [40.53966650233004, -12.96787094452035, 0],
            [40.54216088435714, -12.96786955131756, 0],
            [40.54632033897307, -12.9662476120132, 0],
            [40.54964884580486, -12.96543509828824, 0],
            [40.55297908775753, -12.963001014103, 0],
            [40.55547684121657, -12.9613779126124, 0],
            [40.55963724657521, -12.96137598278831, 0],
            [40.56296293350034, -12.96137686570413, 0],
            [40.57044672263508, -12.96218497292316, 0],
            [40.57377226575244, -12.96299532373244, 0],
            [40.57792956078389, -12.96299498595829, 0],
            [40.58208712159927, -12.96299459220704, 0],
            [40.58458089286921, -12.96542650730115, 0],
            [40.58540970688207, -12.96948087902967, 0],
            [40.58541045445789, -12.97677011837762, 0],
            [40.58541042923551, -12.97758016523136, 0],
            [40.58540586614659, -12.98325757592558, 0],
            [40.58374776102088, -12.98649348546833, 0],
            [40.58291453681733, -12.99054635755552, 0],
            [40.58209100733916, -12.99216082192219, 0],
            [40.57958602693723, -12.99703000082387, 0],
            [40.57792122051447, -13.0002713253458, 0],
            [40.57542564286125, -13.00432296988777, 0],
            [40.57376285414527, -13.00756389925611, 0],
            [40.57126751038141, -13.01242519995112, 0],
            [40.56877387468467, -13.01647716429366, 0],
            [40.56627897981754, -13.01971704084428, 0],
            [40.56295388769795, -13.02376823519647, 0],
            [40.56212402093485, -13.02781941249675, 0],
            [40.56129444571107, -13.03268121937223, 0],
            [40.56129634180555, -13.03673274529453, 0],
            [40.55880182981704, -13.04078421738795, 0],
            [40.55797182450212, -13.04726648996057, 0],
            [40.55630866943697, -13.05293838302875, 0],
            [40.55631011089407, -13.05942116483815, 0],
            [40.5563115390801, -13.06590426843762, 0],
            [40.55631198918925, -13.0707661606002, 0],
            [40.55381584541029, -13.07643764792908, 0],
            [40.55298393165749, -13.07967863660918, 0],
            [40.55215194566447, -13.08291966475911, 0],
            [40.55048796423132, -13.08940159067964, 0],
            [40.55048826704046, -13.09345323316348, 0],
            [40.5496560863046, -13.09507349717665, 0],
            [40.54965647322046, -13.09831498258417, 0],
            [40.54799099677292, -13.10479483658013, 0],
            [40.54798674405897, -13.10884027767331, 0],
            [40.54798081948601, -13.11450298825714, 0],
            [40.54797910325406, -13.11612076364477, 0],
            [40.54797567189959, -13.1193560244567, 0],
            [40.54797399718555, -13.12583378633694, 0],
            [40.54797444147214, -13.13069475391806, 0],
            [40.5479746781114, -13.13393543247361, 0],
            [40.54880836190171, -13.13555870221706, 0],
            [40.54964248019451, -13.14123353834129, 0],
            [40.55047780086212, -13.14853071940425, 0],
            [40.55047883930371, -13.15339365287881, 0],
            [40.55131374137799, -13.15826022403025, 0],
            [40.5513144727401, -13.16312290151365, 0],
            [40.55214851149698, -13.16636775086762, 0],
            [40.55298309835293, -13.17285451438515, 0],
            [40.55298373459588, -13.17771725526578, 0],
            [40.55298379156413, -13.17852772471515, 0],
            [40.55381882087059, -13.18501579602349, 0],
            [40.55465446269217, -13.19069531849051, 0],
            [40.55548990514455, -13.19556438797998, 0],
            [40.55549040554961, -13.19718619635155, 0],
            [40.55549268130111, -13.20448450155892, 0],
            [40.55549394527196, -13.20853934845901, 0],
            [40.55549501003814, -13.21178332965865, 0],
            [40.55716530811956, -13.21828017679969, 0],
            [40.55717210356966, -13.22478179041701, 0],
            [40.55800931055606, -13.23127811522782, 0],
            [40.55967163552252, -13.23775290176135, 0],
            [40.56050736799415, -13.24100283678451, 0],
            [40.56050908084331, -13.25073343470546, 0],
            [40.56051048698146, -13.25884247036655, 0],
            [40.56051183938391, -13.26614070138176, 0],
            [40.56134800000979, -13.27101389779092, 0],
            [40.56218304058969, -13.27426287729226, 0],
            [40.5621854844791, -13.27913362402711, 0],
            [40.56302166767578, -13.28481752077952, 0],
            [40.56302416678368, -13.28968883576778, 0],
            [40.56302619546375, -13.29374844852481, 0],
            [40.56302744748125, -13.29618432225018, 0],
            [40.56302990212659, -13.30105620960229, 0],
            [40.56386534586763, -13.30511752336571, 0],
            [40.56470059814505, -13.30836690920084, 0],
            [40.56470304656092, -13.31242608361494, 0],
            [40.56553792399873, -13.31729781193863, 0],
            [40.56804000673133, -13.32216958888491, 0],
            [40.56970824277218, -13.32541757836407, 0],
            [40.57137688039903, -13.32947726612599, 0],
            [40.5730453033846, -13.33272538651493, 0],
            [40.58297460076936, -13.36120204127733, 0],
            [40.5779776321424, -13.39604756580517, 0],
            [40.56214987040637, -13.41225496448215, 0],
            [40.53882906774264, -13.44061266035482, 0],
            [40.53549422806814, -13.47545418328333, 0],
            [40.52631869560408, -13.49342951939337, 0],
            [40.52648118117203, -13.49989376159965, 0],
            [40.82803731761832, -13.50044084582846, 0],
            [42.01111018880762, -13.50782032801501, 0],
            [41.95342715027589, -13.11851746218765, 0],
            [41.81111149391975, -12.50727099984072, 0],
            [41.73708948707544, -11.94897762845167, 0],
            [41.72192594076836, -11.44122580342971, 0],
            [41.80721259409528, -10.88016272741094, 0],
            [41.92241133279595, -10.16011651294383, 0],
            [40.8799816164999, -10.15343644320549, 0],
          ],
        ],
      },
      properties: { name: "Cabo Delgado ", tessellate: true },
    },
  ],
};
