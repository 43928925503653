import { Dispatch, SetStateAction, useCallback } from "react";
import { IMapState } from "../pages/Theia/WorkStations";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import {
  addFileToStorageData,
  setIsDeleteTabUpdated,
  setIsSwitchTabUpdated,
} from "../pages/Theia/MainMap.store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { updateAllProjects } from "../common/Common.store";
import { setChangeSaved } from "../pages/Theia/Theia.store";
import { api } from "../services/api";
import useSatelliteImageTimeline from "../pages/Theia/ShipDetails/hooks/useSatelliteImageTimeline";
import useShipDetails from "./useShipDetails";
import useCurrentMapState from "./useCurrentMapState";
import useGlobalFilters from "./useGlobalFilter";
import { changeLoaderState } from "../redux/globalState.store";
import dayjs from "dayjs";
import useFireBaseAuth from "./useFireBaseAuth";
import { useSnackbar } from "./SnackbarContext";

const dataURLToImage = (dataURL: any) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = dataURL;
  });
};

const mergeImages = async (dataURL1: any, dataURL2: any) => {
  try {
    const img1: any = await dataURLToImage(dataURL1);
    const img2: any = await dataURLToImage(dataURL2);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = Math.max(img1.width, img2.width);
    canvas.height = Math.max(img1.height, img2.height);

    ctx?.drawImage(img1, 0, 0);
    ctx?.drawImage(img2, 0, 0);

    return canvas.toDataURL("image/png");
  } catch (error) {
    console.error("deckCanvas Error merging images:", error);
  }
};

export const useFireBase = (
  features: { data: any; count: number },
  setFeatures: Dispatch<SetStateAction<{ data: any; count: number }>>,
) => {
  const { currentUser } = useFireBaseAuth();
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const { allProjects } = useSelector((state: RootState) => state.common);

  const saveFileToStorage = useCallback(
    ({
      dataURL,
      workStationId,
      folderId,
    }: {
      dataURL: string;
      workStationId: string;
      folderId: string;
    }) => {
      const res = fetch(dataURL)
        .then((res) => res.blob())
        .then(async (blob) => {
          const file = new File([blob], workStationId, {
            type: "image/png",
          });
          const formData = new FormData();
          formData.append("userId", currentUser.uid);
          formData.append("folderId", folderId);
          formData.append("workStationId", workStationId);

          const storage = getStorage();
          const storageRef = ref(storage, `map/${file.name}`);
          const snapshot = await uploadBytesResumable(storageRef, blob, {
            contentType: blob.type,
          });

          const downloadURL = await getDownloadURL(snapshot.ref);
          formData.append("downloadURL", downloadURL);

          const response = await dispatch(addFileToStorageData(formData));

          return response;
        });
      return res;
    },
    [currentUser.uid],
  );

  const {
    selectedShip,
    shipPaths,
    selectedEvent,
    collapsed,
    shipCollapsed,
    active,
    shipDetailTabValue,
  } = useShipDetails();

  const { satelliteImageTimeline } = useSatelliteImageTimeline();
  const { tabs, prevTabId, selectedTabId, isTabSwitch, isTabDelete } =
    useSelector((state: RootState) => state.header);

  const { date, setDate } = useGlobalFilters();

  const { setNewSnackBar } = useSnackbar();

  const {
    zoomLevel,
    mapLatLng,
    deckCanvas,
    mapSnapshot,
    setLoading,
    setMapViewState,
    mapRef,
  } = useCurrentMapState();

  const {
    setShipDetailTabValue,
    setActive,
    setSidebarCollapsed,
    fetchShips,
    setShipCollapsed,
    fetchEvents,
    fetchShipPaths,
    fetchSatellites,
  } = useShipDetails();

  const handleSetDataFromFirebase = async () => {
    dispatch(changeLoaderState(true));

    const { folderId, workStationId } = tabs[selectedTabId];

    const {
      mapLatLng,
      date: savedDate,
      zoomLevel,
      mySatellite,
      myEvents,
      myShips,
      sidebarTabIndex,
      sidebarDrawer,
      shipDetailsDrawer,
      polygonFeatures,
      activeTabId,
    } = allProjects[folderId].workStations[workStationId].mapState as IMapState;

    let polygonData = null;

    if (polygonFeatures !== null) {
      polygonData = JSON.parse(polygonFeatures);
    }

    if (polygonData !== null && Object.keys(polygonData).length > 0) {
      setFeatures(polygonData);
    }
    activeTabId && setShipDetailTabValue(activeTabId);
    setMapViewState({
      latitude: mapLatLng.lat,
      longitude: mapLatLng.lng,
      zoom: zoomLevel,
    });
    setActive(sidebarTabIndex);
    setSidebarCollapsed(sidebarDrawer);
    setShipCollapsed(shipDetailsDrawer);
    const shipTypesToInclude = ["light", "attributed", "sanctioned"];

    const shipPathsData = [
      ...myEvents.map((event: any) => ({
        synmax_ship_id: event.synmax_ship_id,
        pathColor: event?.path?.color,
        dateRange: {
          startDate: event?.path?.startDate,
          endDate: event?.path?.endDate,
        },
        analysis: false,
      })),
      ...myShips.flatMap((ship: any) => {
        if (ship.type === "opticalSTS" || ship.type === "AISSTS") {
          return [
            ship?.path && ship.synmax_ship_id_1
              ? {
                  synmax_ship_id: ship.synmax_ship_id_1,
                  pathColor: ship.path.color,
                  analysis: false,
                  dateRange: {
                    startDate: ship.path.startDate,
                    endDate: ship.path.endDate,
                  },
                }
              : null,
            ship?.path2 && ship.synmax_ship_id_2
              ? {
                  synmax_ship_id: ship.synmax_ship_id_2,
                  pathColor: ship.path2.color,
                  analysis: false,
                  dateRange: {
                    startDate: ship.path2.startDate,
                    endDate: ship.path2.endDate,
                  },
                }
              : null,
          ].filter(
            (
              item,
            ): item is {
              synmax_ship_id: string;
              pathColor: string;
              analysis: boolean;
              dateRange: { startDate: string; endDate: string };
            } => item !== null,
          );
        }
        if (shipTypesToInclude.includes(ship.type)) {
          return [ship.synmax_ship_id]
            .filter((id): id is string => id !== undefined)
            .map((id) => ({
              synmax_ship_id: id,
              pathColor: ship?.path?.color,
              analysis: false,
              dateRange: {
                startDate: ship?.path?.startDate,
                endDate: ship?.path?.endDate,
              },
            }));
        }
        return [];
      }),
    ];
    await fetchShips(myShips, activeTabId);
    await fetchEvents(myEvents, activeTabId);
    await fetchSatellites(mySatellite, activeTabId);
    await fetchShipPaths(shipPathsData);

    savedDate !== "" ? setDate(dayjs(savedDate)) : setDate(dayjs());
    dispatch(changeLoaderState(false));
  };

  const saveStateToFirebase = useCallback(async () => {
    const shipsArray = Object.values(selectedShip)
      .filter((ship) => !ship?.isObservationShip)
      .map((ship) => ({
        synmax_ship_id: ship.synmax_ship_id,
        object_id: ship.object_id || null,
        type: ship.type,
        name: ship.name,
        ...(shipPaths?.[ship.synmax_ship_id] && {
          path: {
            startDate: shipPaths[ship.synmax_ship_id].dateRange?.startDate,
            endDate: shipPaths[ship.synmax_ship_id].dateRange?.endDate,
            color: shipPaths[ship.synmax_ship_id].color,
          },
        }),

        heading: ship.heading,
        ...(ship.latitude && ship.longitude
          ? { lat: ship.latitude, lng: ship.longitude }
          : {}),
        ...(ship.type === "AISSTS"
          ? {
              synmax_ship_id_1: ship.synmax_ship_id_1,
              synmax_ship_id_2: ship.synmax_ship_id_2,
              ...(ship?.synmax_ship_id_1 &&
                shipPaths?.[ship.synmax_ship_id_1] && {
                  path: {
                    startDate:
                      shipPaths[ship.synmax_ship_id_1].dateRange?.startDate,
                    endDate:
                      shipPaths[ship.synmax_ship_id_1].dateRange?.endDate,
                    color: shipPaths[ship.synmax_ship_id_1].color,
                  },
                }),
              ...(ship?.synmax_ship_id_2 &&
                shipPaths?.[ship.synmax_ship_id_2] && {
                  path2: {
                    startDate:
                      shipPaths[ship.synmax_ship_id_2].dateRange?.startDate,
                    endDate:
                      shipPaths[ship.synmax_ship_id_2].dateRange?.endDate,
                    color: shipPaths[ship.synmax_ship_id_2].color,
                  },
                }),
            }
          : {}),
        ...(ship.type === "opticalSTS"
          ? {
              synmax_ship_id_1: ship.synmax_ship_id_1,
              synmax_ship_id_2: ship.synmax_ship_id_2,
              object_id_1: ship.object_id_1,
              object_id_2: ship.object_id_2,
              ship1_status: ship.ship1_status,
              ship2_status: ship.ship2_status,
              heading: ship.frontend_rotation,
              ...(ship?.synmax_ship_id_1 &&
                shipPaths?.[ship.synmax_ship_id_1] && {
                  path: {
                    startDate:
                      shipPaths[ship.synmax_ship_id_1].dateRange?.startDate,
                    endDate:
                      shipPaths[ship.synmax_ship_id_1].dateRange?.endDate,
                    color: shipPaths[ship.synmax_ship_id_1].color,
                  },
                }),
              ...(ship?.synmax_ship_id_2 &&
                shipPaths?.[ship.synmax_ship_id_2] && {
                  path2: {
                    startDate:
                      shipPaths[ship.synmax_ship_id_2].dateRange?.startDate,
                    endDate:
                      shipPaths[ship.synmax_ship_id_2].dateRange?.endDate,
                    color: shipPaths[ship.synmax_ship_id_2].color,
                  },
                }),
            }
          : {}),
      }));

    const eventsArray = Object.values(selectedEvent).map((event) => ({
      synmax_ship_id: event.synmax_ship_id,
      type: event.type,
      name: event.name,
      ...(shipPaths?.[event.synmax_ship_id] && {
        path: {
          startDate: shipPaths[event.synmax_ship_id].dateRange?.startDate,
          endDate: shipPaths[event.synmax_ship_id].dateRange?.endDate,
          color: shipPaths[event.synmax_ship_id].color,
        },
      }),
      ...(event.type === "spoofing" && event.latitude && event.longitude
        ? { lat: event.latitude, lng: event.longitude }
        : {}),
    }));
    const satelliteImagesArray = Object.values(satelliteImageTimeline).map(
      (image) => ({
        synmax_ship_id: image[0].id,
        name: image[0].name,
      }),
    );
    const currentTab = tabs[prevTabId ? prevTabId : selectedTabId];
    const { folderId, workStationId } = currentTab;

    setLoading(true);

    const mapState = {
      ...allProjects[folderId].workStations[workStationId].mapState,
      showGlobeView: false,
      zoomLevel,
      mapLatLng,
      date: date?.format("YYYY-MM-DD"),
      myShips: shipsArray,
      myEvents: eventsArray,
      mySatellite: satelliteImagesArray,
      sidebarTabIndex: active,
      sidebarDrawer: collapsed,
      shipDetailsDrawer: shipCollapsed,
      polygonFeatures: JSON.stringify(features),
      activeTabId: shipDetailTabValue,
    };

    const res = await api.put(`/work-stations/updateMapState`, {
      userId: currentUser.uid,
      folderId: currentTab.folderId,
      workStationId: currentTab.workStationId,
      mapState,
    });
    // Update the local state as well
    const updatedProjects: any = {
      ...allProjects,
      [folderId]: {
        ...allProjects[folderId],
        workStations: {
          ...allProjects[folderId].workStations,
          [workStationId]: {
            ...allProjects[folderId].workStations[workStationId],
            mapState: {
              ...allProjects[folderId].workStations[workStationId].mapState,
              showGlobeView: false,
              zoomLevel,
              mapLatLng,
              date: date?.format("YYYY-MM-DD"),
              myShips: shipsArray,
              myEvents: eventsArray,
              mySatellite: satelliteImagesArray,
              sidebarTabIndex: active,
              sidebarDrawer: collapsed,
              shipDetailsDrawer: shipCollapsed,
            },
          },
        },
      },
    };
    dispatch(updateAllProjects(updatedProjects));
    setLoading(false);
    dispatch(setChangeSaved(true));
    let response: any;
    if (res.data.msg) {
      if (mapRef.current !== null) {
        const { folderId, workStationId } = currentTab;
        deckCanvas?._deck?.redraw(true);
        await mergeImages(
          mapSnapshot,
          deckCanvas?._deck?.canvas?.toDataURL("image/png"),
        ).then(async (mergedDataURL: any) => {
          response = await saveFileToStorage({
            dataURL: mergedDataURL,
            workStationId,
            folderId,
          });
        });
      }
      if (response) {
        setNewSnackBar({ message: res.data.msg, severity: "success" });
        if (isTabSwitch) {
          dispatch(setIsSwitchTabUpdated(true));
        } else if (isTabDelete) {
          dispatch(setIsDeleteTabUpdated(true));
        }
      }
    }
  }, [
    allProjects,
    date,
    deckCanvas?._deck,
    dispatch,
    mapLatLng,
    mapSnapshot,
    mergeImages,
    selectedTabId,
    tabs,
    currentUser.uid,
    zoomLevel,
    saveFileToStorage,
    prevTabId,
    isTabSwitch,
    isTabDelete,
    selectedShip,
    selectedEvent,
    satelliteImageTimeline,
    collapsed,
    active,
    shipCollapsed,
    features,
    shipPaths,
  ]);

  return {
    saveStateToFirebase,
    handleSetDataFromFirebase,
  };
};

export default useFireBase;
