import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useControl } from "react-map-gl";
import * as MapboxDrawGeodesic from "mapbox-gl-draw-geodesic";
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import React from "react";

let modes = MapboxDraw.modes as any;
modes = MapboxDrawGeodesic.enable(modes);
modes.draw_rectangle = DrawRectangle;
export const DrawControl = React.forwardRef((props: any, ref) => {
  const draw = new MapboxDraw({ ...props, modes });

  const drawRef = useControl(
    () => draw as any,
    ({ map }) => {
      map.on("draw.create", props.onCreate);
      map.on("draw.update", props.onUpdate);
      map.on("draw.delete", props.onDelete);
      map.on("draw.render", props.onRender);
    },
    ({ map }) => {
      map.off("draw.create", props.onCreate);
      map.off("draw.update", props.onUpdate);
      map.on("draw.delete", props.onDelete);
      map.on("draw.render", props.onRender);
    },
    {
      position: props.position,
    },
  );
  React.useImperativeHandle(ref, () => drawRef, [drawRef]); // This way I exposed drawRef outside the component

  return null;
});

DrawControl.defaultProps = {
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {},
};
