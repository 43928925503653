import { COUNTRY_CODES } from "../../utils/Constants";

type CountryCode = (typeof COUNTRY_CODES)[number]["code"];

interface FlagProps {
  size?: number;
  countryCode: CountryCode;
}

const CountryFlagIcon: React.FC<FlagProps> = ({ size = 30, countryCode }) => {
  return (
    <img
      src={`https://flagcdn.com/48x36/${countryCode.toLowerCase()}.png`}
      width={size.toString()}
      alt={`${countryCode} flag`}
    />
  );
};

export default CountryFlagIcon;
