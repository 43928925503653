import { FC } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import styles from "./CustomModal.module.scss";
import moment from "moment";
interface CustomModalInterface {
  openManualAttributionModal: boolean;
  date: string | undefined;
  errorModal: any;
  loadShipsOnMap: any;
  setErrorModal: any;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CustomModal: FC<CustomModalInterface> = ({
  openManualAttributionModal,
  date,
  errorModal,
  setErrorModal,
  loadShipsOnMap,
}) => {
  const onSave = () => {
    const today = new Date();
    const formattedDate = today.toString();
    const { isAISSearch, searchValue } = errorModal;
    loadShipsOnMap(isAISSearch, searchValue, formattedDate);
    setErrorModal(false);
  };

  const closeModal = () => {
    setErrorModal(false);
  };

  return (
    <Modal
      keepMounted
      open={openManualAttributionModal}
      onClose={closeModal}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      className={styles.attrubutionModalWrap}
    >
      <Box sx={style} className={styles.attrubutionModal}>
        <h3 className={styles.title}>AIS Ping Not Found</h3>
        <Typography style={{ color: "white", padding: "12px" }}>
          AIS ping for {date && moment(date).format("YYYY-MM-DD")} not found.
          Search for the latest available AIS position?
        </Typography>

        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
          className={styles.btnWrapper}
        >
          <Button
            variant="outlined"
            onClick={closeModal}
            className={styles.linkBtn}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={onSave}
            className={styles.saveBtn}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
