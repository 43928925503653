import {
  Box,
  Checkbox,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Select,
} from "@mui/material";
import {
  CheckBoxOutlineBlank,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
import styles from "./SelectCheckbox.module.scss";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface SelectCheckboxProps {
  id: string;
  options: any[];
  title: string;
  onChange: (event: { target: { value: any } }) => void;
  value: string[];
  label: string;
  unavailableOptions: string[];
}

const SelectCheckbox = ({
  id,
  options,
  title,
  onChange,
  value,
  label,
  unavailableOptions,
}: SelectCheckboxProps) => (
  <Box
    display="flex"
    flexDirection="column"
    gap={1}
    className={styles.shipFiltersDetail}
  >
    <span className={styles.shipFiltersLabel}>{title}</span>
    <Select
      id={id}
      multiple
      value={value}
      onChange={onChange}
      input={<OutlinedInput />}
      displayEmpty
      renderValue={(selected: any) => {
        if (selected.length === 0)
          return <p className={styles.shipFiltersPlaceholder}>{label}</p>;
        return selected.join(", ");
      }}
      className={styles.shipFilterSelect}
      classes={{
        select: styles.inputSelect,
        icon: styles.inputIcon,
      }}
      label={label}
      inputProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              backgroundColor: "#26262c",
              border: "1px solid #FFFFFF40",
              boxShadow: "0px 4px 4px 0px #00000040",
            },
          },
        },
      }}
    >
      {options.map((option: any) => {
        return (
          <MenuItem
            key={option.label}
            value={option.label}
            className={styles.shipFilterOptions}
            classes={{ selected: styles.shipFilterOptionsSelected }}
            disabled={option.disabled}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={option.checked}
              value={option.label}
              className={styles.checkboxMain}
              classes={{
                checked: styles.checkboxChecked,
              }}
            />
            <ListItemText
              className={styles.shipFilterOptionsText}
              primary={`${option.label}${
                unavailableOptions && unavailableOptions.includes(option.label)
                  ? "(Unavailable)"
                  : ""
              }`}
            />
          </MenuItem>
        );
      })}
      {options.every((option: { disabled: boolean }) => option.disabled) && (
        <p className={styles.shipFilterUnavailable}>
          Unavailable based on the data source(s) selected.
        </p>
      )}
    </Select>
  </Box>
);

export default SelectCheckbox;
