import React from "react";

const OveriewShipIcon = () => {
  return (
    <svg
      width="200"
      height="52"
      viewBox="0 0 200 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M176.145 31.3529L175.982 31.1732C176.001 31.1538 177.924 29.4373 178.123 27.7597L178.138 27.6505H178.245C178.366 27.6505 190.332 27.8593 199.25 27.0605L199.272 27.3033C190.791 28.0632 179.563 27.9151 178.349 27.8957C178.055 29.6291 176.215 31.2825 176.145 31.3529Z"
        fill="white"
      />
      <path
        d="M190.442 51.2214H18.2253C16.2766 51.2305 14.3642 50.694 12.7046 49.6725L9.82036 48.1212V46.6645L9.9029 46.6378C11.1168 46.2348 11.1168 45.3316 11.1168 45.3244V43.7002C11.1168 42.4863 9.0459 41.8599 9.02404 41.8551L3.58583 40.7674C0.915275 40.0998 0.672511 38.3397 0.655517 38.2644C0.00730108 35.8973 0 31.321 0 31.2748V31.1534H176.045C187.351 31.1534 196.997 30.2989 197.936 30.2139L199.636 25.8852C193.469 26.3465 189.294 26.3707 188.757 26.3829L187.507 27.9245L187.317 27.7716L188.643 26.1401H188.701C188.742 26.1401 193.134 26.1401 199.808 25.6278L200 25.6133L195.567 36.9534V38.9903C199.012 39.0437 199.937 41.3647 199.937 42.598C199.957 44.6499 199.338 46.6572 198.167 48.3421C195.759 51.1899 190.658 51.2214 190.442 51.2214ZM10.0583 47.9755L12.8284 49.4662C14.4493 50.4637 16.3172 50.9876 18.2205 50.9787H190.437C190.488 50.9787 195.64 50.9447 197.963 48.1867C199.101 46.5486 199.701 44.5971 199.68 42.6028C199.68 42.4669 199.624 39.2355 195.441 39.2355H195.319V36.8903L197.825 30.4591C196.368 30.5902 186.975 31.389 176.031 31.389H0.252498C0.252498 32.1173 0.318061 36.0721 0.903154 38.211C0.903154 38.296 1.14592 39.8983 3.64895 40.5222L9.09203 41.6123C9.19642 41.6439 11.3693 42.3115 11.3693 43.6929V45.3171C11.3693 45.3584 11.3547 46.3586 10.0631 46.832L10.0583 47.9755Z"
        fill="white"
      />
      <path
        d="M6.13495 51.2205H2.58797C1.89363 51.2205 1.76009 50.0722 1.75523 50.0212L1.47363 39.6133H1.71641L1.99561 50.0139C1.99561 50.0139 2.11698 50.985 2.58797 50.985H6.10095C6.30003 50.9243 6.76859 50.7252 6.76859 50.4533V41.2957H7.01137V50.4533C7.01137 50.9656 6.23935 51.1986 6.14953 51.2229L6.13495 51.2205Z"
        fill="white"
      />
      <path
        d="M9.62628 47.9503H7.83458L7.42188 47.676V47.1127L7.8176 46.8359H9.62628V47.9503ZM7.90743 47.7075H9.38351V47.0787H7.90014L7.65736 47.239V47.5473L7.90743 47.7075Z"
        fill="white"
      />
      <path
        d="M9.94188 47.0215H9.50488V47.2643H9.94188V47.0215Z"
        fill="white"
      />
      <path d="M10.0503 47.4922H9.61328V47.735H10.0503V47.4922Z" fill="white" />
      <path
        d="M8.66788 51.9998H8.63631C8.43481 51.9755 8.32313 51.7279 8.30371 51.6769V51.6308V48.2319L8.41295 47.7949L8.65573 47.8532L8.54892 48.2756V51.5993C8.58291 51.6697 8.64115 51.7473 8.67757 51.7522C8.71398 51.757 8.72129 51.7522 8.76499 51.6769C8.76499 51.6769 9.1777 50.9947 9.1777 48.278L9.07087 47.858L9.31364 47.7974L9.4302 48.2635C9.4302 51.1015 8.9932 51.791 8.97378 51.8177C8.94357 51.8725 8.89931 51.9183 8.84554 51.9503C8.79178 51.9823 8.73045 51.9994 8.66788 51.9998Z"
        fill="white"
      />
      <path
        d="M9.29067 46.8941L9.04789 46.8334L9.15473 46.4134C9.15473 43.6943 8.74199 43.0145 8.73956 43.0145C8.69829 42.9563 8.66674 42.9417 8.6546 42.9441C8.64246 42.9466 8.55991 43.0243 8.52592 43.0971V46.4207L8.63275 46.8431L8.38998 46.9014L8.27588 46.4353V43.0364C8.2953 42.9854 8.40698 42.7378 8.60848 42.7135C8.67163 42.7097 8.73455 42.7239 8.78997 42.7544C8.8454 42.7849 8.89104 42.8305 8.92165 42.8859C8.9435 42.9174 9.3805 43.6142 9.3805 46.445V46.4765L9.29067 46.8941Z"
        fill="white"
      />
      <path
        d="M189.398 27.7732L189.155 27.7708L189.232 23.7285H191.243V27.6979H191V23.9713H189.47L189.398 27.7732Z"
        fill="white"
      />
      <path
        d="M189.971 23.8527L189.728 23.8479L189.886 16.7539H190.801V23.8503H190.558V16.9967H190.124L189.971 23.8527Z"
        fill="white"
      />
      <path
        d="M198.755 25.8726L190.559 16.9287V13.8867H191.683V14.2582L190.806 14.9574V16.8292L198.939 25.71L198.755 25.8726ZM190.801 14.1222V14.6442L191.435 14.1222H190.801Z"
        fill="white"
      />
      <path
        d="M190.765 16.5182L190.597 16.3434L191.42 15.5592H190.68V15.3164H191.678V15.649L190.765 16.5182Z"
        fill="white"
      />
      <path
        d="M20.682 31.2635H20.4392V7.94716L15.0641 8.69977L13.7677 18.8527V31.2635H13.5249V18.8382L14.8456 8.4837L20.682 7.66797V31.2635Z"
        fill="white"
      />
      <path
        d="M2.65047 31.2617H2.40769V27.7317L8.90684 19.2831L8.64221 19.0185L1.77647 27.8167V31.2617H1.53369V27.7317L8.62036 18.6543L9.22973 19.2612L2.65047 27.8143V31.2617Z"
        fill="white"
      />
      <path
        d="M20.561 18.9597H11.3452V17.6973H20.561V17.94H11.588V18.7169H20.561V18.9597Z"
        fill="white"
      />
      <path
        d="M20.561 24.844H11.3452V23.584H20.561V23.8268H11.588V24.6012H20.561V24.844Z"
        fill="white"
      />
      <path
        d="M20.561 27.8948H11.3452V26.6348H13.756V26.8775H11.588V27.652H20.561V27.8948Z"
        fill="white"
      />
      <path
        d="M32.8916 22.0106H9.89326L8.71094 20.906L9.28632 20.6777L32.8916 20.7481V22.0106ZM9.98795 21.7678H32.6488V20.9909L9.31062 20.9205L9.15281 20.9861L9.98795 21.7678Z"
        fill="white"
      />
      <path d="M12.734 27.7734H12.4912V31.2621H12.734V27.7734Z" fill="white" />
      <path d="M11.8067 24.832H11.564V26.7937H11.8067V24.832Z" fill="white" />
      <path d="M12.4586 24.832H12.2158V26.7937H12.4586V24.832Z" fill="white" />
      <path d="M11.8067 21.998H11.564V23.7412H11.8067V21.998Z" fill="white" />
      <path d="M12.4586 21.998H12.2158V23.7412H12.4586V21.998Z" fill="white" />
      <path d="M11.8067 18.9473H11.564V20.9089H11.8067V18.9473Z" fill="white" />
      <path
        d="M12.4586 18.9473H12.2158V20.9089H12.4586V18.9473Z"
        fill="white"
      />
      <path
        d="M3.40344 27.8687L3.25049 27.6818L9.54085 22.5349L7.8147 20.8403L7.98465 20.668L9.90501 22.5519L3.40344 27.8687Z"
        fill="white"
      />
      <path
        d="M4.53804 27.8949H2.53027V27.6521H4.4555L9.82088 23.571V21.7793H10.0637V23.6924L10.0151 23.7288L4.53804 27.8949Z"
        fill="white"
      />
      <path
        d="M8.08319 20.6609L8.04346 20.9004L8.92961 21.0474L8.96934 20.8079L8.08319 20.6609Z"
        fill="white"
      />
      <path
        d="M17.2259 21.8879H16.9832V19.8268H16.3131V21.8879H16.0703V19.584H17.2259V21.8879Z"
        fill="white"
      />
      <path
        d="M30.99 16.1239H23.1167V14.791H30.99V16.1239ZM23.3595 15.8811H30.7472V15.0338H23.3595V15.8811Z"
        fill="white"
      />
      <path
        d="M38.8365 16.1239H37.7222V14.791H38.8365V16.1239ZM37.9649 15.8811H38.5938V15.0338H37.9649V15.8811Z"
        fill="white"
      />
      <path
        d="M38.4027 13.2879H25.5161V11.9551H38.4027V13.2879ZM25.7589 13.0451H38.1599V12.1979H25.7589V13.0451Z"
        fill="white"
      />
      <path
        d="M32.9516 19.1742H23.1167V17.8438H32.9516V19.1742ZM23.3595 18.9314H32.7088V18.0865H23.3595V18.9314Z"
        fill="white"
      />
      <path
        d="M37.9649 24.8426H23.1167V23.5098H37.9649V24.8426ZM23.3595 24.5998H37.7222V23.7525H23.3595V24.5998Z"
        fill="white"
      />
      <path
        d="M23.3595 16.0039H23.1167V31.2625H23.3595V16.0039Z"
        fill="white"
      />
      <path d="M25.7589 13.168H25.5161V16.0012H25.7589V13.168Z" fill="white" />
      <path
        d="M37.966 23.633H37.7232V15.0362H30.7725L30.3452 13.1984L30.5831 13.1426L30.9643 14.7935H37.966V23.633Z"
        fill="white"
      />
      <path
        d="M38.1808 13.1222L37.7456 14.8652L37.9812 14.924L38.4164 13.181L38.1808 13.1222Z"
        fill="white"
      />
      <path
        d="M38.1185 13.7051H30.6992V13.9479H38.1185V13.7051Z"
        fill="white"
      />
      <path
        d="M37.9649 24.7227H37.7222V31.2631H37.9649V24.7227Z"
        fill="white"
      />
      <path
        d="M66.9599 27.8953H37.7222V26.5625H66.9599V27.8953ZM37.9649 27.6526H66.7171V26.8053H37.9649V27.6526Z"
        fill="white"
      />
      <path
        d="M86.1429 27.8953H68.0244V26.5625H86.1429V27.8953ZM68.2672 27.6526H85.9001V26.8053H68.2672V27.6526Z"
        fill="white"
      />
      <path
        d="M114.266 27.8953H87.208V26.5625H114.266V27.8953ZM87.4508 27.6526H114.023V26.8053H87.4508V27.6526Z"
        fill="white"
      />
      <path
        d="M149.142 27.8953H115.11V26.5625H149.142V27.8953ZM115.353 27.6526H148.9V26.8053H115.353V27.6526Z"
        fill="white"
      />
      <path
        d="M178.337 27.8946H150.209V26.5642L188.587 26.3457L188.59 26.5885L150.452 26.8046V27.6518H178.337V27.8946Z"
        fill="white"
      />
      <path
        d="M55.1878 31.3848H53.855V24.6016H55.1878V31.3848ZM54.0978 31.142H54.945V24.8443H54.0978V31.142Z"
        fill="white"
      />
      <path
        d="M76.5506 31.3848H75.2178V24.6016H76.5506V31.3848ZM75.4606 31.142H76.3078V24.8443H75.4606V31.142Z"
        fill="white"
      />
      <path
        d="M97.6952 31.3848H96.3623V24.6016H97.6952V31.3848ZM96.6051 31.142H97.4524V24.8443H96.6051V31.142Z"
        fill="white"
      />
      <path
        d="M118.849 31.3848H117.516V24.6016H118.849V31.3848ZM117.759 31.142H118.606V24.8443H117.759V31.142Z"
        fill="white"
      />
      <path
        d="M139.988 31.3848H138.655V24.6016H139.988V31.3848ZM138.898 31.142H139.745V24.8443H138.898V31.142Z"
        fill="white"
      />
      <path
        d="M164.185 31.3848H162.852V24.6016H164.185V31.3848ZM163.095 31.142H163.942V24.8443H163.095V31.142Z"
        fill="white"
      />
      <path
        d="M176.176 31.3848H174.843V24.6016H176.176V31.3848ZM175.086 31.142H175.933V24.8443H175.086V31.142Z"
        fill="white"
      />
      <path
        d="M66.9606 27.7734H66.7178V31.2621H66.9606V27.7734Z"
        fill="white"
      />
      <path
        d="M68.2672 27.8828H68.0244V31.3715H68.2672V27.8828Z"
        fill="white"
      />
      <path
        d="M86.1427 27.8828H85.8999V31.3715H86.1427V27.8828Z"
        fill="white"
      />
      <path d="M87.4508 27.8828H87.208V31.3715H87.4508V27.8828Z" fill="white" />
      <path
        d="M114.267 27.8828H114.024V31.3715H114.267V27.8828Z"
        fill="white"
      />
      <path d="M115.353 27.8828H115.11V31.3715H115.353V27.8828Z" fill="white" />
      <path
        d="M149.144 27.8828H148.901V31.3715H149.144V27.8828Z"
        fill="white"
      />
      <path
        d="M150.452 27.8828H150.209V31.3715H150.452V27.8828Z"
        fill="white"
      />
      <path
        d="M31.1363 4.23242H30.8936V13.1691H31.1363V4.23242Z"
        fill="white"
      />
      <path
        d="M30.4006 7.14495L28.8599 16.1172L29.1002 16.1585L30.6409 7.18622L30.4006 7.14495Z"
        fill="white"
      />
      <path
        d="M33.1682 4.35317H28.769V0.378906H29.0118V4.11039H33.1682V4.35317Z"
        fill="white"
      />
      <path
        d="M28.9637 2.2476L28.7407 2.34375L29.5714 4.26985L29.7944 4.1737L28.9637 2.2476Z"
        fill="white"
      />
      <path
        d="M32.5831 4.23145H32.3404V3.17051H29.2134V2.92773H32.5831V4.23145Z"
        fill="white"
      />
      <path
        d="M34.1538 2.18359H29.7231V2.42637H34.1538V2.18359Z"
        fill="white"
      />
      <path
        d="M34.1543 4.80078H31.6318V5.04356H34.1543V4.80078Z"
        fill="white"
      />
      <path
        d="M31.6612 2.30469H31.4185V3.05001H31.6612V2.30469Z"
        fill="white"
      />
      <path
        d="M32.5318 2.30469H32.2891V3.05001H32.5318V2.30469Z"
        fill="white"
      />
      <path
        d="M33.6901 6.72606H29.7231V6.48329H33.4473V5.6627H29.9028V5.41992H33.6901V6.72606Z"
        fill="white"
      />
      <path
        d="M32.8019 4.92188H32.5591V5.54096H32.8019V4.92188Z"
        fill="white"
      />
      <path
        d="M33.4547 4.92188H33.2119V5.54096H33.4547V4.92188Z"
        fill="white"
      />
      <path
        d="M29.4206 13.0877L26.9673 15.9219L27.1508 16.0808L29.6042 13.2466L29.4206 13.0877Z"
        fill="white"
      />
      <path
        d="M27.0284 16.0036H26.7856V14.8674L28.3249 13.0879L28.5094 13.2481L27.0284 14.9573V16.0036Z"
        fill="white"
      />
      <path
        d="M27.0729 19.136L24.2251 15.941V14.9141H24.4679V15.8488L27.255 18.9733L27.0729 19.136Z"
        fill="white"
      />
      <path
        d="M27.2834 18.9471H27.0407V17.9032L24.4551 14.9947L24.6372 14.832L27.2834 17.8109V18.9471Z"
        fill="white"
      />
      <path
        d="M26.8544 21.9682L24.2251 18.9918V17.9648H24.4679V18.8995L27.0365 21.808L26.8544 21.9682Z"
        fill="white"
      />
      <path
        d="M27.2834 21.9974H27.0407V20.9535L24.4551 18.045L24.6372 17.8848L27.2834 20.8612V21.9974Z"
        fill="white"
      />
      <path
        d="M26.8544 24.8022L24.2251 21.8258V20.7988H24.4679V21.7335L27.0365 24.642L26.8544 24.8022Z"
        fill="white"
      />
      <path
        d="M27.2834 24.8314H27.0407V23.7875L24.4551 20.879L24.6372 20.7188L27.2834 23.6952V24.8314Z"
        fill="white"
      />
      <path
        d="M27.0729 27.8571L24.2251 24.6622V23.6328H24.4679V24.5675L27.255 27.6945L27.0729 27.8571Z"
        fill="white"
      />
      <path
        d="M27.2834 27.6654H27.0407V26.6239L24.4551 23.713L24.6372 23.5527L27.2834 26.5316V27.6654Z"
        fill="white"
      />
      <path
        d="M37.8443 27.6523H23.2388V27.8951H37.8443V27.6523Z"
        fill="white"
      />
      <path
        d="M32.3378 19.0548H32.095V17.0494H31.4273V19.0548H31.1846V16.8066H32.3378V19.0548Z"
        fill="white"
      />
      <path
        d="M32.3378 21.8887H32.095V19.8834H31.4273V21.8887H31.1846V19.6406H32.3378V21.8887Z"
        fill="white"
      />
      <path
        d="M32.3378 24.7223H32.095V22.7193H31.4273V24.7223H31.1846V22.4766H32.3378V24.7223Z"
        fill="white"
      />
      <path
        d="M12.6128 29.9258H2.53027V30.1686H12.6128V29.9258Z"
        fill="white"
      />
      <path
        d="M23.1295 29.9258H20.6216V30.1686H23.1295V29.9258Z"
        fill="white"
      />
      <path
        d="M177.036 29.9258H37.8442V30.1686H177.036V29.9258Z"
        fill="white"
      />
      <path
        d="M42.8307 27.7734H42.5879V31.2621H42.8307V27.7734Z"
        fill="white"
      />
      <path
        d="M45.8141 27.8828H45.5713V31.3715H45.8141V27.8828Z"
        fill="white"
      />
      <path
        d="M48.9337 27.7734H48.6909V31.2621H48.9337V27.7734Z"
        fill="white"
      />
      <path
        d="M51.9171 27.8828H51.6743V31.3715H51.9171V27.8828Z"
        fill="white"
      />
      <path
        d="M58.0211 27.8828H57.7783V31.3715H58.0211V27.8828Z"
        fill="white"
      />
      <path
        d="M61.1437 27.7734H60.9009V31.2621H61.1437V27.7734Z"
        fill="white"
      />
      <path
        d="M64.1241 27.8828H63.8813V31.3715H64.1241V27.8828Z"
        fill="white"
      />
      <path
        d="M70.4469 27.8828H70.2041V31.3715H70.4469V27.8828Z"
        fill="white"
      />
      <path
        d="M73.5689 27.7734H73.3262V31.2621H73.5689V27.7734Z"
        fill="white"
      />
      <path
        d="M79.8199 27.8828H79.5771V31.3715H79.8199V27.8828Z"
        fill="white"
      />
      <path
        d="M82.6559 27.8828H82.4131V31.3715H82.6559V27.8828Z"
        fill="white"
      />
      <path
        d="M91.3746 27.8828H91.1318V31.3715H91.3746V27.8828Z"
        fill="white"
      />
      <path
        d="M94.2096 27.8828H93.9668V31.3715H94.2096V27.8828Z"
        fill="white"
      />
      <path
        d="M100.531 27.8828H100.289V31.3715H100.531V27.8828Z"
        fill="white"
      />
      <path
        d="M103.364 27.8828H103.122V31.3715H103.364V27.8828Z"
        fill="white"
      />
      <path
        d="M106.417 27.8828H106.174V31.3715H106.417V27.8828Z"
        fill="white"
      />
      <path
        d="M109.251 27.8828H109.008V31.3715H109.251V27.8828Z"
        fill="white"
      />
      <path
        d="M112.521 27.8828H112.278V31.3715H112.521V27.8828Z"
        fill="white"
      />
      <path
        d="M121.675 27.8828H121.433V31.3715H121.675V27.8828Z"
        fill="white"
      />
      <path d="M124.73 27.8828H124.487V31.3715H124.73V27.8828Z" fill="white" />
      <path
        d="M127.562 27.8828H127.319V31.3715H127.562V27.8828Z"
        fill="white"
      />
      <path
        d="M130.614 27.8828H130.371V31.3715H130.614V27.8828Z"
        fill="white"
      />
      <path
        d="M133.666 27.8828H133.423V31.3715H133.666V27.8828Z"
        fill="white"
      />
      <path
        d="M136.499 27.8828H136.256V31.3715H136.499V27.8828Z"
        fill="white"
      />
      <path
        d="M142.386 27.8828H142.144V31.3715H142.386V27.8828Z"
        fill="white"
      />
      <path d="M145.873 27.8828H145.63V31.3715H145.873V27.8828Z" fill="white" />
      <path d="M151.76 27.8828H151.518V31.3715H151.76V27.8828Z" fill="white" />
      <path
        d="M154.811 27.8828H154.568V31.3715H154.811V27.8828Z"
        fill="white"
      />
      <path
        d="M157.863 27.8828H157.621V31.3715H157.863V27.8828Z"
        fill="white"
      />
      <path
        d="M161.134 27.8828H160.892V31.3715H161.134V27.8828Z"
        fill="white"
      />
      <path
        d="M167.018 27.8828H166.775V31.3715H167.018V27.8828Z"
        fill="white"
      />
      <path d="M170.07 27.8828H169.827V31.3715H170.07V27.8828Z" fill="white" />
      <path
        d="M173.341 27.8828H173.098V31.3715H173.341V27.8828Z"
        fill="white"
      />
      <path d="M195.455 38.9902H1.26953V39.233H195.455V38.9902Z" fill="white" />
    </svg>
  );
};

export default OveriewShipIcon;
