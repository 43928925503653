import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addFileToStorage } from "./MainMap.services";

export const addFileToStorageData = createAsyncThunk(
  "mainMap/addFileToStorageData",
  async (addFileData: any) => {
    try {
      const data = await addFileToStorage(addFileData);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const mainMapSlice = createSlice({
  name: "mainMap",
  initialState: {
    loading: false,
    error: "",
    fileData: {} as any,
    isFileAdded: false as boolean,
    isSwitchTabUpdated: false as boolean,
    isDeleteTabUpdated: false as boolean,
    minimizePathToolModal: {
      path: false,
      pathAnalysis: false,
      extendedPath: false,
      futurePrediction: false,
      pathPlayback: false,
      estimatedLocation: false,
      downloadXLS: false,
    } as any,
    openPathToolModal: {
      path: false,
      pathAnalysis: false,
      extendedPath: false,
      futurePrediction: false,
      pathPlayback: false,
      estimatedLocation: false,
      downloadXLS: false,
    } as any,
    minimizePolygonModal: false as boolean,
  },
  reducers: {
    setIsFileAdded: (state, action) => {
      state.isFileAdded = action.payload;
    },
    setIsSwitchTabUpdated: (state, action) => {
      state.isSwitchTabUpdated = action.payload;
    },
    setIsDeleteTabUpdated: (state, action) => {
      state.isDeleteTabUpdated = action.payload;
    },
    setMinimizePathToolModal: (state, action) => {
      state.minimizePathToolModal = action.payload;
    },
    setOpenPathToolModal: (state, action) => {
      state.openPathToolModal = action.payload;
    },
    setMinimizePolygonModal: (state, action) => {
      state.minimizePolygonModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //ADD FILE TO STORAGE
      .addCase(addFileToStorageData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        addFileToStorageData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.fileData = action.payload.addFileData;
          state.isFileAdded = true;
        },
      )
      .addCase(
        addFileToStorageData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      );
  },
});

export const {
  setIsFileAdded,
  setIsSwitchTabUpdated,
  setIsDeleteTabUpdated,
  setMinimizePathToolModal,
  setOpenPathToolModal,
  setMinimizePolygonModal,
} = mainMapSlice.actions;

export const mainMapReducer = mainMapSlice.reducer;
