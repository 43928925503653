export const britNed = {
  type: "FeatureCollection",
  name: "BritNed Cable V2.kmz",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Name: "BritNed Cable V2",
        tessellate: 1,
        extrude: 0,
        visibility: -1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [0.72046451126957, 51.448738006680443, 0.0],
          [0.735722872477156, 51.451200427427828, 0.0],
          [0.767892666644612, 51.471412410414118, 0.0],
          [0.79845565436705, 51.474801372422597, 0.0],
          [0.827170372970694, 51.476823013214727, 0.0],
          [0.852600662288194, 51.477367443361118, 0.0],
          [0.877351159531343, 51.477054754903968, 0.0],
          [0.892320239437456, 51.476715113132528, 0.0],
          [0.910904673165807, 51.474224226009397, 0.0],
          [0.927628173778432, 51.472895095390328, 0.0],
          [0.95030951377296, 51.473137494088292, 0.0],
          [0.960774716922388, 51.473232115923409, 0.0],
          [0.994668666329084, 51.477863645676841, 0.0],
          [1.030084440451804, 51.483213759805068, 0.0],
          [1.068837953907265, 51.481412458955013, 0.0],
          [1.107541685356848, 51.47851745610614, 0.0],
          [1.154824640356686, 51.47038547623989, 0.0],
          [1.176482874963305, 51.469793299571528, 0.0],
          [1.21295354175712, 51.474427131832719, 0.0],
          [1.271553039345832, 51.47417902335112, 0.0],
          [1.312609974691379, 51.465148660840981, 0.0],
          [1.347535465192757, 51.467918276419582, 0.0],
          [1.363036535994486, 51.468672464685653, 0.0],
          [1.383470999260161, 51.472154279052667, 0.0],
          [1.422558736754906, 51.474572999302481, 0.0],
          [1.449935906516546, 51.477621366200687, 0.0],
          [1.491456567192948, 51.482356636358581, 0.0],
          [1.538874161851229, 51.493506755353231, 0.0],
          [1.577829291134023, 51.517635324254172, 0.0],
          [1.613814545466552, 51.540149754264519, 0.0],
          [1.655445875968697, 51.566409819871197, 0.0],
          [1.697637596822825, 51.592819129303336, 0.0],
          [1.736830436635379, 51.617480842910958, 0.0],
          [1.793227899242174, 51.638987285164177, 0.0],
          [1.838920975897875, 51.655951394277473, 0.0],
          [1.866879483954158, 51.666374384728073, 0.0],
          [1.906490141711434, 51.680127655740797, 0.0],
          [1.931147439034582, 51.687785070022407, 0.0],
          [1.957991293531551, 51.699363267072613, 0.0],
          [1.978845829038538, 51.708693623213897, 0.0],
          [2.031044884001831, 51.729889863381388, 0.0],
          [2.047102504203326, 51.733227011513662, 0.0],
          [2.072618236870003, 51.748849844377332, 0.0],
          [2.121855714704781, 51.764596588651713, 0.0],
          [2.151253538082871, 51.773928695968721, 0.0],
          [2.192357185698091, 51.79030848780436, 0.0],
          [2.232009326571409, 51.805479031758068, 0.0],
          [2.281207989788328, 51.82397038938533, 0.0],
          [2.350238179427828, 51.842841828897093, 0.0],
          [2.41136340857528, 51.86002230752711, 0.0],
          [2.470404057875313, 51.876796661922469, 0.0],
          [2.508265715210558, 51.887276945327187, 0.0],
          [2.536428307309246, 51.885832477792228, 0.0],
          [2.562972574371676, 51.880860300137847, 0.0],
          [2.587562574028943, 51.877069361754451, 0.0],
          [2.623580062237301, 51.864116951633463, 0.0],
          [2.650336798722326, 51.849825424318183, 0.0],
          [2.672738160922525, 51.839314763184078, 0.0],
          [2.690703766327132, 51.831081876461383, 0.0],
          [2.713430686935443, 51.830490359181482, 0.0],
          [2.738023121222588, 51.82903964632505, 0.0],
          [2.755677792377487, 51.827854056266268, 0.0],
          [2.789741765795353, 51.832367345021972, 0.0],
          [2.812639806428854, 51.835232411438149, 0.0],
          [2.829099755201647, 51.834868846986417, 0.0],
          [2.847762596785566, 51.834495847727993, 0.0],
          [2.871494059523396, 51.831591414845462, 0.0],
          [2.9223009129442, 51.83841774652889, 0.0],
          [2.941935549986603, 51.834883419233883, 0.0],
          [2.963660970795383, 51.834897408227278, 0.0],
          [2.998533135522645, 51.837571102447548, 0.0],
          [3.011525017073238, 51.834263888361811, 0.0],
          [3.034124614768516, 51.840128667939119, 0.0],
          [3.042638605265213, 51.838884266095342, 0.0],
          [3.085128855848178, 51.837882195930653, 0.0],
          [3.098140770202606, 51.840582458258687, 0.0],
          [3.127646169508522, 51.840125799050021, 0.0],
          [3.163200025416129, 51.845861040500132, 0.0],
          [3.185350438704098, 51.845630011586721, 0.0],
          [3.201205314293654, 51.843948358422352, 0.0],
          [3.225290614138818, 51.843994250916907, 0.0],
          [3.244795708671362, 51.84634428195637, 0.0],
          [3.278917033684521, 51.847701130237311, 0.0],
          [3.310808082909868, 51.848767298756897, 0.0],
          [3.350291280716031, 51.850366362173162, 0.0],
          [3.387984030196074, 51.847898420226997, 0.0],
          [3.412827787141004, 51.849243865389766, 0.0],
          [3.451097786034736, 51.844970798007687, 0.0],
          [3.49644232647361, 51.842082357126863, 0.0],
          [3.520546073468362, 51.840578521162087, 0.0],
          [3.549724847580207, 51.844843863516118, 0.0],
          [3.579467600563775, 51.848618069258272, 0.0],
          [3.595938466934134, 51.851868203785813, 0.0],
          [3.643481942849474, 51.859628159654122, 0.0],
          [3.687360155236887, 51.871725242694517, 0.0],
          [3.721690028291043, 51.881340134607342, 0.0],
          [3.749645296169859, 51.88930122632916, 0.0],
          [3.762813099845819, 51.89643870330849, 0.0],
          [3.789591717571543, 51.902446077853313, 0.0],
          [3.818107109258875, 51.904972859928073, 0.0],
          [3.858177430161258, 51.910825682386118, 0.0],
          [3.900548462592572, 51.91751340175464, 0.0],
          [3.953521777058917, 51.925805750012152, 0.0],
          [3.979434340410806, 51.925237565186627, 0.0],
          [3.986536530378479, 51.922664997011999, 0.0],
        ],
      },
    },
  ],
};
