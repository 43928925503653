import { MapboxOverlay } from "@deck.gl/mapbox/typed";
import { useControl } from "react-map-gl";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Dispatch, SetStateAction } from "react";
import moment from "moment";
import { PathData } from "../MainMap";
import MapTooltip from "../../../components/MapTooltip/MapTooltip";
import { EventHoverData, PathHoverData } from "./types";

function DeckGLOverlay(props: any) {
  const deck = useControl<any>(() => new MapboxOverlay(props));
  //@ts-ignore
  if (deck?._deck !== undefined && deck?._deck?.canvas !== null) {
    props.setDeckCanvas(deck);
  }
  deck.setProps(props);

  return null;
}

interface IRenderShips {
  deckGlLayers: any;
  setDeckCanvas: any;
  setShowCursor: any;
  setClickedCoordinates: Dispatch<SetStateAction<{ x: number; y: number }>>;
  setSelectedMapObject: Dispatch<any>;
  setCurrentUserHoverCoordinates: Dispatch<
    SetStateAction<[number, number] | null>
  >;
  shipPaths: {
    [key: string]: {
      path: PathData[];
      color: string;
      analysis: boolean;
    };
  };
  pathHoverData: PathHoverData | null;
  setPathHoverData: Dispatch<SetStateAction<PathHoverData | null>>;
  eventHoverData: EventHoverData | null;
  setEventHoverData: Dispatch<SetStateAction<EventHoverData | null>>;
}

const RenderShips = (props: IRenderShips) => {
  const {
    deckGlLayers,
    setDeckCanvas,
    setShowCursor,
    setCurrentUserHoverCoordinates,
    pathHoverData,
    setPathHoverData,
    eventHoverData,
    setEventHoverData,
  } = props;
  const { detections } = useSelector((state: RootState) => state.theia);

  const handleMapClick = (event: { object: any; x: any; y: any }) => {
    if (event.object !== undefined) {
      props.setSelectedMapObject(event.object);
    } else {
      props.setSelectedMapObject(null);
    }
    props.setClickedCoordinates({ x: event.x, y: event.y });
  };

  return (
    <div style={{ position: "relative" }}>
      {detections && (
        <DeckGLOverlay
          setDeckCanvas={setDeckCanvas}
          layers={deckGlLayers}
          onHover={(object: any) => {
            if (
              object.coordinate &&
              Array.isArray(object.coordinate) &&
              object.coordinate.length === 2
            ) {
              setCurrentUserHoverCoordinates(object.coordinate);
            } else {
              setCurrentUserHoverCoordinates(null);
            }
            // add all the geojson layers, maybe some code orginatization as well?
            if (object.layer) {
              const layerId = object.layer.id;
              if (
                layerId === "selectedShips" ||
                layerId === "light" ||
                layerId === "unattributed" ||
                layerId === "attributed" ||
                layerId === "spoofingEvents" ||
                layerId === "opticalSTS" ||
                layerId === "AISSTS" ||
                layerId === "sanctionedShips"
              ) {
                setShowCursor(true);
              }
            } else {
              setShowCursor(false);
            }
          }}
          onClick={(object: { object: any; x: number; y: number }) => {
            handleMapClick(object);
            const pathAnalysis =
              props.shipPaths?.[object?.object?.synmax_ship_id]?.analysis;
            if (object.object && pathAnalysis) {
              const { timestamp, longitude, latitude } = object.object;
              setPathHoverData({
                x: object.x,
                y: object.y,
                longitude,
                latitude,
                timestamp: `${moment
                  .utc(timestamp * 1000)
                  .format("DD/MM/YYYY HH:mm:ss")} UTC`,
              });
            } else {
              setPathHoverData(null);
            }
            if (object.object && object.object?.iconString) {
              const { position, iconString } = object.object;
              if (position?.length !== 2) return;
              setEventHoverData({
                x: object.x,
                y: object.y,
                longitude: position[1],
                latitude: position[0],
                iconString: iconString,
              });
            } else {
              setEventHoverData(null);
            }
          }}
        />
      )}
      <MapTooltip pathAnalysisData={pathHoverData} eventData={eventHoverData} />
    </div>
  );
};

export default RenderShips;
