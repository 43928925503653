import { Box } from "@mui/material";
import styles from "./ShipFilters.module.scss";
import SelectCheckbox from "../../../../components/SelectCheckbox/SelectCheckbox";
import useShipFilters from "./useShipFilters";
import { FilterParameters } from "../FilterArea/FilterArea";
import {
  ASPIRE_TYPES,
  ASPIRE_TYPES_UNATTRIBUTED,
  ASPIRE_UNASSIGNED,
  ISubType,
} from "../../../../utils/Constants";
import { useEffect, useRef, useState } from "react";

interface IShipTypeFilters {
  currentFilter: FilterParameters;
  currentEventTypeFilter: string | null;
}

const ShipTypeFilters = ({
  currentFilter,
  currentEventTypeFilter,
}: IShipTypeFilters) => {
  const {
    shipTypeFilters,
    shipSubTypeFilters,
    setShipSubTypeFilters,
    setShipTypeFilters,
  } = useShipFilters(currentFilter, currentEventTypeFilter);

  const [shipTypeSource, setShipTypeSource] = useState<typeof ASPIRE_TYPES>([]);
  const [subTypeSource, setSubTypeSource] = useState<ISubType[]>([]);
  const isInitialSetting = useRef(true);

  const handleShipTypeSelect = (event: { target: { value: string[] } }) => {
    const {
      target: { value },
    } = event;
    setShipTypeFilters(value);

    if (!isInitialSetting.current) {
      setShipSubTypeFilters([]); // Reset subTypeFilters only if not initial setting
    }
  };

  const handleSubTypeSelect = (event: { target: { value: string[] } }) => {
    const {
      target: { value },
    } = event;
    setShipSubTypeFilters(value);

    const updatedSubTypes = subTypeSource.map((subType) => ({
      ...subType,
      checked: value.includes(subType.label),
    }));

    setSubTypeSource(updatedSubTypes);
  };

  useEffect(() => {
    setShipTypeSource(
      (currentEventTypeFilter === "unattributed"
        ? ASPIRE_TYPES_UNATTRIBUTED
        : ASPIRE_TYPES
      ).map((item) => {
        return {
          ...item,
          checked: shipTypeFilters.includes(item.value),
        };
      }),
    );
  }, [currentEventTypeFilter, currentFilter, shipTypeFilters]);

  useEffect(() => {
    const subSource = shipTypeSource
      .filter((item) => shipTypeFilters.includes(item.value))
      .flatMap((item) => item.subTypes || []);

    subSource.push(ASPIRE_UNASSIGNED);

    setSubTypeSource(
      subSource.map((subType) => ({
        ...subType,
        checked: shipSubTypeFilters.includes(subType.label),
      })),
    );
  }, [shipTypeFilters, shipTypeSource, shipSubTypeFilters]);

  useEffect(() => {
    isInitialSetting.current = false; // Set the flag to false after initial setting
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      className={styles.shipFiltersDetail}
    >
      <SelectCheckbox
        id="ship-type"
        options={shipTypeSource}
        title="Show ships matching below type only:"
        value={shipTypeFilters}
        onChange={handleShipTypeSelect}
        label="Ship Type Sources"
        unavailableOptions={[]}
      />
      {shipTypeFilters.length > 0 && subTypeSource.length > 0 && (
        <SelectCheckbox
          id="ship-sub-type"
          options={subTypeSource}
          title="Show ships matching below sub type only:"
          value={shipSubTypeFilters}
          onChange={handleSubTypeSelect}
          label="Ship Sub Type Sources"
          unavailableOptions={[]}
        />
      )}
    </Box>
  );
};

export default ShipTypeFilters;
