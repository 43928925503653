import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useFireBaseAuth from "../../../context/useFireBaseAuth";
import { useNavigate } from "react-router";
import { signOut, getAuth } from "firebase/auth";
import { api } from "../../../services/api";
import styles from "./bannerModal.module.scss";

// Acknowledgement text
const acknowledgement = [
  "You are accessing a U.S. Government system.",
  "System usage may be monitored, recorded, and subject to audit.",
  "Unauthorized use of the system is prohibited and subject to criminal and civil penalties.",
  "Use of the system indicates consent to monitoring and recording.",
];

// Banner Modal
const BannerModal = () => {
  // Inintialize navigation hook
  const navigate = useNavigate();

  // Get current user from firebase auth context
  const { currentUser } = useFireBaseAuth();

  // State to handle modal open/close
  const [open, setOpen] = useState(false);

  // This useEffect hook will check if the acknowledgement banner needs to be shown.
  useEffect(() => {
    // Function to check if the banner needs to be shown
    const checkBanner = async () => {
      try {
        // Call the API to check if the user has already acknowledged the banner
        const response = await api.get(
          `/users/banner?userEmail=${currentUser.email}`,
        );

        if (response.data) {
          // If the response is true, open the modal to show the banner
          setOpen(true);
        }
      } catch (err) {
        // Log the error
        console.log(err);
      }
    };

    // Check if the current user has an email
    if (currentUser.email) {
      // Call the checkBanner function
      checkBanner();
    }

    // currentUser is a dependency for this useEffect hook
  }, [currentUser]);

  // Function to handle if user clicks on disagree
  const rejectAction = () => {
    // Sign out the user from firebase
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // remove MFA from local storage
        localStorage.setItem("isMFAVerified", "false");
        // navigate to login page
        navigate("/login");
      })
      .catch((error) => {
        // Log the error
        console.log(error, "err");
      });
  };

  return (
    // Banner Modal Dialog
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => console.log("hhe")}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ root: styles["banner-dialog"], paper: styles["banner-paper"] }}
    >
      <DialogTitle
        id="alert-dialog-title"
        classes={{ root: styles["banner-dialog-title"] }}
      >
        {"Security Acknowledgement"}
      </DialogTitle>
      <DialogContent classes={{ root: styles["banner-dialog-content"] }}>
        <DialogContentText
          id="alert-dialog-description"
          classes={{ root: styles["banner-dialog-content-text"] }}
        >
          <div className={styles["banners-container"]}>
            {acknowledgement.map((item, index) => {
              return (
                <div style={{ margin: "0.4em", display: "flex" }} key={index}>
                  <div className={styles["box-icon"]} />
                  <Typography className={styles["banner-point"]}>
                    {item}
                  </Typography>
                </div>
              );
            })}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: styles["dialog-actions"] }}>
        <Button
          onClick={rejectAction}
          classes={{ root: styles["disagree-button"] }}
        >
          I Disagree
        </Button>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          classes={{ root: styles["agree-button"] }}
        >
          I Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BannerModal;
