import { Box, Link } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "../Auth.module.scss";
import { InfoOutlined } from "@mui/icons-material";
import ValidatedPasswordInput from "../../../components/ValidatedPasswordInput/ValidatedPasswordInput";
import PasswordInput from "../../../components/PasswordInput/PasswordInput";
import OutlinedInputField from "../../../components/OutlinedInputField/OutlinedInputField";
import useSignUp from "./useSignUp";

const SignUp = () => {
  const {
    createNewUser,
    firstName,
    hanldeFirstNameCVhange,
    lastName,
    hanldeLastNameCVhange,
    email,
    handleEmailChange,
    password,
    setCurrentPassword,
    setPasswordIsValid,
    confirmPassword,
    hanldeChangeConfirmPassword,
    isLoading,
    errMessage,
    handleLoginClick,
  } = useSignUp();

  return (
    <Box className={styles.loginDetail}>
      <h5 className={styles.loginDetailHead}>Create An Account</h5>
      <form
        className={styles.detailInputWrapper}
        onSubmit={(e) => {
          e.preventDefault();
          createNewUser();
        }}
      >
        <Box className={styles.inputWrapperMain}>
          <OutlinedInputField
            label="First Name"
            value={firstName}
            setValue={hanldeFirstNameCVhange}
            placeholder="Enter first name"
          />
          <OutlinedInputField
            label="Last Name"
            value={lastName}
            setValue={hanldeLastNameCVhange}
            placeholder="Enter last name"
          />
        </Box>
        <OutlinedInputField
          label="Email"
          value={email}
          setValue={handleEmailChange}
          placeholder="Enter email"
        />
        <ValidatedPasswordInput
          currentPassword={password}
          setCurrentPassword={setCurrentPassword}
          label="Password"
          setPasswordIsValid={setPasswordIsValid}
          placeholder="Enter password"
        />
        <PasswordInput
          label="Confirm Password"
          currentPassword={confirmPassword}
          setCurrentPassword={hanldeChangeConfirmPassword}
          placeholder="Confirm password"
        />
        <LoadingButton
          variant="outlined"
          className={styles.buttonWrap}
          type="submit"
          loading={isLoading}
        >
          Sign Up
        </LoadingButton>
        {errMessage && (
          <p className={styles.errorMessage}>
            <InfoOutlined />
            {errMessage}
          </p>
        )}
        <p className={styles.accountWrapper}>
          Already have an account?
          <Link
            className={styles.inputLink}
            underline="none"
            onClick={handleLoginClick}
          >
            Log In
          </Link>
        </p>
      </form>
    </Box>
  );
};

export default SignUp;
