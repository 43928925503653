import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { defaultImplementation } from "../utils/utils";
import useGlobalFilters from "./useGlobalFilter";
import { Feature, FeatureCollection, Point, GeoJsonProperties } from "geojson";
import {
  OSInteligenceItem,
  getAllWebcams,
} from "../pages/Theia/SidebarComponents/OSWebcams/model";

const INITIAL_MAP_VIEW_STATE = {
  latitude: 51.47,
  longitude: 0.45,
  zoom: 4,
};

const INITIAL_MAP_LAT_LONG = { lat: 51, lng: 2 };

interface MainMapContextProps {
  zoomLevel: number;
  setZoomLevel: Dispatch<SetStateAction<number>>;
  mapLatLng: { lat: number; lng: number };
  setMapLatLng: Dispatch<SetStateAction<{ lat: number; lng: number }>>;
  mapSnapshot: string;
  setMapSnapshot: Dispatch<SetStateAction<string>>;
  deckCanvas: any;
  setDeckCanvas: Dispatch<SetStateAction<any>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setMapViewState: Dispatch<
    SetStateAction<{
      latitude: number;
      longitude: number;
      zoom: number;
    }>
  >;
  mapViewState: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  mapRef: any;
  webcamGeoJson: FeatureCollection | null;
  handleGetAllWebcams: () => void;
  selectWebcam: (webcamId: OSInteligenceItem, x: number, y: number) => void;
  webcamModalPosition: { x: number; y: number };
  selectedWebcam: OSInteligenceItem | null;
  selectedWebcamUrl: string | null;
  handleCloseModal: () => void;
}

const MainMapContext = createContext<MainMapContextProps>({
  zoomLevel: 4,
  setZoomLevel: defaultImplementation,
  mapLatLng: INITIAL_MAP_LAT_LONG,
  setMapLatLng: defaultImplementation,
  mapSnapshot: "",
  setMapSnapshot: defaultImplementation,
  deckCanvas: "",
  setDeckCanvas: defaultImplementation,
  loading: false,
  setLoading: defaultImplementation,
  setMapViewState: defaultImplementation,
  mapViewState: INITIAL_MAP_VIEW_STATE,
  mapRef: null,
  webcamGeoJson: null,
  handleGetAllWebcams: defaultImplementation,
  selectWebcam: defaultImplementation,
  webcamModalPosition: { x: 0, y: 0 },
  selectedWebcam: null,
  selectedWebcamUrl: null,
  handleCloseModal: defaultImplementation,
});

const useCurrentMapState = () => {
  const context = useContext<MainMapContextProps>(MainMapContext);
  if (!context) {
    throw new Error("useCurrentMapState must be used with a MainMapProvider");
  }
  return context;
};

const convertWebcamsToGeoJson = (
  webcams: OSInteligenceItem[],
): FeatureCollection => {
  const features: Feature<Point, GeoJsonProperties>[] = webcams.map(
    (webcam) => {
      const pointGeometry: Point = {
        type: "Point",
        coordinates: [webcam.longitude, webcam.latitude],
      };

      return {
        type: "Feature",
        geometry: pointGeometry,
        properties: {
          ...webcam,
        },
      };
    },
  );

  return {
    type: "FeatureCollection",
    features: features,
  };
};

export const MainMapProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [zoomLevel, setZoomLevel] = useState<number>(4);
  const [mapLatLng, setMapLatLng] = useState(INITIAL_MAP_LAT_LONG);
  const [mapSnapshot, setMapSnapshot] = useState<string>("");
  const [deckCanvas, setDeckCanvas] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [mapViewState, setMapViewState] = useState(INITIAL_MAP_VIEW_STATE);
  const [webcamGeoJson, setWebcamGeoJson] = useState<FeatureCollection | null>(
    null,
  );
  const [selectedWebcam, setSelectedWebcam] =
    useState<OSInteligenceItem | null>(null);
  const [selectedWebcamUrl, setSelectedWebcamUrl] = useState<string | null>(
    null,
  );

  const [webcamModalPosition, setWebcamModalPosition] = useState({
    x: 0,
    y: 0,
  });

  const mapRef = useRef(null);

  const { date } = useGlobalFilters();

  const selectWebcam = (webcamId: OSInteligenceItem, x: number, y: number) => {
    setSelectedWebcam(webcamId);
    setWebcamModalPosition({ x, y });
    setSelectedWebcamUrl(webcamId.description);
  };

  const handleCloseModal = () => {
    setSelectedWebcam(null);
    setSelectedWebcamUrl(null);
  };

  const handleGetAllWebcams = useCallback(async () => {
    if (date !== null) {
      const webcamData = await getAllWebcams({
        date: date?.format("YYYY-MM-DD"),
      });
      const formatedWebcamData = convertWebcamsToGeoJson(webcamData);
      setWebcamGeoJson(formatedWebcamData);
    }
  }, [date, setWebcamGeoJson]);

  useEffect(() => {
    handleGetAllWebcams();
  }, [date, handleGetAllWebcams]);

  return (
    <MainMapContext.Provider
      value={{
        zoomLevel,
        setZoomLevel,
        mapLatLng,
        setMapLatLng,
        mapSnapshot,
        setMapSnapshot,
        deckCanvas,
        setDeckCanvas,
        loading,
        setLoading,
        setMapViewState,
        mapViewState,
        mapRef,
        webcamGeoJson,
        handleGetAllWebcams,
        selectWebcam,
        webcamModalPosition,
        selectedWebcam,
        selectedWebcamUrl,
        handleCloseModal,
      }}
    >
      {children}
    </MainMapContext.Provider>
  );
};

export default useCurrentMapState;
