import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/store";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import Label from "../../../../components/Label/Label";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import { DateRange, MapContext } from "../../MainMap";
import { Box } from "@mui/material";
import {
  fetchPolygonAnalysisData,
  setResetPolygonData,
} from "../PolygonShipAnalysis.store";
import styles from "./PolygonShipAnalysisSelectDate.module.scss";

interface PolygonShipAnalysisSelectDateProps {
  setShowPolygonShipAnalysisSlider: (val: boolean) => void;
  dateRange: DateRange;
  setDateRange: (val: DateRange) => void;
  showPolygonShipAnalysisSlider: boolean;
  lastSelectedOption: string;
  setLastSelectedOption: (lastSelectedOption: string) => void;
  limit: number;
  skip: number;
  resetValuesForSlider: () => void;
}

const PolygonShipAnalysisSelectDate = ({
  setShowPolygonShipAnalysisSlider,
  dateRange,
  setDateRange,
  showPolygonShipAnalysisSlider,
  lastSelectedOption,
  setLastSelectedOption,
  limit,
  skip,
  resetValuesForSlider,
}: PolygonShipAnalysisSelectDateProps) => {
  const { analyzeShape } = useSelector(
    (state: RootState) => state.polygonShipAnalysis,
  );
  const [isUpdateSaved, setisUpdateSaved] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const { loading, error } = useSelector(
    (state: RootState) => state.polygonShipAnalysis,
  );
  const { features } = useContext(MapContext);
  const [analyzeShapeArray, setAnalyzeShapeArray] = useState(analyzeShape);

  useEffect(() => {
    setAnalyzeShapeArray(analyzeShape);
  }, [analyzeShape]);

  useEffect(() => {
    setisUpdateSaved(false);
  }, [lastSelectedOption]);

  const generateShipDataPromises = useCallback(
    (
      area: any,
      startDate: moment.Moment,
      endDate: moment.Moment,
      limit: number,
      skip: number,
      segmentHours: number,
    ) => {
      const promises = [];
      let currentStart = startDate.clone();
      let currentEnd = startDate
        .clone()
        .add(segmentHours, "hours")
        .subtract(1, "seconds");

      // Calculate the total number of segments
      const totalSegments = Math.ceil(
        endDate.diff(startDate, "hours") / segmentHours,
      );

      let segmentIndex = 0;

      while (currentStart.isBefore(endDate)) {
        segmentIndex++;
        const isLastSegment = segmentIndex === totalSegments;
        const shipData = {
          area,
          start: currentStart.format("YYYY-MM-DD HH:mm:ss"),
          // we want to ensure last segment ends at the top of the hour
          end: isLastSegment
            ? currentStart
                .clone()
                .add(segmentHours, "hours")
                .format("YYYY-MM-DD HH:mm:ss")
            : currentEnd.format("YYYY-MM-DD HH:mm:ss"),
          limit,
          skip,
        };

        promises.push(dispatch(fetchPolygonAnalysisData(shipData)));

        // move to the next segment
        currentStart = currentEnd.clone().add(1, "seconds");
        currentEnd = currentStart
          .clone()
          .add(segmentHours, "hours")
          .subtract(1, "seconds");
      }

      return promises;
    },
    [dispatch],
  );

  const handleClick = useCallback(async () => {
    //reset slider and skip value when polygon is changed
    if (showPolygonShipAnalysisSlider) {
      resetValuesForSlider();
    }

    setResetPolygonData();

    const area = features.data[lastSelectedOption].geometry;
    const startDate = moment(new Date(dateRange.startDate)).startOf("day");
    const endDate = moment(new Date(dateRange.endDate)).endOf("day");
    const segmentHours = 2;

    setIsProcessing(true);

    const promises = generateShipDataPromises(
      area,
      startDate,
      endDate,
      limit,
      skip,
      segmentHours,
    );

    try {
      const results = await Promise.allSettled(promises);
      const allSuccessful = results.every(
        (result) => result.status === "fulfilled",
      );

      if (allSuccessful && !error) {
        setShowPolygonShipAnalysisSlider(true);
        setisUpdateSaved(true);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  }, [
    showPolygonShipAnalysisSlider,
    resetValuesForSlider,
    features.data,
    lastSelectedOption,
    dateRange,
    limit,
    skip,
    generateShipDataPromises,
    error,
    setShowPolygonShipAnalysisSlider,
    setisUpdateSaved,
  ]);

  return (
    <Box display="flex" alignItems="flex-end" gap="12px">
      <Box display="flex" flexDirection="column" gap="12px" minWidth="200px">
        <Label label="Analyze Ships In" />
        <SelectInput
          name="analyze-ships"
          value={lastSelectedOption}
          onChange={(e: any) => {
            setLastSelectedOption(e.target.value);
          }}
          optionsArray={analyzeShapeArray}
          className={styles.analyzeShipSelect}
        />
      </Box>
      <CustomDatePicker
        title="Start Date"
        value={dateRange.startDate}
        setValue={(date: any) => {
          setDateRange({ ...dateRange, startDate: date });
          setisUpdateSaved(false);
        }}
        maxDate={dateRange.endDate}
      />
      <CustomDatePicker
        title="End Date"
        value={dateRange.endDate}
        setValue={(date: any) => {
          setDateRange({ ...dateRange, endDate: date });
          setisUpdateSaved(false);
        }}
        minDate={dateRange.startDate}
      />
      <Box width="100%">
        {(!isUpdateSaved || !showPolygonShipAnalysisSlider) && (
          <CustomButton
            buttonName={
              showPolygonShipAnalysisSlider ? "Update" : "Load Ships In Polygon"
            }
            loading={loading || isProcessing}
            onClick={handleClick}
            variant={showPolygonShipAnalysisSlider ? "outlined" : "contained"}
          />
        )}
      </Box>
    </Box>
  );
};

export default PolygonShipAnalysisSelectDate;
