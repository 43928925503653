import { useState, useEffect } from "react";
import { getSatelliteImage } from "../../RenderShips/apiCalls";

// type declaration for imageCache state
interface ImageCache {
  [key: string]: string;
}

// getObjectId extracts object_id from all ship details by filter against synmax_ship_id
const getObjectId = (shipDetailsTabs: any, shipDetailTabValue: any) => {
  // Check both shipDetailsTabs and shipDetailTabValue exist
  if (shipDetailsTabs && shipDetailTabValue) {
    // Filter to find unique ship details
    const shipDetails = shipDetailsTabs.filter(
      (detail: { synmax_ship_id: any }) =>
        detail.synmax_ship_id === shipDetailTabValue,
    )[0];

    // Some events do not have object_id. In that case we need to take object_id_1
    const object_id = shipDetails?.object_id
      ? shipDetails?.object_id
      : shipDetails?.object_id_1;

    // return the object_id
    return object_id;
  }
};

// Custom hook to fetch and store satellite images for ships
const useSatelliteImage = ({
  shipDetailsTabs,
  shipDetailTabValue,
}: {
  shipDetailsTabs: {};
  shipDetailTabValue: string;
}) => {
  // Initialize states to cache images
  const [imageCache, setImageCache] = useState<ImageCache>({});

  // Initialize image state to store current active image
  const [image, setImage] = useState<string>("");

  // getSatelliteImageRequest function fetches for satellite image and updates cache and image state
  const getSatelliteImageRequest = async (object_id: string) => {
    // If object_id is doesnt exist return
    if (!object_id) return;

    try {
      // Fetch image over the network
      const imageData = await getSatelliteImage(+object_id);
      if (imageData) {
        // update cache with new image
        setImageCache((prevCache) => ({
          ...prevCache,
          [object_id]: imageData,
        }));
        // Update image state to be returned
        setImage(imageData);
      }
    } catch (error) {
      return "";
    } finally {
    }
  };

  // useEffect hook triggered when user switches the tab or ship details are updated
  useEffect(() => {
    // reset image before moving forward
    setImage("");
    // Find object_id
    const obj_id = getObjectId(shipDetailsTabs, shipDetailTabValue);
    // If image exist in cache, retrieve from cache
    if (imageCache.hasOwnProperty(obj_id)) {
      setImage(imageCache[obj_id]);

      // Fetch over the network if doesnt exist in cache
    } else {
      obj_id && getSatelliteImageRequest(`${obj_id}`);
    }
  }, [shipDetailsTabs, shipDetailTabValue]);

  // image url to be returned for the active tab
  return image;
};

export default useSatelliteImage;
