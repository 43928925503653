export const nemoLink = {
  type: "FeatureCollection",
  name: "Nemo Link Cable V2.kmz",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Name: "Nemo Link Cable V2",
        tessellate: 1,
        extrude: 0,
        visibility: -1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [1.367033660129786, 51.324707985962277, 0.0],
          [1.375774918027421, 51.320389119213658, 0.0],
          [1.383160562670607, 51.319299445361693, 0.0],
          [1.419519586582803, 51.306620916817877, 0.0],
          [1.44489690143403, 51.297690786572751, 0.0],
          [1.487006412137071, 51.300323385726337, 0.0],
          [1.507037093446575, 51.304089636243297, 0.0],
          [1.524941158352664, 51.308882872034083, 0.0],
          [1.550063884668977, 51.316854128370473, 0.0],
          [1.597556806943943, 51.317158197409121, 0.0],
          [1.642427509691993, 51.323040865068549, 0.0],
          [1.662248238755168, 51.325326273266263, 0.0],
          [1.670509633020234, 51.330234472678647, 0.0],
          [1.683413397467852, 51.330433756334273, 0.0],
          [1.722369157568924, 51.326454798981189, 0.0],
          [1.883135362864163, 51.326217132893532, 0.0],
          [1.944970188277642, 51.3241576979553, 0.0],
          [2.072365570229213, 51.324510566396498, 0.0],
          [2.092990025354653, 51.32431249174946, 0.0],
          [2.133506778739798, 51.322605598085573, 0.0],
          [2.166470397620097, 51.322737679615692, 0.0],
          [2.225913137235513, 51.312672719478471, 0.0],
          [2.271803744979881, 51.305083908109872, 0.0],
          [2.345611180739413, 51.332585281068667, 0.0],
          [2.418190854805993, 51.359536383491637, 0.0],
          [2.425946711430969, 51.361497810114322, 0.0],
          [2.435022400031315, 51.369213627245237, 0.0],
          [2.457338985635971, 51.3695273864341, 0.0],
          [2.476621413782685, 51.376524821849863, 0.0],
          [2.494857830239394, 51.384796143548627, 0.0],
          [2.525191317973239, 51.384763931207331, 0.0],
          [2.535015983105819, 51.379910862759743, 0.0],
          [2.542993282193102, 51.371193028023043, 0.0],
          [2.550760652301445, 51.364177769057669, 0.0],
          [2.553791698291923, 51.361669613181192, 0.0],
          [2.561009339375984, 51.358741016225608, 0.0],
          [2.645551526683825, 51.35451531096669, 0.0],
          [2.698548001723688, 51.351674705818567, 0.0],
          [2.732336229668699, 51.350845268312277, 0.0],
          [2.757435051303858, 51.350346873504321, 0.0],
          [2.80942973967788, 51.359581897283078, 0.0],
          [2.835681568392232, 51.359552413750748, 0.0],
          [2.86457531505933, 51.359912876454871, 0.0],
          [2.891889080643142, 51.35418438880842, 0.0],
          [2.91150119107163, 51.349858707024858, 0.0],
          [2.945857869660777, 51.348156101780489, 0.0],
          [2.963894166827628, 51.346720602495267, 0.0],
          [2.982381195863186, 51.346931944871713, 0.0],
          [3.018928748146317, 51.3438250287779, 0.0],
          [3.053894972974727, 51.340948842753498, 0.0],
          [3.090235457236488, 51.338581934281187, 0.0],
          [3.117395803832466, 51.336042399352877, 0.0],
          [3.159112924364511, 51.325163189995038, 0.0],
        ],
      },
    },
  ],
};
