import { api } from "../../services/api";
import { AddTabType, DeleteTabType } from "./Header.store";

export const addTab = async (data: AddTabType) => {
  const response = await api.post("/tabs", data);
  return response.data;
};

export const updateTab = async (data: any) => {
  const response = await api.put("/tabs", data);
  return response.data;
};

export const deleteTab = async (data: DeleteTabType) => {
  const response = await api.delete(
    `/tabs/${data.tabId}?userId=${data.userId}`,
  );
  return response.data;
};

export const fetchTabs = async (userId: string) => {
  const response = await api.get(`/tabs?userId=${userId}`);
  return response.data;
};
