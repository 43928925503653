export const IFA2 = {
  type: "FeatureCollection",
  name: "IFA-2 Cable.kmz",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        Name: "IFA-2 Cable",
        tessellate: 1,
        extrude: 0,
        visibility: -1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-1.214685859626682, 50.81042186418253, 0.0],
          [-1.22296706528942, 50.800974944395513, 0.0],
          [-1.220773459279663, 50.797593514105593, 0.0],
          [-1.219177471781083, 50.787414559085818, 0.0],
          [-1.212333668105712, 50.781351740899467, 0.0],
          [-1.207310299951797, 50.779411853017351, 0.0],
          [-1.205401427881146, 50.776534814301677, 0.0],
          [-1.196927534274558, 50.772478873731281, 0.0],
          [-1.196075061551816, 50.769843330364992, 0.0],
          [-1.191463506681305, 50.763198667448577, 0.0],
          [-1.175853398392837, 50.760202804279302, 0.0],
          [-1.170345414371037, 50.759096806715149, 0.0],
          [-1.163168822094649, 50.759159308717592, 0.0],
          [-1.149161499555612, 50.757136818579163, 0.0],
          [-1.136599781834746, 50.755348142784023, 0.0],
          [-1.130327896939129, 50.754824137411468, 0.0],
          [-1.106027512438869, 50.745145125296098, 0.0],
          [-1.091222440579899, 50.740329655828802, 0.0],
          [-1.084648381786848, 50.736436271164933, 0.0],
          [-1.069271011470444, 50.735817013033063, 0.0],
          [-1.054628804253617, 50.731575756003153, 0.0],
          [-1.042873987411924, 50.728543732174153, 0.0],
          [-1.029696976074731, 50.72601428574179, 0.0],
          [-1.020428821642093, 50.723455552839923, 0.0],
          [-1.014552492439337, 50.721446561639397, 0.0],
          [-1.007522934521428, 50.7213322251853, 0.0],
          [-0.995961846134763, 50.718706762657192, 0.0],
          [-0.98762285069698, 50.718324009695607, 0.0],
          [-0.978457196448252, 50.718122734879849, 0.0],
          [-0.960031565201687, 50.713313691885553, 0.0],
          [-0.954740746523385, 50.712380617932617, 0.0],
          [-0.928513852450215, 50.697159715880481, 0.0],
          [-0.903343784638238, 50.682750412875272, 0.0],
          [-0.882976964140769, 50.671136648006197, 0.0],
          [-0.872324104433578, 50.660526621534423, 0.0],
          [-0.844467587660235, 50.650907380578857, 0.0],
          [-0.813458270462079, 50.638780074309643, 0.0],
          [-0.800463029486568, 50.633882196443238, 0.0],
          [-0.779755425753149, 50.627632320827978, 0.0],
          [-0.755761572088113, 50.621746595107268, 0.0],
          [-0.733712282319972, 50.616718624946088, 0.0],
          [-0.710755689681926, 50.611126443324629, 0.0],
          [-0.686556422292139, 50.601015702264732, 0.0],
          [-0.657531779059345, 50.588456309638993, 0.0],
          [-0.630747634848511, 50.576491010289487, 0.0],
          [-0.627469544470876, 50.575911058788392, 0.0],
          [-0.607743304745054, 50.564419797740307, 0.0],
          [-0.595303620454859, 50.545110573126152, 0.0],
          [-0.586849533942445, 50.536758153714068, 0.0],
          [-0.579798754655644, 50.527813842818063, 0.0],
          [-0.573045514187855, 50.515428707880737, 0.0],
          [-0.565688808745366, 50.504532391282098, 0.0],
          [-0.541643442449029, 50.470914708076478, 0.0],
          [-0.513008739918193, 50.430655041872399, 0.0],
          [-0.486268328385557, 50.391454656833098, 0.0],
          [-0.449525750893175, 50.33863164496281, 0.0],
          [-0.421609401835734, 50.298221584997989, 0.0],
          [-0.421102645972642, 50.293318051383316, 0.0],
          [-0.417160899714926, 50.285085687509017, 0.0],
          [-0.415236078248381, 50.26324007604147, 0.0],
          [-0.413965849148298, 50.258928537389608, 0.0],
          [-0.412650045689688, 50.234755384105803, 0.0],
          [-0.406817829027561, 50.218854657166212, 0.0],
          [-0.405744944941089, 50.202946288781753, 0.0],
          [-0.404093215352126, 50.198641908871608, 0.0],
          [-0.402166243894446, 50.193141390458763, 0.0],
          [-0.401817142509027, 50.181907043625351, 0.0],
          [-0.401514461050659, 50.158038870655837, 0.0],
          [-0.401473283421053, 50.152186947576148, 0.0],
          [-0.398304314108872, 50.142344329385928, 0.0],
          [-0.397424865532758, 50.130892463129477, 0.0],
          [-0.394216657138083, 50.11363449761879, 0.0],
          [-0.39074451937309, 50.092581074324571, 0.0],
          [-0.388396290978766, 50.077368881857943, 0.0],
          [-0.386592641806071, 50.060608420013217, 0.0],
          [-0.386019424390927, 50.049146679631967, 0.0],
          [-0.38919124197067, 50.039763994120449, 0.0],
          [-0.390388983162208, 50.023150457894147, 0.0],
          [-0.392227012775967, 49.999734259099121, 0.0],
          [-0.396563779858184, 49.982382715590553, 0.0],
          [-0.396871514187064, 49.965609516421949, 0.0],
          [-0.396013498400534, 49.944011552425273, 0.0],
          [-0.397897771390502, 49.939970365115073, 0.0],
          [-0.399708294870032, 49.921424063928242, 0.0],
          [-0.400880052843142, 49.890985947023253, 0.0],
          [-0.401731770920708, 49.85702209538875, 0.0],
          [-0.402779022414622, 49.836536326230387, 0.0],
          [-0.403237925845857, 49.817616045787297, 0.0],
          [-0.403759354444396, 49.807913335008422, 0.0],
          [-0.405869214448263, 49.794262936996567, 0.0],
          [-0.408966270352277, 49.77690456840967, 0.0],
          [-0.402275874802688, 49.759068756341527, 0.0],
          [-0.391863457245747, 49.744850990691681, 0.0],
          [-0.377081393318677, 49.722945628499062, 0.0],
          [-0.377408537483555, 49.716069457097703, 0.0],
          [-0.370904805942276, 49.704324688579213, 0.0],
          [-0.358529590725401, 49.680938367130373, 0.0],
          [-0.353981196003341, 49.672203602159897, 0.0],
          [-0.355059485314002, 49.660573079961097, 0.0],
          [-0.34133664676561, 49.644423097727369, 0.0],
          [-0.320606906620517, 49.597022696591317, 0.0],
          [-0.309103424981355, 49.570099474608881, 0.0],
          [-0.302549964889329, 49.562433151190348, 0.0],
          [-0.286884321974073, 49.552182204337093, 0.0],
          [-0.263404372312109, 49.543475545373532, 0.0],
          [-0.244607238396166, 49.536984276159188, 0.0],
          [-0.231418643564699, 49.505026871081839, 0.0],
          [-0.224662328310575, 49.487239336320172, 0.0],
          [-0.217963949191431, 49.467387454038629, 0.0],
          [-0.198866872555107, 49.454504665735442, 0.0],
          [-0.187816020257422, 49.445386521942503, 0.0],
          [-0.172608403501247, 49.437153807034839, 0.0],
          [-0.162271265809157, 49.427635102786986, 0.0],
          [-0.158984024040667, 49.419571350327111, 0.0],
          [-0.157458362119929, 49.411807954307662, 0.0],
          [-0.155966761813819, 49.403401025755322, 0.0],
          [-0.151679976619541, 49.386571904727319, 0.0],
          [-0.149469852573895, 49.371942219073482, 0.0],
          [-0.145841759189912, 49.354476340127398, 0.0],
          [-0.143643861127272, 49.34756716981596, 0.0],
          [-0.144340674489343, 49.324904296013152, 0.0],
          [-0.145245446957739, 49.321697137958381, 0.0],
          [-0.160127164019763, 49.299257979311577, 0.0],
          [-0.160842616634835, 49.296281688544887, 0.0],
          [-0.161269445866724, 49.28666614292932, 0.0],
        ],
      },
    },
  ],
};
