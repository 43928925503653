import React, { createContext, useContext, useState, ReactNode } from "react";
import { AlertColor } from "@mui/material";
import { defaultImplementation } from "../utils/utils";

interface SnackbarContextType {
  message: string;
  severity: AlertColor;
  isOpen: boolean;
  handleSnackBarClose: () => void;
  setNewSnackBar: (snackBarInfo: {
    message: string;
    severity?: AlertColor;
  }) => void;
}

const SnackbarContext = createContext<SnackbarContextType>({
  message: "",
  severity: "info",
  isOpen: false,
  handleSnackBarClose: defaultImplementation,
  setNewSnackBar: defaultImplementation,
});

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSnackBarClose = () => {
    setIsOpen(false);
  };

  const setNewSnackBar = ({
    message,
    severity = "success",
  }: {
    message: string;
    severity?: AlertColor;
  }) => {
    setSeverity(severity);
    setMessage(message);
    setIsOpen(true);
  };

  return (
    <SnackbarContext.Provider
      value={{
        message,
        severity,
        isOpen,
        handleSnackBarClose,
        setNewSnackBar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};
