import axios from "axios";
import { REACT_APP_BASE_URL } from "../../../../utils/Constants";

export const getWebcamThumbnail = async ({
  url,
}: {
  url: string;
}): Promise<Blob> => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get(
      `${REACT_APP_BASE_URL}/v1/osint/webcams/thumbnail?url=${url}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
        responseType: "blob",
      },
    );
    return res.data;
  } catch (error: any) {
    throw new Error("error fetching Webcam thumbnail:", error);
  }
};
